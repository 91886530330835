import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import utility from '../../../utility/utility';
import _ from 'lodash'

import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { Analytics } from '../../../services/analytics.service'
import { pushDebugMessage } from '../../../store/actions/debug'
import { 
  selectSearchTask, 
  resetSearch,
} from '../../../store/actions/shop'

import Cart from './../../../components/Shop/Cart'


class AuctionStartedInfoScreen extends ByggbuddyScreen {
  
  // Component settings
  show_cart = true

  toShop = () => {
    this.props.history.push(this.getRoot() + '/shop')
  }

  renderContent() {

    return (
      <>
        <div style={{marginTop: 20, textAlign: 'center'}}>
          <h1 style={{fontSize: 40, fontWeight: 'normal', color: '#000000', textAlign: 'center', fontFamily: 'MuseoSans-300'}}>
            Din förfrågan har skickats!
          </h1>
        </div>

        <div style={{margin: '20px auto', width: '100%', maxWidth: '600px', }}>
          <p style={{fontSize: '20px', fontWeight: 'normal', color: '#404040', textAlign: 'center', fontFamily: 'MuseoSans-300'}}>
            Du kommer att få ett mail inom kort med en bekräftelse på din förfrågan. Hantverkaren återkommer inom kort, normalt inom några dagar, med en offert eller med information om hur offertprocessen kommer att gå till.
          </p>

        </div>

        <DefaultButton
          style={{width: '100%', maxWidth: '400px', height: '50px', backgroundColor: '#167f40', margin: '30px 0', fontSize: '20px', textTransform: 'uppercase'}}
          onClick={() => this.toShop()}
        >
          Tillbaka till start
        </DefaultButton>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  shop: state.shop,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuctionStartedInfoScreen)
