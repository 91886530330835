import { 
  SET_TASKS,
  SET_TASK_ITEMS,
  SET_TASK_ITEM_IMAGES,
  SET_TASK_ITEM_QUESTIONS,
  SET_TASK_ITEM_PREREQUISITES,
  SET_DRIVERS,
  SET_OPTIONS,
  SET_SERVICES,
  SET_SETTINGS,
  SET_COMPETENCES,
  SET_PROPERTIES,
  SET_GEOLOCATIONS,
  SET_PROVIDER_ACCOUNTS,

  SET_SHOP_PROVIDER_ACCOUNT_ID,

  SELECT_SEARCH_TASK,
  SELECT_SEARCH_TASK_ITEM,
  SET_SEARCH_DRIVER_VALUE,
  SET_SEARCH_TASK_TEXT,
  ADD_IMAGE_TO_TASK,
  REMOVE_IMAGE_FROM_TASK,
  DONE_IMAGES_TO_TASK,
  SET_SEARCH_TASK_ANSWER,
  INITIATE_CHECKOUT,
  CONFIRM_PREREQUISITES,
  ADD_SEARCH_TO_CART,
  RESET_SEARCH,
  
  RESET_CART_JOB_OPTIONS,
  SELECT_CART_JOB_OPTION,
  SET_CART_ADDRESS,
  SET_CART_SPEED,
  SET_CART_MAX_PRICE_PER_HOUR,
  SET_CART_PREFERRED_STARTDATE,
  SET_CART_PREFERRED_ENDDATE,
  SET_CART_JOB_NAME,
  SET_CART_JOB_TEXT,
  SET_CART_PROVIDER_ACCOUNT_IDS,
  REMOVE_TASK_FROM_CART,
  RESET_CART,
  LOAD_CART,
} from '../actions/actionTypes';
import moment from 'moment'

const maxDeduction = 100000; // Two people in the household with a max of 50 000 SEK pp


const initialState = {
  tasks: [],
  task_items: [],
  drivers: [],
  options: [],
  task_item_images: [],
  task_item_questions: [],
  task_item_prerequisites: [],
  services: [],
  settings: [],
  competences: [],
  properties: [],
  geolocations: [],
  speeds: {
    express: 7,
    normal: 30,
    slow: 90,
  },
  provider_accounts: [],
  shop_provider_account_id: 0,
  provider_tasks: [],
  always_in_cart_task_ids: [],
  search: {
    task_id: 0,
    task_name: '',
    task_item_ids: [],
    driver_values: [],
    task_item_image_ids: [],
    done_task_item_image_ids: [],
    task_item_question_ids: [],
    task_items: [],
    task_images: [],
    task_answers: [],
    task_text: '',
    task_text_confirmed: false,
    total_hours: 0,
    total_work: 0,
    total_work_deducted: 0,
    total_material: 0,
    total_price: 0,
    total_price_deducted: 0,
    checkout_initiated: false,
    prerequisites_confirmed: false,
  },
  cart: {
    tasks: [],
    //min_price_per_hour: 500,
    //min_price_per_hour_deducted: 350,
    default_price_per_hour: 600,
    price_per_hour: 600,
    hours_adjustment: 0,
    material_adjustment: 0,
    //max_price_per_hour: 800,
    //max_price_per_hour_deducted: 560,
    total_hours: 0,
    total_work: 0,
    total_work_deducted: 0,
    total_material: 0,
    total_price: 0,
    total_price_deducted: 0,
    address: null,
    option_ids: [],
    options: [],
    speed: 'normal',
    preferred_startdate: null,
    preferred_enddate: null,
    job_name: '',
    job_text: '',
    provider_account_ids: [],
    potential_provider_accounts: [],
  }
};

const reducer = (state = initialState, action) => {
  let search = {}
  let task_item_ids = []
  let task_images = []
  let task_answers = []
  let option_ids = []
  let task_item_image_ids = []
  let task_item_question_ids = []
  let new_state = {}
  let tasks = []
  let provider_tasks = []
  let potential_provider_accounts = []
  let total_hours = 0
  let total_work = 0
  let total_work_deducted = 0
  let total_material = 0
  let total_price = 0
  let total_price_deducted = 0
  let price_per_hour = state.cart.price_per_hour
  let hours_adjustment = state.cart.hours_adjustment
  let material_adjustment = state.cart.material_adjustment
        
      
  switch (action.type) {
    case SET_TASKS:
      if (state.shop_provider_account_id == 0) {
        provider_tasks = [...action.tasks]
      }
      else {
        for (var i = 0; i < state.provider_accounts.length; i++) {
          let provider_account = state.provider_accounts[i]
          if (provider_account.provider_account_id == state.shop_provider_account_id) {
            for (var k = 0; k < action.tasks.length; k++) {
              let task = action.tasks[k]
              
              for (var j = 0; j < provider_account.provider_tasks.length; j++) {
                let provider_task = provider_account.provider_tasks[j]
              
                if (task.task_id == provider_task.task_id) {
                  provider_tasks.push(task)
                }
              }
            }
          }
        }
      }
      return {
        ...state,
        tasks: action.tasks,
        provider_tasks,
      }
    case SET_TASK_ITEMS:
      return {
        ...state,
        task_items: action.task_items,
      }
    case SET_TASK_ITEM_IMAGES:
      return {
        ...state,
        task_item_images: action.task_item_images,
      }
    case SET_TASK_ITEM_QUESTIONS:
      return {
        ...state,
        task_item_questions: action.task_item_questions,
      }
    case SET_TASK_ITEM_PREREQUISITES:
      return {
        ...state,
        task_item_prerequisites: action.task_item_prerequisites,
      }
    case SET_OPTIONS:
      return {
        ...state,
        options: action.options,
      }
    case SET_DRIVERS:
      return {
        ...state,
        drivers: action.drivers,
      }
    case SET_SERVICES:
      return {
        ...state,
        services: action.services,
      }
    case SET_SETTINGS:
      tasks = state.cart.tasks
      price_per_hour = state.cart.price_per_hour
      let normal_speed = state.speeds.normal
      let always_in_cart_task_ids = state.always_in_cart_task_ids
      if (action.settings) {
        for (var i = 0; i < action.settings.length; i++) {
          let setting = action.settings[i]

          if (setting.setting_name == 'SHOP_PRICE_PER_HOUR') {
            price_per_hour = setting.int
          }
          if (setting.setting_name == 'AUCTION_LENGTH') {
            normal_speed = setting.int
          }
          if (setting.setting_name == 'ALWAYS_IN_CART_TASK_IDS') {
            always_in_cart_task_ids = setting.string.split(',').map(id => parseInt(id))
          }
        }
      }
      calculateCartTasks(tasks, state)
      calculateCart(state.cart)

      total_hours = 0
      total_work = 0
      total_work_deducted = 0
      total_material = 0
      total_price = 0
      total_price_deducted = 0

      for (var i = 0; i < tasks.length; i++) {
        let task = tasks[i]
        total_hours += task.total_hours
        total_work += task.total_work
        total_work_deducted  += task.total_work_deducted
        total_material += task.total_material
        total_price  += task.total_price
        total_price_deducted += task.total_price_deducted
      }
      
      return {
        ...state,
        always_in_cart_task_ids,
        speeds: {
          ...state.speeds,
          normal: normal_speed
        },
        cart: {
          ...state.cart,
          tasks,
          price_per_hour,
          default_price_per_hour: price_per_hour,
          total_hours,
          total_work,
          total_work_deducted,
          total_material,
          total_price,
          total_price_deducted,
        },
        settings: action.settings,
      }
    case SET_COMPETENCES:
      return {
        ...state,
        competences: action.competences,
      }
    case SET_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
      }
    case SET_PROVIDER_ACCOUNTS:
      if (state.shop_provider_account_id > 0) {
        for (var i = 0; i < action.provider_accounts.length; i++) {
          let provider_account = action.provider_accounts[i]
          if (provider_account.provider_account_id == state.shop_provider_account_id) {
            for (var k = 0; k < state.tasks.length; k++) {
              let task = state.tasks[k]

              for (var j = 0; j < provider_account.provider_tasks.length; j++) {
                let provider_task = provider_account.provider_tasks[j]
            
                if (task.task_id == provider_task.task_id) {
                  provider_tasks.push(task)
                }
              }
            }
            potential_provider_accounts = [provider_account]
          }
        }
      }
      else {
        // Find potential providers
        provider_tasks = [...state.provider_tasks]
        potential_provider_accounts = []
        for (var i = 0; i < action.provider_accounts.length; i++) {
          let provider_account = action.provider_accounts[i]
          let num_matching_tasks = 0
          for (var j = 0; j < state.cart.tasks.length; j++) {
            let task_id = state.cart.tasks[j].task_id
            for (var k = 0; k < provider_account.provider_tasks.length; k++) {
              let provider_task = provider_account.provider_tasks[k]
              if (provider_task.task_id == task_id) {
                num_matching_tasks++;
                break;
              }
            }
          }
          if (num_matching_tasks == state.cart.tasks.length && !provider_account.hide_from_byggbuddy) {
            potential_provider_accounts.push(provider_account)
          }
        }
      }
      return {
        ...state,
        provider_accounts: action.provider_accounts,
        provider_tasks,
        cart: {
          ...state.cart,
          potential_provider_accounts,
        }
      }
    case SET_GEOLOCATIONS:
      for (var i = 0; i < action.geolocations.length; i++) {
        let geolocation = action.geolocations[i]
        let geolocation_text_short = geolocation.geolocation_text
        if (geolocation_text_short.substring(geolocation_text_short.length - 8) == ', Sweden') {
          geolocation_text_short = geolocation_text_short.substring(0, geolocation_text_short.length - 8);
        }
        if (geolocation_text_short.substring(geolocation_text_short.length - 9) == ', Sverige') {
          geolocation_text_short = geolocation_text_short.substring(0, geolocation_text_short.length - 9);
        }
        geolocation.geolocation_text_short = geolocation_text_short
      }
      return {
        ...state,
        geolocations: action.geolocations,
      }
    case SET_SHOP_PROVIDER_ACCOUNT_ID:
      price_per_hour = state.cart.price_per_hour
      hours_adjustment = state.cart.hours_adjustment
      material_adjustment = state.cart.material_adjustment
          
      if (action.shop_provider_account_id == 0) {
        provider_tasks = [...state.tasks]
        for (var i = 0; i < state.provider_accounts.length; i++) {
          let provider_account = state.provider_accounts[i]
          let num_matching_tasks = 0
          for (var j = 0; j < state.cart.tasks.length; j++) {
            let task_id = state.cart.tasks[j].task_id
            for (var k = 0; k < provider_account.provider_tasks.length; k++) {
              let provider_task = provider_account.provider_tasks[k]
              if (provider_task.task_id == task_id) {
                num_matching_tasks++;
                break;
              }
            }
          }
          if (num_matching_tasks == state.cart.tasks.length && !provider_account.hide_from_byggbuddy) {
            potential_provider_accounts.push(provider_account)
          }
        }
        price_per_hour = state.cart.default_price_per_hour
        hours_adjustment = 0
        material_adjustment = 0
      }
      else {
        for (var i = 0; i < state.provider_accounts.length; i++) {
          let provider_account = state.provider_accounts[i]
          if (provider_account.provider_account_id == action.shop_provider_account_id) {
            for (var k = 0; k < state.tasks.length; k++) {
              let task = state.tasks[k]
              
              for (var j = 0; j < provider_account.provider_tasks.length; j++) {
                let provider_task = provider_account.provider_tasks[j]
              
                if (task.task_id == provider_task.task_id) {
                  provider_tasks.push(task)
                }
              }
            }
            potential_provider_accounts = [provider_account]
            // Remove tasks not with this provider
            for (var j = 0; j < state.cart.tasks.length; j++) {
              let task = state.cart.tasks[j]
              for (var k = 0; k < provider_tasks.length; k++) {
                let provider_task = provider_tasks[k]
                if (provider_task.task_id == task.task_id) {
                  tasks.push(task)
                }
              }
            }
            if (provider_account.provider_price_per_hour) {
              price_per_hour = provider_account.provider_price_per_hour
            }
            if (provider_account.provider_hours_adjustment) {
              hours_adjustment = provider_account.provider_hours_adjustment
            }
            if (provider_account.provider_material_adjustment) {
              material_adjustment = provider_account.provider_material_adjustment
            }
          }
        }
      }
      
      // Recalculate
      calculateCartTasks(tasks, state)

      total_hours = 0
      total_work = 0
      total_work_deducted = 0
      total_material = 0
      total_price = 0
      total_price_deducted = 0

      for (var i = 0; i < tasks.length; i++) {
        let task = tasks[i]
        total_hours += task.total_hours
        total_work += task.total_work
        total_work_deducted  += task.total_work_deducted
        total_material += task.total_material
        total_price  += task.total_price
        total_price_deducted += task.total_price_deducted
      }
      return {
        ...state,
        shop_provider_account_id: action.shop_provider_account_id,
        provider_tasks,
        cart: {
          ...state.cart,
          tasks,
          price_per_hour,
          hours_adjustment,
          material_adjustment,
          total_hours,
          total_work,
          total_work_deducted,
          total_material,
          total_price,
          total_price_deducted,
          potential_provider_accounts,
        }
      }
    case SELECT_SEARCH_TASK:
      for (var i = 0; i < state.task_items.length; i++) {
        let task_item = state.task_items[i]
        if (task_item.task_id == action.task_id && task_item.parent_task_item_id == 0 ) {
          task_item_ids.push(task_item.task_item_id)

          // Add task item images
          for (var j = 0; j < state.task_item_images.length; j++) {
            let task_item_image = state.task_item_images[j]
            if (task_item_image.task_item_id == task_item.task_item_id) {
              task_item_image_ids.push(task_item_image.task_item_image_id)
            }
          }

          // Add task item questions
          for (var j = 0; j < state.task_item_questions.length; j++) {
            let task_item_question = state.task_item_questions[j]
            if (task_item_question.task_item_id == task_item.task_item_id) {
              task_item_question_ids.push(task_item_question.task_item_question_id)
            }
          }
        }
      }
      let task_name = ''
      for (var i = 0; i < state.tasks.length; i++) {
        let task = state.tasks[i]
        if (task.task_id == action.task_id) {
          task_name = task.task_name
        }
      }
      new_state = {
        ...state,
        search: {
          ...state.search,
          task_id: action.task_id,
          task_name: task_name,
          task_item_ids: task_item_ids,
          driver_values: [],
          task_item_image_ids,
          task_item_question_ids,
          task_images: [],
          task_text: '',
          task_text_confirmed: false,
        }
      }
      generateSearchTaskItems(new_state)
      return new_state

    case SELECT_SEARCH_TASK_ITEM:
      task_images = [...state.search.task_images]
      task_answers = [...state.search.task_answers]
      for (var i = 0; i < state.search.task_item_ids.length; i++) {
        let search_task_item_id = state.search.task_item_ids[i]
        let replace = false
      
        for (var j = 0; j < state.task_items.length; j++) {
          let task_item = state.task_items[j]
          // Replacing parent item
          if (task_item.task_item_id == action.task_item_id && task_item.parent_task_item_id == search_task_item_id) {
            replace = true

            // Add item images
            task_item_image_ids = [...state.search.task_item_image_ids]
            for (var k = 0; k < state.task_item_images.length; k++) {
              let task_item_image = state.task_item_images[k]
              if (task_item_image.task_item_id == action.task_item_id) {
                task_item_image_ids.push(task_item_image.task_item_image_id)
              }
            }

            // Add item questions
            task_item_question_ids = [...state.search.task_item_question_ids]
            for (var k = 0; k < state.task_item_questions.length; k++) {
              let task_item_question = state.task_item_questions[k]
              if (task_item_question.task_item_id == action.task_item_id) {
                task_item_question_ids.push(task_item_question.task_item_question_id)
              }
            }
          }
          // Replacing child item
          if (task_item.task_item_id == search_task_item_id && task_item.parent_task_item_id == action.task_item_id) {
            replace = true

            // Remove child images no longer to be taken
            task_item_image_ids = []
            for (var k = 0; k < state.search.task_item_image_ids.length; k++) {
              let current_image_id = state.search.task_item_image_ids[k]
              
              for (var l = 0; l < state.task_item_images.length; l++) {
                let task_item_image = state.task_item_images[l]
                if (current_image_id == task_item_image.task_item_image_id && task_item_image.task_item_id != search_task_item_id) {
                  task_item_image_ids.push(current_image_id)
                }
              }
            }

            // Remove child images already taken
            task_images = []
            for (var k = 0; k < state.search.task_images.length; k++) {
              let current_image = state.search.task_images[k]
              
              for (var l = 0; l < state.task_item_images.length; l++) {
                let task_item_image = state.task_item_images[l]
                if (current_image.task_item_image_id == task_item_image.task_item_image_id && task_item_image.task_item_id != search_task_item_id) {
                  task_images.push(current_image)
                }
              }
            }

            // Remove child questions no longer to be answered
            task_item_question_ids = []
            for (var k = 0; k < state.search.task_item_question_ids.length; k++) {
              let current_question_id = state.search.task_item_question_ids[k]
              
              for (var l = 0; l < state.task_item_questions.length; l++) {
                let task_item_question = state.task_item_questions[l]
                if (current_question_id == task_item_question.task_item_question_id && task_item_question.task_item_id != search_task_item_id) {
                  task_item_question_ids.push(current_question_id)
                }
              }
            }

            // Remove child questions already answered
            task_answers = []
            for (var k = 0; k < state.search.task_answers.length; k++) {
              let current_answer = state.search.task_answers[k]
              
              for (var l = 0; l < state.task_item_questions.length; l++) {
                let task_item_question = state.task_item_questions[l]
                if (current_answer.task_item_question_id == task_item_question.task_item_question_id && task_item_question.task_item_id != search_task_item_id) {
                  task_answers.push(current_answer)
                }
              }
            }
          }
        }
        if (replace) {
          task_item_ids.push(action.task_item_id)
        }
        else {
          task_item_ids.push(search_task_item_id)
        }
      }

      new_state = {
        ...state,
        search: {
          ...state.search,
          task_item_ids: task_item_ids,
          task_item_image_ids,
          task_item_question_ids,
          task_images,
          task_answers,
          task_text_confirmed: false,
        }
      }
      generateSearchTaskItems(new_state)
      return new_state

    case SET_SEARCH_DRIVER_VALUE:
      let driver_values = []
      for (var i = 0; i < state.search.driver_values.length; i++) {
        let driver_value = state.search.driver_values[i]
        if (driver_value.driver_id != action.driver_id) {
          driver_values.push(driver_value)
        }
      }
      driver_values.push({driver_id: action.driver_id, driver_value: action.driver_value})

      //console.warn(driver_values)

      new_state = {
        ...state,
        search: {
          ...state.search,
          driver_values: driver_values,
          task_text_confirmed: false,
        }
      }
      generateSearchTaskItems(new_state)
      return new_state

    case ADD_IMAGE_TO_TASK:
      //console.warn(action)
      // Adding image to task in search
      if (action.cart_task_index == -1) {
        task_images = [...state.search.task_images]
        task_images.push({
          image_uri: action.image_uri,
          task_item_image_id: action.task_item_image_id,
        })
        return {
          ...state,
          search: {
            ...state.search,
            task_images: task_images,
          }
        }
      }
      // Adding image to task in cart
      else {
        let tasks = []
        for (var i = 0; i < state.cart.tasks.length; i++) {
          let task = {...state.cart.tasks[i]}

          if (i == action.cart_task_index) {
            task_images = [...task.task_images]
            task_images.push({
              image_uri: action.image_uri,
              task_item_image_id: action.task_item_image_id,
            })
            task.task_images = task_images
          }

          tasks.push(task)
        }
        return {
          ...state,
          cart: {
            ...state.cart,
            tasks,
          }
        }
      }

    case REMOVE_IMAGE_FROM_TASK:
      //console.warn(action)
      // Removing image from task in search
      if (action.cart_task_index == -1) {
        task_images = [...state.search.task_images]
        task_images.splice(action.index, 1)
        return {
          ...state,
          search: {
            ...state.search,
            task_images: task_images,
          }
        }
      }
      // Removing image from task in cart
      else {
        let tasks = []
        for (var i = 0; i < state.cart.tasks.length; i++) {
          let task = {...state.cart.tasks[i]}

          if (i == action.cart_task_index) {
            task_images = [...task.task_images]
            task_images.splice(action.index, 1)
            task.task_images = task_images
          }

          tasks.push(task)
        }
        return {
          ...state,
          cart: {
            ...state.cart,
            tasks,
          }
        }
      }

    case DONE_IMAGES_TO_TASK:
      //console.warn(action)
      // Done images for task in search
      if (action.cart_task_index == -1) {
        for (var i = 0; i < state.search.task_item_image_ids.length; i++) {
          let task_item_image_id = state.search.task_item_image_ids[i]
          if (task_item_image_id != action.task_item_image_id) {
            task_item_image_ids.push(task_item_image_id)
          }
        }
        return {
          ...state,
          search: {
            ...state.search,
            task_item_image_ids: task_item_image_ids,
            task_text_confirmed: false,
          }
        }
      }
      // Done images for task in cart
      else {
        let tasks = []
        for (var i = 0; i < state.cart.tasks.length; i++) {
          let task = {...state.cart.tasks[i]}

          if (i == action.cart_task_index) {
            for (var j = 0; j < task.task_item_image_ids.length; j++) {
              let task_item_image_id = task.task_item_image_ids[j]
              if (task_item_image_id != action.task_item_image_id) {
                task_item_image_ids.push(task_item_image_id)
              }
              else {
                task.done_task_item_image_ids.push(task_item_image_id)
              }
            }
            task.task_item_image_ids = task_item_image_ids
          }

          tasks.push(task)
        }
        return {
          ...state,
          cart: {
            ...state.cart,
            tasks,
          }
        }
      }

    case SET_SEARCH_TASK_ANSWER:
      for (var i = 0; i < state.search.task_item_question_ids.length; i++) {
        let task_item_question_id = state.search.task_item_question_ids[i]
        if (task_item_question_id != action.task_item_question_id) {
          task_item_question_ids.push(task_item_question_id)
        }
      }
      let task_answer = {
        task_item_question_id: action.task_item_question_id,
        job_task_answer_text: action.answer_text,
      }
      task_answers = [...state.search.task_answers]
      task_answers.push(task_answer)
      return {
        ...state,
        search: {
          ...state.search,
          task_item_question_ids,
          task_answers,
          task_text_confirmed: false,
        }
      }

    
    case INITIATE_CHECKOUT:
      // Confirm for task in search
      if (action.cart_task_index == -1) {
        return {
          ...state,
          search: {
            ...state.search,
            checkout_initiated: action.initiate,
          }
        }
      }
      // Confirm for task in cart
      else {
        let tasks = []
        for (var i = 0; i < state.cart.tasks.length; i++) {
          let task = {...state.cart.tasks[i]}

          if (i == action.cart_task_index) {
            task.checkout_initiated = action.initiate
          }

          tasks.push(task)
        }
        return {
          ...state,
          cart: {
            ...state.cart,
            tasks,
          }
        }
      }

      case CONFIRM_PREREQUISITES:
      // Confirm for task in search
      if (action.cart_task_index == -1) {
        return {
          ...state,
          search: {
            ...state.search,
            prerequisites_confirmed: action.confirm,
          }
        }
      }
      // Confirm for task in cart
      else {
        let tasks = []
        for (var i = 0; i < state.cart.tasks.length; i++) {
          let task = {...state.cart.tasks[i]}

          if (i == action.cart_task_index) {
            task.prerequisites_confirmed = action.confirm
          }

          tasks.push(task)
        }
        return {
          ...state,
          cart: {
            ...state.cart,
            tasks,
          }
        }
      }

    case SET_SEARCH_TASK_TEXT:
      return {
        ...state,
        search: {
          ...state.search,
          task_text: action.task_text,
          task_text_confirmed: true,
        }
      }

    case LOAD_CART:
      let valid_tasks = []
      for (var i = 0; i < action.cart.tasks.length; i++) {
        let loaded_task = action.cart.tasks[i]
        for (var j = 0; j < state.tasks.length; j++) {
          let shop_task = state.tasks[j]

          if (shop_task.task_id == loaded_task.task_id) {
            valid_tasks.push(loaded_task)
          }
        }
      }
      action.cart.tasks = valid_tasks
      // Find potential providers
      if (state.shop_provider_account_id == 0) {
        potential_provider_accounts = []
        for (var i = 0; i < state.provider_accounts.length; i++) {
          let provider_account = state.provider_accounts[i]
          let num_matching_tasks = 0
          for (var j = 0; j < action.cart.tasks.length; j++) {
            let task_id = action.cart.tasks[j].task_id
            for (var k = 0; k < provider_account.provider_tasks.length; k++) {
              let provider_task = provider_account.provider_tasks[k]
              if (provider_task.task_id == task_id) {
                num_matching_tasks++;
                break;
              }
            }
          }
          if (num_matching_tasks == action.cart.tasks.length && !provider_account.hide_from_byggbuddy) {
            potential_provider_accounts.push(provider_account)
          }
        }
      }
      else {
        for (var i = 0; i < state.provider_accounts.length; i++) {
          let provider_account = state.provider_accounts[i]
          if (provider_account.provider_account_id == state.shop_provider_account_id) {
            let num_matching_tasks = 0
            for (var j = 0; j < action.cart.tasks.length; j++) {
              let task_id = action.cart.tasks[j].task_id
              for (var k = 0; k < provider_account.provider_tasks.length; k++) {
                let provider_task = provider_account.provider_tasks[k]
                if (provider_task.task_id == task_id) {
                  num_matching_tasks++;
                  break;
                }
              }
            }
            if (num_matching_tasks == action.cart.tasks.length) {
              potential_provider_accounts.push(provider_account)
            }
          }
        }
      }
      action.cart.potential_provider_accounts = potential_provider_accounts
      calculateCartTasks(valid_tasks, state)

      total_hours = 0
      total_work = 0
      total_work_deducted = 0
      total_material = 0
      total_price = 0
      total_price_deducted = 0

      for (var i = 0; i < valid_tasks.length; i++) {
        let task = valid_tasks[i]
        total_hours += task.total_hours
        total_work += task.total_work
        total_work_deducted  += task.total_work_deducted
        total_material += task.total_material
        total_price  += task.total_price
        total_price_deducted += task.total_price_deducted
      }
      
      return {
        ...state,
        cart: {
          ...action.cart,
          tasks: valid_tasks,
          default_price_per_hour: state.cart.default_price_per_hour,
          price_per_hour: state.cart.price_per_hour,
          material_adjustment: state.cart.material_adjustment,
          hours_adjustment: state.cart.hours_adjustment,
          total_hours,
          total_work,
          total_work_deducted,
          total_material,
          total_price,
          total_price_deducted,
        },
      }

    case ADD_SEARCH_TO_CART:
      let task = {...state.search}
      let cart = {...state.cart}
      cart.tasks.push(task)
      
      // Find potential providers
      potential_provider_accounts = []
      if (state.shop_provider_account_id == 0) {
        for (var i = 0; i < state.provider_accounts.length; i++) {
          let provider_account = state.provider_accounts[i]
          let num_matching_tasks = 0
          for (var j = 0; j < cart.tasks.length; j++) {
            let task_id = cart.tasks[j].task_id
            for (var k = 0; k < provider_account.provider_tasks.length; k++) {
              let provider_task = provider_account.provider_tasks[k]
              if (provider_task.task_id == task_id) {
                num_matching_tasks++;
                break;
              }
            }
          }
          if (num_matching_tasks == cart.tasks.length && !provider_account.hide_from_byggbuddy) {
            potential_provider_accounts.push(provider_account)
          }
        }
      }
      else {
        for (var i = 0; i < state.provider_accounts.length; i++) {
          let provider_account = state.provider_accounts[i]
          if (provider_account.provider_account_id == state.shop_provider_account_id) {
            let num_matching_tasks = 0
            for (var j = 0; j < cart.tasks.length; j++) {
              let task_id = cart.tasks[j].task_id
              for (var k = 0; k < provider_account.provider_tasks.length; k++) {
                let provider_task = provider_account.provider_tasks[k]
                if (provider_task.task_id == task_id) {
                  num_matching_tasks++;
                  break;
                }
              }
            }
            if (num_matching_tasks == cart.tasks.length) {
              potential_provider_accounts.push(provider_account)
            }
          }
        }
      }
      cart.potential_provider_accounts = potential_provider_accounts
      search = {
        task_id: 0,
        task_name: '',
        task_item_ids: [],
        driver_values: [],
        task_item_image_ids: [],
        done_task_item_image_ids: [],
        task_item_question_ids: [],
        task_items: [],
        task_images: [],
        task_answers: [],
        task_text: '',
        task_text_confirmed: false,
        total_hours: 0,
        total_work: 0,
        total_work_deducted: 0,
        total_material: 0,
        total_price: 0,
        total_price_deducted: 0,
        checkout_initiated: false,
        prerequisites_confirmed: false,
      }
      ensureTasksAlwaysInCart(cart, state)
      calculateCart(cart)
      return {
        ...state,
        search: search,
        cart: cart,
      }

    case RESET_SEARCH:
      search = {
        task_id: 0,
        task_name: '',
        task_item_ids: [],
        driver_values: [],
        task_item_image_ids: [],
        done_task_item_image_ids: [],
        task_item_question_ids: [],
        task_items: [],
        task_images: [],
        task_answers: [],
        task_text: '',
        task_text_confirmed: false,
        total_hours: 0,
        total_work: 0,
        total_work_deducted: 0,
        total_material: 0,
        total_price: 0,
        total_price_deducted: 0,
        checkout_initiated: false,
        prerequisites_confirmed: false,
      }
      return {
        ...state,
        search: search,
      }

    case RESET_CART_JOB_OPTIONS:
      for (var i = 0; i < state.options.length; i++) {
        let option = state.options[i]
        if (option.parent_option_id == 0) {
          option_ids.push(option.option_id)
        }
      }
      for (var i = 0; i < state.cart.tasks.length; i++) {
        let task = state.cart.tasks[i]
        task.checkout_initiated = false
        task.prerequisites_confirmed = false
        if (task.task_item_image_ids.length == 0 && task.done_task_item_image_ids.length > 0) {
          task.task_item_image_ids = [...task.done_task_item_image_ids]
          task.done_task_item_image_ids = []
        }
        tasks.push(task)
      }
      //console.warn('RESET', tasks)
      
      return {
        ...state,
        cart: {
          ...state.cart,
          option_ids: option_ids,
          options: [],
          address: null,
          tasks,
        }
      }

    case SELECT_CART_JOB_OPTION:
      let options = []
      //console.warn('A', state.cart.option_ids)
      for (var i = 0; i < state.cart.option_ids.length; i++) {
        let cart_option_id = state.cart.option_ids[i]
        let replace = false
      
        for (var j = 0; j < state.options.length; j++) {
          let option = state.options[j]
          if (option.option_id == action.option_id && option.parent_option_id == cart_option_id) {
            replace = true
          }
        }
        let option_id = 0
        if (replace) {
          option_id = action.option_id
        }
        else {
          option_id = cart_option_id
        }
        option_ids.push(option_id)
        for (var j = 0; j < state.options.length; j++) {
          let option = state.options[j]
          if (option.option_id == option_id) {
            options.push(option)
          }
        }
      }
      new_state = {
        ...state,
        cart: {
          ...state.cart,
          option_ids: option_ids,
          options: options,
        }
      }
      return new_state

    case SET_CART_ADDRESS:
      if (action.address.latitude && action.address.longitude) {

        // Find potential providers
        let task_provider_accounts = []
        if (state.shop_provider_account_id == 0) {
          for (var i = 0; i < state.provider_accounts.length; i++) {
            let provider_account = state.provider_accounts[i]
            let num_matching_tasks = 0
            for (var j = 0; j < state.cart.tasks.length; j++) {
              let task_id = state.cart.tasks[j].task_id
              for (var k = 0; k < provider_account.provider_tasks.length; k++) {
                let provider_task = provider_account.provider_tasks[k]
                if (provider_task.task_id == task_id) {
                  num_matching_tasks++;
                  break;
                }
              }
            }
            if (num_matching_tasks == state.cart.tasks.length && !provider_account.hide_from_byggbuddy) {
              task_provider_accounts.push(provider_account)
            }
          }
        }
        else {
          for (var i = 0; i < state.provider_accounts.length; i++) {
            let provider_account = state.provider_accounts[i]
            if (provider_account.provider_account_id == state.shop_provider_account_id) {
              let num_matching_tasks = 0
              for (var j = 0; j < state.cart.tasks.length; j++) {
                let task_id = state.cart.tasks[j].task_id
                for (var k = 0; k < provider_account.provider_tasks.length; k++) {
                  let provider_task = provider_account.provider_tasks[k]
                  if (provider_task.task_id == task_id) {
                    num_matching_tasks++;
                    break;
                  }
                }
              }
              if (num_matching_tasks == state.cart.tasks.length) {
                task_provider_accounts.push(provider_account)
              }
            }
          }
        }

        potential_provider_accounts = []
        for (var i = 0; i < task_provider_accounts.length; i++) {
          let provider_account = task_provider_accounts[i]
          provider_account.distance = 999999

          let provider_locations = provider_account.provider_locations
          let provider_max_distance = provider_account.provider_max_distance

          let valid_location = false
          for (var j = 0; j < provider_locations.length; j++) {
            let provider_location = provider_locations[j]

            // Get distance from provider location to job location
            let p = 0.017453292519943295;
      
            let lat1 = provider_location.latitude;
            let lon1 = provider_location.longitude;

            let lat2 = action.address.latitude;
            let lon2 = action.address.longitude;

            let a = 0.5 - Math.cos((lat2 - lat1) * p)/2 + Math.cos(lat1 * p) * Math.cos(lat2 * p) * (1 - Math.cos((lon2 - lon1) * p))/2;
            let distance = 12742 * Math.asin(Math.sqrt(a)); //Distance in km

            //console.log('DISTANCE', provider_location, distance)

            if (distance <= provider_max_distance) {
              valid_location = true
              if (distance < provider_account.distance) {
                provider_account.distance = distance
              }
            }
          }
          if (valid_location) {
            potential_provider_accounts.push(provider_account)
          }
        }
        potential_provider_accounts.sort((a, b) => (a.distance - b.distance))
      }
      else {
        potential_provider_accounts = [...state.cart.potential_provider_accounts]
      }
      return {
        ...state,
        cart: {
          ...state.cart,
          address: action.address,
          potential_provider_accounts,
        }
      }

    case SET_CART_SPEED:
      return {
        ...state,
        cart: {
          ...state.cart,
          speed: action.speed,
        }
      }

    case SET_CART_JOB_NAME:
      return {
        ...state,
        cart: {
          ...state.cart,
          job_name: action.job_name,
        }
      }

    case SET_CART_JOB_TEXT:
      return {
        ...state,
        cart: {
          ...state.cart,
          job_text: action.job_text,
        }
      }

    case SET_CART_PREFERRED_STARTDATE:
      return {
        ...state,
        cart: {
          ...state.cart,
          preferred_startdate: action.preferred_startdate,
        }
      }

    case SET_CART_PREFERRED_ENDDATE:
      return {
        ...state,
        cart: {
          ...state.cart,
          preferred_enddate: action.preferred_enddate,
        }
      }

    case SET_CART_MAX_PRICE_PER_HOUR:
      return {
        ...state,
        cart: {
          ...state.cart,
          max_price_per_hour: action.price_per_hour,
        }
      }

    case SET_CART_PROVIDER_ACCOUNT_IDS:
      return {
        ...state,
        cart: {
          ...state.cart,
          provider_account_ids: action.provider_account_ids,
        }
      }

    case REMOVE_TASK_FROM_CART:
      tasks = [...state.cart.tasks]
      tasks.splice(action.task_index, 1)
      
      // Find potential providers
      potential_provider_accounts = []
      if (state.shop_provider_account_id == 0) {
        for (var i = 0; i < state.provider_accounts.length; i++) {
          let provider_account = state.provider_accounts[i]
          let num_matching_tasks = 0
          for (var j = 0; j < tasks.length; j++) {
            let task_id = tasks[j].task_id
            for (var k = 0; k < provider_account.provider_tasks.length; k++) {
              let provider_task = provider_account.provider_tasks[k]
              if (provider_task.task_id == task_id) {
                num_matching_tasks++;
                break;
              }
            }
          }
          if (num_matching_tasks == tasks.length && !provider_account.hide_from_byggbuddy) {
            potential_provider_accounts.push(provider_account)
          }
        }
      }
      else {
        for (var i = 0; i < state.provider_accounts.length; i++) {
          let provider_account = state.provider_accounts[i]
          if (provider_account.provider_account_id == state.shop_provider_account_id) {
            let num_matching_tasks = 0
            for (var j = 0; j < tasks.length; j++) {
              let task_id = tasks[j].task_id
              for (var k = 0; k < provider_account.provider_tasks.length; k++) {
                let provider_task = provider_account.provider_tasks[k]
                if (provider_task.task_id == task_id) {
                  num_matching_tasks++;
                  break;
                }
              }
            }
            if (num_matching_tasks == tasks.length) {
              potential_provider_accounts.push(provider_account)
            }
          }
        }
      }

      new_state = {
        ...state,
        cart: {
          ...state.cart,
          tasks,
          potential_provider_accounts,
        }
      }
      ensureTasksAlwaysInCart(new_state.cart, state)
      calculateCart(new_state.cart)
      
      return new_state

    case RESET_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          tasks: [],
          total_hours: 0,
          total_work: 0,
          total_work_deducted: 0,
          total_material: 0,
          total_price: 0,
          total_price_deducted: 0,
          address: null,
          option_ids: [],
          options: [],
          speed: 'normal', //= 7 days, 'express' = 2 days, 'slow' = 30 days
          preferred_startdate: null,
          preferred_enddate: null,
          job_name: '',
          job_text: '',
          potential_provider_accounts: [],
        }
      }
    
    default:
      return state;
  }
}

const calculateCart = (cart) => {

  let total_hours = 0
  let total_work = 0
  let total_work_deducted = 0
  let total_material = 0
  let total_price = 0
  let total_price_deducted = 0

  let tasks = [...cart.tasks]
  let task_without_price = false
  for (var i = 0; i < tasks.length; i++) {
    let task = tasks[i]
    total_hours += task.total_hours
    total_work += task.total_work
    total_work_deducted  += task.total_work_deducted
    total_material += task.total_material
    total_price  += task.total_price
    total_price_deducted += task.total_price_deducted

    if (task.total_price == 0) {
      task_without_price = true
    }
  }

  // Cap on ROT-deduction
  if (total_work - total_work_deducted > maxDeduction) {
    let correction = total_work - total_work_deducted - maxDeduction
    total_work_deducted += correction
    total_price_deducted += correction
  }

  if (task_without_price) {
    cart.total_hours = 0
    cart.total_work = 0
    cart.total_work_deducted = 0
    cart.total_material = 0
    cart.total_price = 0
    cart.total_price_deducted = 0
  }
  else {
    cart.total_hours = total_hours
    cart.total_work = total_work
    cart.total_work_deducted = total_work_deducted
    cart.total_material = total_material
    cart.total_price = total_price
    cart.total_price_deducted = total_price_deducted
  }
}

const calculateCartTasks = (tasks, state) => {
  for (var i = 0; i < tasks.length; i++) {
    let task = tasks[i]

    task.total_hours = 0
    task.total_work = 0
    task.total_work_deducted = 0
    task.total_material = 0
    task.total_price = 0
    task.total_price_deducted = 0

    for (var j = 0; j < task.task_items.length; j++) {
      let task_item = task.task_items[j]

      task_item.hours             = (task_item.hours_base      + task_item.driver_value * task_item.hours_per_driver)  * (1 + state.cart.hours_adjustment / 100)
      task_item.material          = (task_item.material_base   + task_item.driver_value * task_item.material_per_driver) * (1 + state.cart.material_adjustment / 100)
      task_item.work              = task_item.hours * state.cart.price_per_hour
      task_item.work_deducted     = Math.round(task_item.work * 0.7)

      task.total_hours += task_item.hours
      task.total_work += task_item.work
      task.total_work_deducted += task_item.work_deducted
      task.total_material += task_item.material
      task.total_price += task_item.work + task_item.material
      task.total_price_deducted += task_item.work_deducted + task_item.material
    }
  }
}

const calculateCartTask = (task, state) => {
  
  task.total_hours = 0
  task.total_work = 0
  task.total_work_deducted = 0
  task.total_material = 0
  task.total_price = 0
  task.total_price_deducted = 0

  for (var j = 0; j < task.task_items.length; j++) {
    let task_item = task.task_items[j]

    task_item.hours             = (task_item.hours_base      + task_item.driver_value * task_item.hours_per_driver)  * (1 + state.cart.hours_adjustment / 100)
    task_item.material          = (task_item.material_base   + task_item.driver_value * task_item.material_per_driver) * (1 + state.cart.material_adjustment / 100)
    task_item.work              = task_item.hours * state.cart.price_per_hour
    task_item.work_deducted     = Math.round(task_item.work * 0.7)

    task.total_hours += task_item.hours
    task.total_work += task_item.work
    task.total_work_deducted += task_item.work_deducted
    task.total_material += task_item.material
    task.total_price += task_item.work + task_item.material
    task.total_price_deducted += task_item.work_deducted + task_item.material
  }
  
}

const generateSearchTaskItems = (state) => {
  let task_items = []
  let total_hours = 0
  let total_work = 0
  let total_work_deducted = 0
  let total_material = 0
  let total_price = 0
  let total_price_deducted = 0

  for (var i = 0; i < state.search.task_item_ids.length; i++) {
    let task_item_id = state.search.task_item_ids[i]

    let task_item = null
    for (var j = 0; j < state.task_items.length; j++) {
      task_item = {...state.task_items[j]}

      if (task_item.task_item_id == task_item_id) {
        break
      }
    }
    let driver_value = null
    if (task_item.driver_id == 0) {
      driver_value = {
        driver_value: 0
      }
    }
    else {
      for (var j = 0; j < state.search.driver_values.length; j++) {
        let d = state.search.driver_values[j]
        if (d.driver_id == task_item.driver_id) {
          driver_value = d
          break
        }
      }
    }
    if (driver_value) {
      task_item.driver_value      = driver_value.driver_value
      task_item.hours             = task_item.hours_base      + driver_value.driver_value * task_item.hours_per_driver
      task_item.material          = task_item.material_base   + driver_value.driver_value * task_item.material_per_driver
      task_item.work              = task_item.hours * state.cart.price_per_hour
      task_item.work_deducted     = Math.round(task_item.work * 0.7)

      task_items.push(task_item)

      total_hours += task_item.hours
      total_work += task_item.work
      total_work_deducted += task_item.work_deducted
      total_material += task_item.material
      total_price += task_item.work + task_item.material
      total_price_deducted += task_item.work_deducted + task_item.material
    }
  }
  state.search.task_items = task_items
  state.search.total_hours = total_hours
  state.search.total_work = Math.ceil(total_work)
  state.search.total_work_deducted = Math.ceil(total_work_deducted)
  state.search.total_material = Math.ceil(total_material)
  state.search.total_price = Math.ceil(total_price)
  state.search.total_price_deducted = Math.ceil(total_price_deducted)
  //console.warn(state.search.task_items)
}

const ensureTasksAlwaysInCart = (cart, state) => {
  
  // Check which tasks are already in the cart
  let always_task_count = 0
  let remaining_task_ids = [...state.always_in_cart_task_ids]
  for (var i = 0; i < cart.tasks.length; i++) {
    let task = cart.tasks[i]
    if (remaining_task_ids.includes(task.task_id)) {
      remaining_task_ids.splice(remaining_task_ids.indexOf(task.task_id), 1)
      always_task_count++
    }
  }

  if (always_task_count == cart.tasks.length) {
    // Cart consists of only always tasks. Clear the cart!
    cart.tasks = []
  }
  else {
  
    // Add missing tasks
    for (var i = 0; i < remaining_task_ids.length; i++) {

      for (var j = 0; j < state.tasks.length; j++) {
        let task = state.tasks[j]
        if (task.task_id == remaining_task_ids[i]) {
          let task_item_ids = []
          let task_items = []
          
          for (var k = 0; k < state.task_items.length; k++) {
            let task_item = state.task_items[k]
            if (task_item.task_id == remaining_task_ids[i]) {
              task_item_ids.push(task_item.task_item_id)
              task_items.push({...task_item, driver_value: 0})
            }
          }

          let always_task = {
            task_id: remaining_task_ids[i],
            task_name: '',
            task_item_ids,
            driver_values: [],
            task_item_image_ids: [],
            done_task_item_image_ids: [],
            task_item_question_ids: [],
            task_items,
            task_images: [],
            task_answers: [],
            task_text: '', 
            task_text_confirmed: false,
            total_hours: 0,
            total_work: 0,
            total_work_deducted: 0,
            total_material: 0,
            total_price: 0,
            total_price_deducted: 0,
            checkout_initiated: false,
            prerequisites_confirmed: false,
            always_in_cart: true,
          }
          calculateCartTask(always_task, state)
          cart.tasks.push(always_task)
        }
      }
    }
  }
  cart.tasks = cart.tasks.sort((a, b) => {
    return (a.always_in_cart ? 0 : 1) - (b.always_in_cart ? 0 : 1)
  })
}

export default reducer;