import React, { Component } from "react";
import { connect } from "react-redux";
import utility from "../../../utility/utility";
import ByggbuddyScreen from "../../ByggbuddyScreen";
import DefaultInput from "../../../components/UI/DefaultInput/DefaultInput";
import DefaultButton from "../../../components/UI/DefaultButton/DefaultButton";
import { AUCTION_STATUS, PROVIDER_AUCTION_STATUS } from "../provider.consts.js";
import { pushDebugMessage } from "../../../store/actions/debug";

import _ from "lodash";
import { Analytics } from "../../../services/analytics.service";

import ProviderAuction from "./ProviderAuction";

class ProviderAuctionsScreen extends ByggbuddyScreen {
  show_back = false;

  // Component settings
  content_padding = "0px";
  content_max_width = "none";

  componentDidMount() {
    this.updateBackButtonVisibility();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.auction_id !== this.props.match.params.auction_id
    ) {
      this.updateBackButtonVisibility();
    }
  }

  updateBackButtonVisibility = () => {
    const selected_auction_id = this.props.match.params.auction_id;

    // Show back if selected_auction_id is not null/undefined/0 and isn't "ongoing" or any other special strings
    const isValidAuctionId =
      selected_auction_id && !isNaN(Number(selected_auction_id));

    this.show_back = isValidAuctionId;
    this.forceUpdate(); // Force a re-render to apply the new value of show_back
  };

  selectAuction = (selected_auction_id) => {
    if (selected_auction_id) {
      this.goTo("/provider/auctions/" + selected_auction_id);
    } else {
      this.goTo("/provider/auctions");
    }
  };

  renderBrandedContent(provider_account) {
    let selected_auction_id = this.props.match?.params?.auction_id || 0;

    let auctions = [];
    let specific_selected = false;

    switch (selected_auction_id) {
      case AUCTION_STATUS.ONGOING:
        auctions = this.props.auctions.filter(
          (a) =>
            a.auction_status === AUCTION_STATUS.ONGOING &&
            a.provider_status === PROVIDER_AUCTION_STATUS.INVITED,
        );
        break;

      case AUCTION_STATUS.ABORTED:
        auctions = this.props.auctions.filter(
          (a) =>
            a.auction_status === AUCTION_STATUS.ABORTED ||
            a.provider_status !== PROVIDER_AUCTION_STATUS.INVITED,
        );
        break;

      case AUCTION_STATUS.ACCEPTED:
        auctions = this.props.auctions.filter(
          (a) => a.auction_status === AUCTION_STATUS.ACCEPTED,
        );
        break;

      case AUCTION_STATUS.DECLINED:
        auctions = this.props.auctions.filter(
          (a) => a.auction_status === AUCTION_STATUS.DECLINED,
        );
        break;
      case AUCTION_STATUS.BID_SENT:
        auctions = this.props.auctions.filter(
          (a) => a.auction_status === AUCTION_STATUS.BID_SENT,
        );
        break;
      case AUCTION_STATUS.FINISHED:
        auctions = this.props.auctions.filter(
          (a) => a.auction_status === AUCTION_STATUS.FINISHED,
        );
        break;

      default:
        if (selected_auction_id) {
          specific_selected = true;
          auctions = this.props.auctions.filter(
            (a) => a.auction_id === selected_auction_id,
          );
        } else {
          auctions = this.props.auctions; // Or handle the default case as needed
        }
        break;
    }

    return (
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        {/* {this.state.show_back && ( */}
        {/*   <div */}
        {/*     className={"byggbuddy-auction-container-back"} */}
        {/*     style={{ padding: "15px", cursor: "pointer" }} */}
        {/*   > */}
        {/*     <div */}
        {/*       style={{ */}
        {/*         textDecoration: "none", */}
        {/*         color: "#404040", */}
        {/*         display: "flex", */}
        {/*         alignItems: "center", */}
        {/*       }} */}
        {/*       onClick={() => window.history.back()} */}
        {/*     > */}
        {/*       <span className="material-icons">arrow_back_ios</span> Tillbaka */}
        {/*     </div> */}
        {/*   </div> */}
        {/* )} */}

        {!specific_selected && auctions.length !== 0 && (
          <div
            className={
              "byggbuddy-auction-list" +
              (specific_selected ? " item-selected" : "")
            }
            style={{
              flexShrink: "0",
              flexGrow: "0",
              borderRight: "1px solid rgb(192, 192, 192)",
            }}
          >
            {auctions.map((auction) => (
              <div
                key={auction.auction_id}
                className={
                  "byggbuddy-auction-card" +
                  (auction.auction_id === selected_auction_id
                    ? " selected"
                    : "")
                }
                style={{
                  padding: "15px",
                  cursor: "pointer",
                  borderBottom: "1px solid rgb(192, 192, 192)",
                }}
                onClick={() => this.selectAuction(auction.auction_id)}
              >
                {this.renderAuctionCard(auction)}
              </div>
            ))}
          </div>
        )}

        {specific_selected ? (
          <div
            className={"byggbuddy-auction-container item-selected"}
            style={{ flexGrow: "1" }}
          >
            <ProviderAuction {...this.props} auction_id={selected_auction_id} />
          </div>
        ) : null}

        {!specific_selected && !auctions.length ? (
          <div
            className={"byggbuddy-auction-list"}
            style={{
              flexShrink: "0",
              flexGrow: "0",
              borderRight: "1px solid rgb(192, 192, 192)",
            }}
          >
            <i>Här var det tomt</i>
          </div>
        ) : null}
      </div>
    );
  }

  renderAuctionCard(auction) {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: "35%",
            maxWidth: "150px",
            flexGrow: 0,
            flexShrink: 5,
          }}
        >
          <img
            src={
              auction.job_images.length
                ? auction.job_images[0].job_image_url
                : require("../../../assets/images/background-5.jpg")
            }
            style={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "65%",
            marginLeft: "15px",
          }}
        >
          <div style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            {auction.job_name}
          </div>
          <div style={{ marginBottom: "10px" }}>{auction.geolocation_text}</div>
          <div style={{ fontSize: "14px" }}>
            {auction.first_name} {auction.last_name}
          </div>
          {auction.job_hours ? (
            <div style={{ fontSize: "14px" }}>
              Uppskattad omfattning{" "}
              {utility.niceNumberFormat(auction.job_hours)}
            </div>
          ) : null}
          <div style={{ fontSize: "14px" }}>
            Start tidigast {auction.preferred_startdate}
          </div>
          <div style={{ fontSize: "14px" }}>
            Färdigt senast {auction.preferred_enddate}
          </div>
        </div>
      </div>
    );
  }

  renderByggbuddyContent() {
    return <>Not allowed!</>;
  }
}

const mapStateToProps = (state) => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  auth: state.auth,
  ui: state.ui,
  auctions: state.auctions.auctions,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderAuctionsScreen);
