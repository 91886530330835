import { 
  AUTH_SET_TOKEN, 
  AUTH_REMOVE_TOKEN,
  START_AUTHENTICATING,
  STOP_AUTHENTICATING,
  START_REFRESHING_TOKEN,
  STOP_REFRESHING_TOKEN,
  SET_USER, 
  SET_PROVIDER_ACCOUNT, 
  SET_DEVICE_TOKEN, 
  SET_DEVICE_ID, 
  SET_ZIP_AREA,
  SET_API_ENDPOINT,
  SET_SHOP_SCOPE,
} from './actionTypes'
import { uiStartLoading, uiStopLoading, uiStartSaving, uiStopSaving, setBranding } from './ui'
import { pushDebugMessage } from './debug'
import startMainApp from '../../screens/MainApp/startMainApp'
//import stopMainApp from '../../screens/MainApp/stopMainApp'
import showNoConnection from '../../screens/MainApp/showNoConnection'
import showObsoleteApp from '../../screens/MainApp/showObsoleteApp'
import { getAuctions } from '../../store/actions/auctions'
import { getJobs } from '../../store/actions/jobs'
import { getShopData, resetSearch, resetCart, loadCart, loadStoredShop, setShopProviderAccountId } from '../../store/actions/shop'
import { goToScreen } from '../../store/actions/ui'
//import { resolve } from 'rsvp'
import { Socket } from '../../services/socket.service'
import { Analytics } from '../../services/analytics.service'
import  build  from '../../../package.json'

export const tryAuth = auth_data => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(startAuthenticating())

      let params = {
        response_type: 'password',
        scope: auth_data.scope,
        username: auth_data.email,
        password: auth_data.password,
      }

      Socket.authorize(params)
      .then(auth_result => {

        if (!auth_result.access_token) {
          //alert('Authentication failed, please try again!')
          console.warn(auth_result)
          reject('No access token')
          return
        } else {
          dispatch(
            authStoreToken(
              auth_result.access_token,
              auth_result.expires_in,
              auth_result.refresh_token,
              auth_result.scope,
            )
          )
          .then(() => {
            return Promise.all([
              dispatch(getAuctions()),
              dispatch(getJobs()),
              dispatch(getShopData())
            ])
          })
          // Load user info
          .then(() => {
            if (auth_result.scope == 'customer' || auth_result.scope == 'provider') {
              return dispatch(getUser())
            }
            else {
              dispatch(setUser(null))
              return Promise.resolve()
            }
          })
          .then(() => {
            // Load provider info
            if (auth_result.scope == 'provider') {
              return dispatch(getProviderAccount())
            }
            else {
              return Promise.resolve()
            }
          })
          // Start UI
          .then(() => {
            //dispatch(loadCart())
            
            // Check if app version is current
            let min_build = 9999
            let settings = getState().shop.settings
            for (var i = 0; i < settings.length; i++) {
              let setting = settings[i]
              if (setting.setting_name == 'MIN_BUILD_WEB') {
                min_build = setting.int
              }
            }

            if (build < min_build) {
              dispatch(stopAuthenticating())
              showObsoleteApp()
              reject('Too old app version')
            }
            else {

              // Firebase analytics
              Analytics.logLogin({method: 'password'})
              Analytics.logEvent('login_customer')
              let user = getState().auth.user
              if (auth_result.scope == 'customer') {
                console.warn('SET USER ID', 'customer_' + user.customer_id)  
                Analytics.setUserId('customer_' + user.customer_id)
                Analytics.setUserProperty('provider_account_id', '0')
              }
              if (auth_result.scope == 'provider') {
                Analytics.setUserId('provider_' + user.provider_user_id)
                Analytics.setUserProperty('provider_account_id', 'provider_account_' + user.provider_account_id)
              }
              
              // startMainApp()
              dispatch(stopAuthenticating())
              Socket.startSocket()
              resolve()

              // Connect device token to this new login
              if (getState().auth.device_token) {
                Socket.setDeviceToken(getState().auth.device_token)
              }
            }
          })
          .catch(error => {
            console.warn(error)
            reject(error)
          })
        }
      })
      .catch(auth_results => {

        console.log("AUTH ERROR", auth_results.error)
        console.warn(auth_results.error)
        dispatch(stopAuthenticating())
        reject(auth_results)
      })
    })
  }
}

export const anonymousAuth = authData => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch(startAuthenticating())
      
      let params = {
        response_type: 'client_credentials',
        scope: 'anonymous',
        client_id: 'se.byggbuddy.app',
        client_secret: 'abc123',
      }

      //console.warn('QA')
      Socket.authorize(params)
      .then(auth_result => {

        //dispatch(uiStopLoading())
        if (!auth_result.access_token) {
          //alert('Anonymous authentication failed!')
          //console.warn(auth_result)
          reject('No access token')
          return
        } else {
          //console.warn('QB')
          dispatch(
            authStoreToken(
              auth_result.access_token,
              auth_result.expires_in,
              auth_result.refresh_token,
              auth_result.scope,
            )
          )
          .then(() => {
            //console.warn('QC')
            return Promise.all([
              dispatch(getAuctions()),
              dispatch(getJobs()),
              dispatch(getShopData())
            ])
          })
          // Load user info
          .then(() => {
            //console.warn('QD')
            if (auth_result.scope == 'customer' || auth_result.scope == 'provider') {
              return dispatch(getUser())
            }
            else {
              dispatch(setUser(null))
              return Promise.resolve()
            }
          })
          .then(() => {
            //console.warn('QE')
            // Load provider info
            if (auth_result.scope == 'provider') {
              return dispatch(getProviderAccount())
            }
            else {
              return Promise.resolve()
            }
          })
          // Start UI
          .then(() => {
            dispatch(loadCart())
            
            // Check if app version is current
            let min_build = 9999
            let settings = getState().shop.settings
            for (var i = 0; i < settings.length; i++) {
              let setting = settings[i]
              if (setting.setting_name == 'MIN_BUILD_WEB') {
                min_build = setting.int
              }
            }

            if (build < min_build) {
              dispatch(stopAuthenticating())
              showObsoleteApp()
              reject('Too old app version')
            }
            else {
            
              // Firebase analytics
              if (auth_result.scope == 'anonymous') {
                Analytics.setUserId('anonymous_' + getState().auth.device_id)
                Analytics.setUserProperty('provider_account_id', '0')
              }
              
              // startMainApp()
              dispatch(stopAuthenticating())
              Socket.startSocket()
              resolve()

              // Connect device token to this new login
              if (getState().auth.device_token) {
                Socket.setDeviceToken(getState().auth.device_token)
              }
            }
            //console.warn('QG')
          })
          .catch(error => {
            console.warn(error)
            reject(error)
          })
        }
      })
      .catch(err => {

        console.log("AUTH ERROR", err)
        //alert('Anonymous authentication failed!')
        console.warn(err)
        dispatch(stopAuthenticating())
        reject(err)
      })
    })
  }
}

export const authStoreToken = (token, expires_in, refreshToken, scope) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      const now = new Date()
      const expiryDate = now.getTime() + expires_in * 1000
      dispatch(authSetToken(token, expiryDate, scope))
      .then(async () => {
        localStorage.setItem('ap:auth:token', token)
        localStorage.setItem('ap:auth:expiryDate', expiryDate.toString())
        localStorage.setItem('ap:auth:scope', scope)
        localStorage.setItem('ap:auth:refreshToken', refreshToken)
        
        resolve()
      })
    })
  }
}

export const authSetToken = (token, expiryDate, scope) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: AUTH_SET_TOKEN,
        token: token,
        token_expiry_date: expiryDate,
        scope: scope,
      })
      resolve()
    })
  }
}

export const autoAuth = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {

      dispatch(startAuthenticating())
      dispatch(loadStoredShop()) // Load cached version of shop

      // Handle if access token sent in url
      let query = new URLSearchParams(window.location.search)
      let token = query.get("token")
      let expires = query.get("expires")
      let scope = query.get("scope")

      Promise.resolve()
      .then(() => {
        if (token && expires && scope) {
          return dispatch(authSetToken(token, expires, scope))
        }
        else {
          return Promise.resolve()
        }
      })
      .then(() => {
        if (token && expires && scope) {
          const now = new Date()
          const expires_in = Math.round(expires - now.getTime() / 1000)
          return dispatch(
            authStoreToken(
              token,
              expires_in,
              null,
              scope,
            )
          )
        }
        else {
          return Promise.resolve()
        }
      })

      // Try login with existing token
      .then(() => {
        return dispatch(authGetToken())  
      })
      .then(token => {
        dispatch(pushDebugMessage('Auth: Found token'))
        //Socket.setAccessToken(token)
        return Promise.all([
          dispatch(getAuctions()),
          dispatch(getJobs()),
          dispatch(getShopData())
        ])
      })
      
      // Load user info
      .then(() => {
        let scope = getState().auth.scope
        //console.warn('E')
        if (scope == 'customer' || scope == 'provider') {
          //console.warn('E1')
          return dispatch(getUser())
        }
        else {
          dispatch(setUser(null))
          //console.warn('E2')
          return Promise.resolve()
        }
      })
      .then(() => {
        let scope = getState().auth.scope
        //console.warn('F')
        // Load provider info
        if (scope == 'provider') {
          return dispatch(getProviderAccount())
        }
        else {
          return Promise.resolve()
        }
      })
      // Start UI
      .then(() => {
        dispatch(loadCart())
        
        // Firebase analytics
        let user = getState().auth.user
        let scope = getState().auth.scope
        if (scope == 'customer') {
          //console.warn('SET USER ID', 'customer_' + user.customer_id)
          Analytics.logEvent('login_customer')
          Analytics.setUserId('customer_' + user.customer_id)
          Analytics.setUserProperty('provider_account_id', '0')
        }
        else if (scope == 'provider') {
          //console.warn('SET USER ID', 'provider_' + user.provider_user_id, user.provider_account_id)
          Analytics.logEvent('login_provider')
          Analytics.setUserId('provider_' + user.provider_user_id)
          Analytics.setUserProperty('provider_account_id', 'provider_account_' + user.provider_account_id)
        }
        else if (scope == 'anonymous') {
          //console.warn('SET USER ID', 'anonymous_' + getState().auth.device_id)  
          Analytics.setUserId('anonymous_' + getState().auth.device_id)
          Analytics.setUserProperty('provider_account_id', '0')
        }

        // Check if app version is current
        let min_build = 9999
        let settings = getState().shop.settings
        for (var i = 0; i < settings.length; i++) {
          let setting = settings[i]
          if (setting.setting_name == 'MIN_BUILD_WEB') {
            min_build = setting.int
          }
        }

        if (build < min_build) {
          dispatch(stopAuthenticating())
          showObsoleteApp()
          reject('Too old app version ' + build + ' < ' + min_build)
        }
        else {
          // startMainApp()
          dispatch(stopAuthenticating())
          Socket.startSocket()
          resolve()

          // Connect device token to this new login
          if (getState().auth.device_token) {
            Socket.setDeviceToken(getState().auth.device_token)
          }
        }
      }) 
      .catch(err => {
        console.warn('Auto auth error', err)
        // Try to login as anonymous
        if (err == 'Server down' || err == 'No connection') {
          dispatch(stopAuthenticating())
          showNoConnection()
          reject(err)
        }
        else {
          dispatch(anonymousAuth())
          .then(() => {
            dispatch(stopAuthenticating())
            resolve()
          })
          .catch((error) => {
            dispatch(authClearStorage())
            dispatch(stopAuthenticating())
            showNoConnection()
            reject(error)
          })
        }
      })
    })
  }
}

export const authGetToken = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // Check if we have token in state and that it hasn't expired
      let token = getState().auth.token
      let expiryDate = getState().auth.token_expiry_date
      let scope = getState().auth.scope

      let refresh = false

      //dispatch(pushDebugMessage('Auth: Get token from state'))
      if (!token || !expiryDate || new Date() >= new Date(expiryDate)) {
        
        refresh = true

        // Try to get token from storage
        if (!token || !expiryDate) {
          dispatch(pushDebugMessage('Auth: Get token from storage'))
          token = await localStorage.getItem('ap:auth:token')
          if (!token) {
            reject()
            return
          }

          const expiryDate = await localStorage.getItem('ap:auth:expiryDate')
          if (!expiryDate) {
            reject('No token')
            return
          }

          const scope = await localStorage.getItem('ap:auth:scope')
          if (!scope) {
            reject('No token')
            return
          }

          dispatch(pushDebugMessage('Auth: Token expiry date: ' + new Date(parseInt(expiryDate)).toString()))

          const parsedExpiryDate = new Date(parseInt(expiryDate))
          const now = new Date()
          // If we have a stored token, and it has not expired, set that token
          if (now < parsedExpiryDate) {
            dispatch(pushDebugMessage('Auth: Set token to state'))
            dispatch(authSetToken(token, expiryDate, scope))
            .then(() => {
              resolve(token)  
            })
            refresh = false
            return
          }
        }

        // Else, try refresh using stored refresh token
        if (refresh) {
          //console.warn('Auth: Try refresh token')
          dispatch(pushDebugMessage('Auth: Try refresh token'))
          const refreshToken = await localStorage.getItem('ap:auth:refreshToken')
          dispatch(pushDebugMessage('Refresh token: ' + refreshToken))
          if (!refreshToken) {
            reject('No token')
            return
          }

          const params = {
            response_type: 'refresh_token',
            scope: scope,
            refresh_token: refreshToken,
          }

          if (getState().auth.is_refreshing_token) {
            // Concurrent refresh is ongoing, wait and pick up token
            console.warn('Concurrent refresh')
            setTimeout(() => {
              let token = getState().auth.token
              let expiry_date = parseInt(getState().auth.token_expiry_date)
              console.warn('Concurrent refresh - stop wait')

              if (!token || !expiryDate || new Date() >= new Date(expiry_date)) {
                console.warn('Concurrent refresh - fail')
                reject('No token')
              }
              else {
                console.warn('Concurrent refresh - success')
                resolve(token)
              }
            }, 5000)
          }
          else {
            dispatch(startRefreshingToken())

            Socket.authorize(params)
            .then(auth_result => {

              dispatch(uiStopLoading())
              if (!auth_result.access_token || auth_result.error) {
                
                // Our refresh token has expired, let's login as anonymous instead
                dispatch(anonymousAuth())
                .then(() => {
                  dispatch(stopRefreshingToken())
                  resolve(getState().auth.token)
                })
                .catch((error) => {
                  dispatch(authClearStorage())
                  dispatch(stopRefreshingToken())
                  reject(error)
                })
              } else {
                token = auth_result.access_token
                dispatch(pushDebugMessage('Auth: Got refreshed token'))
                dispatch(
                  authStoreToken(
                    auth_result.access_token,
                    auth_result.expires_in,
                    auth_result.refresh_token,
                    auth_result.scope,
                  )
                )
                .then(() => {
                  dispatch(stopRefreshingToken())
                  resolve(token)
                })
                .catch(error => {
                  console.warn(error)
                  dispatch(authClearStorage())
                  dispatch(stopRefreshingToken())
                  reject(error)
                })
              }
            })
            .catch(err => {

              // TODO HANDLE NO CONNECTION
              
              // Our refresh token has expired, let's login as anonymous instead
              console.warn('Refresh token error - trying anonymousAuth')
              dispatch(anonymousAuth())
              .then(() => {
                dispatch(stopRefreshingToken())
                resolve(getState().auth.token)
              })
              .catch((err) => {
                dispatch(authClearStorage())
                dispatch(stopRefreshingToken())
                reject(err)
              })
            })
          }
        }
      }
      else {
        resolve(token)
      }
    })
  }
}

export const authClearStorage = () => {
  return dispatch => {
    dispatch(authRemoveToken())
    localStorage.removeItem('ap:auth:token')
    localStorage.removeItem('ap:auth:expiryDate')
    localStorage.removeItem('ap:auth:refreshToken')
    Socket.unauthorize()

    // startMainApp()
  }
}

export const authLogout = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {

      // Clean up data
      dispatch(resetSearch())
      dispatch(resetCart())
      dispatch(setZipArea(null))
      
      // Try to shift to anonymous login
      dispatch(anonymousAuth())
      .then(() => {
        resolve()
      })
      .catch(() => {
        dispatch(authClearStorage())
        reject()
      })
    })
  }
}

export const authRemoveToken = () => {
  return {
    type: AUTH_REMOVE_TOKEN,
  }
}

export const getUser = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {

      dispatch(uiStartLoading())
      dispatch(pushDebugMessage('Load user'))
      Socket.getUser()
      .then(user => {
        dispatch(setUser(user))
        dispatch(pushDebugMessage('Load user successful'))
        dispatch(uiStopLoading())
        resolve()
      })
      .catch(err => {
        dispatch(pushDebugMessage('Load user failed'))
        console.warn(err)
        dispatch(uiStopLoading())
        reject(err)
      })
    })
  }
}

export const approveUserTerms = (user_terms_approval) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      Socket.approveUserTerms(user_terms_approval)
      .then((response) => {
        dispatch(setUser(response.getUser.user))
        resolve()
      })
      .catch(err => {
        console.warn(err)
        reject(err)
        alert('Användaruppgifterna kunde inte sparas')
      })
    })
  }
}

export const setUser = (user) => {
  return {
    type: SET_USER,
    user: user,
  }
}

export const getProviderAccount = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {

      dispatch(uiStartLoading())
      dispatch(pushDebugMessage('Load provider'))
      
      Socket.getProviderAccount()
      .then(provider_account => {

        dispatch(setProviderAccount(provider_account))
        dispatch(pushDebugMessage('Load provider successful'))
        dispatch(uiStopLoading())
        resolve()
      })
      .catch(err => {
        dispatch(pushDebugMessage('Load provider failed'))
        console.warn(err)
        dispatch(uiStopLoading())
        reject(err)
      })
    })
  }
}

export const setProviderAccount = (provider_account) => {
  return {
    type: SET_PROVIDER_ACCOUNT,
    provider_account: provider_account,
  }
}

export const setDeviceToken = (device_token) => {
  /*Socket.setDeviceToken(device_token)
  .catch(error => {
    console.warn(error)
  })*/
  return {
    type: SET_DEVICE_TOKEN,
    device_token: device_token,
  }
}

export const ensureDeviceID = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      
      // Check if we have device ID in state
      let device_id = getState().auth.device_id
      
      if (device_id) {
        resolve()
      }
      else {
        device_id = await localStorage.getItem('ap:auth:device_id')
        if (!device_id) {
          device_id = ''
          for (var i = 0; i < 32; i++) {
            device_id += String.fromCharCode(Math.floor(Math.random() * 25) + 65)
          }
          await localStorage.setItem('ap:auth:device_id', device_id)
          
        }
        dispatch(setDeviceID(device_id))
        resolve()
      }
    })
  }
}

export const setDeviceID = (device_id) => {
  return {
    type: SET_DEVICE_ID,
    device_id: device_id,
  }
}

export const signupCustomer = (customer) => {
  return dispatch => {
    return new Promise((resolve, reject) => {

      dispatch(uiStartSaving())
      dispatch(pushDebugMessage('Signup new customer'))

      // Firebase analytics
      Analytics.logSignUp({method: 'email'})
      Analytics.logEvent('signup_customer')
      
      Socket.signupCustomer(customer)
      .then(() => {
        dispatch(pushDebugMessage('Signup new customer successful'))

        //Sign in new user
        const auth_data = {
          scope: 'customer',
          email: customer.email,
          password: customer.password,
        }
        dispatch(uiStopSaving())
        dispatch(tryAuth(auth_data))
        .then((error) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
      .catch(err => {
        console.warn(err)
        //alert('Something went wrong, please try again!')
        dispatch(pushDebugMessage('Signup new customer failed: ' + JSON.stringify(err)))
        dispatch(uiStopSaving())

        reject(err)
      })
    })
  }
}

export const updateCustomer = (customer) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      Socket.updateCustomer(customer)
      .then(() => {
        dispatch(getUser())
        .then(() => {
          resolve()
        })
        .catch(error => {
          console.warn(error)
          reject(error)
        })
      })
      .catch(err => {
        console.warn(err)
        reject(err)
        alert('Användaruppgifterna kunde inte sparas')
      })
    })
  }
}

export const resetCustomerPassword = (customer) => {
  return dispatch => {
    Socket.resetCustomerPassword(customer)
    .then(() => {
      // Do nothing
    })
    .catch(err => {
      console.warn(err)
    })
  }
}

export const signupProvider = (provider_account, provider_user) => {
  return dispatch => {
    return new Promise((resolve, reject) => {

      dispatch(uiStartSaving())
      dispatch(pushDebugMessage('Signup new provider'))

      // Firebase analytics
      Analytics.logSignUp({method: 'email'})
      Analytics.logEvent('signup_provider')
      
      Socket.signupProvider(provider_account, provider_user)
      .then(() => {
        dispatch(pushDebugMessage('Signup new provider successful'))

        //Sign in new user
        const auth_data = {
          scope: 'provider',
          email: provider_user.email,
          password: provider_user.password,
        }
        dispatch(uiStopSaving())
        dispatch(tryAuth(auth_data))
        .then((error) => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
      .catch(err => {
        console.warn(err)
        //alert('Something went wrong, please try again!')
        dispatch(pushDebugMessage('Signup new provider failed: ' + JSON.stringify(err)))
        dispatch(uiStopSaving())
        //alert('Signup new provider failed!')
        reject(err)
      })
    })
  }
}

export const resetProviderUserPassword = (provider_user) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      Socket.resetProviderUserPassword(provider_user)
      .then(() => {
        resolve()
      })
      .catch(err => {
        console.warn(err)
        reject()
      })
    })
  }
}

export const createProviderUser = (provider_user) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      Socket.createProviderUser(provider_user)
      .then((response) => {
        dispatch(getProviderAccount())
        resolve()
      })
      .catch(err => {
        console.warn(err)
        reject(err)
        alert('Kontouppgifterna kunde inte sparas')
      })
    })
  }
}

export const updateProviderUser = (provider_user) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      Socket.updateProviderUser(provider_user)
      .then((response) => {
        Promise.resolve()
        .then(() => {
          if (provider_user.provider_user_id == getState().auth.user.provider_user_id) {
            return dispatch(getUser())
          }
          else {
            return Promise.resolve()
          }
        })
        .then(() => {
          return dispatch(getProviderAccount())
        })
        .then(() => {
          resolve()
        })
        .catch(error => {
          console.warn(error)
          reject(error)
        })
      })
      .catch(err => {
        console.warn(err)
        reject()
        alert('Kontouppgifterna kunde inte sparas')
      })
    })
  }
}

export const deleteProviderUser = (provider_user_id) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      if (provider_user_id == getState().auth.user.provider_user_id && getState().auth.scope == 'provider') {
        alert('Fel! Kan inte ta bort inloggad användare')
        reject()
        return
      }

      Socket.deleteProviderUser(provider_user_id)
      .then((response) => {
        Promise.resolve()
        .then(() => {
          return dispatch(getProviderAccount())
        })
        .then(() => {
          resolve()
        })
        .catch(error => {
          console.warn(error)
          reject(error)
        })
      })
      .catch(err => {
        console.warn(err)
        reject()
        alert('Kontouppgifterna kunde inte sparas')
      })
    })
  }
}

export const updateProviderAccount = (provider_account) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      Socket.updateProviderAccount(provider_account)
      .then((response) => {
        if (response.updateProviderAccount.provider_account) {
          dispatch(setProviderAccount(response.updateProviderAccount.provider_account))
        }
        dispatch(getAuctions())
        resolve()
      })
      .catch(err => {
        console.warn(err)
        reject(err)
        alert('Kontouppgifterna kunde inte sparas')
      })
    })
  }
}

export const saveProviderCompetences = (provider_competences) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      Socket.saveProviderCompetences(provider_competences)
      .then((response) => {
        if (response.getProviderAccount.provider_account) {
          dispatch(setProviderAccount(response.getProviderAccount.provider_account))
        }
        dispatch(getAuctions())
        resolve()
      })
      .catch(err => {
        console.warn(err)
        reject(err)
        alert('Kontouppgifterna (kompetenser) kunde inte sparas')
      })
    })
  }
}

export const saveProviderLocations = (provider_locations) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      Socket.saveProviderLocations(provider_locations)
      .then((response) => {
        if (response.getProviderAccount.provider_account) {
          dispatch(setProviderAccount(response.getProviderAccount.provider_account))
        }
        dispatch(getAuctions())
        resolve()
      })
      .catch(err => {
        console.warn(err)
        reject(err)
        alert('Kontouppgifterna (platser) kunde inte sparas')
      })
    })
  }
}

export const saveProviderImages = (provider_images) => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      Socket.saveProviderImages(provider_images)
      .then((response) => {
        if (response.getProviderAccount.provider_account) {
          dispatch(setProviderAccount(response.getProviderAccount.provider_account))
        }
        resolve()
      })
      .catch(err => {
        console.warn(err)
        reject(err)
        alert('Kontouppgifterna (bilder) kunde inte sparas')
      })
    })
  }
}

export const setZipArea = (zip_area) => {
  return {
    type: SET_ZIP_AREA,
    zip_area: zip_area,
  }
}

export const setApiEndpoint = (api_endpoint) => {
  return async dispatch => {
    await localStorage.setItem('ap:auth:api_endpoint', api_endpoint)
    dispatch ({
      type: SET_API_ENDPOINT,
      api_endpoint: api_endpoint,
    })
    Socket.startSocket()
  }
}

export const setShopScope = (shop_scope) => {
  return async dispatch => {
    await localStorage.setItem('ap:auth:shop_scope', shop_scope)
    dispatch ({
      type: SET_SHOP_SCOPE,
      shop_scope: shop_scope,
    })
    //Socket.startSocket()
  }
}

export const startAuthenticating = () => {
  return {
    type: START_AUTHENTICATING,
  }  
}

export const stopAuthenticating = () => {
  return {
    type: STOP_AUTHENTICATING,
  }  
}

export const startRefreshingToken = () => {
  return {
    type: START_REFRESHING_TOKEN,
  }  
}

export const stopRefreshingToken = () => {
  return {
    type: STOP_REFRESHING_TOKEN,
  }  
}