import React from 'react';
import { connect } from "react-redux";

import { Switch, Route, Redirect, Link } from 'react-router-dom';

import CustomerOverview from './CustomerOverview'
import CustomerAuctions from './Auctions/CustomerAuctions'
import CustomerViewBid from './Auctions/CustomerViewBid'
import CustomerJobs from './Jobs/CustomerJobs'

class Customer extends React.Component {

  componentDidMount() {
  }

  render() {

    let root = this.props.ui.is_custom_domain ? '' : '/:provider'

    return (
      <div className="byggbuddy-customer">
        
        <Route path={root+'/customer/'} exact                                 component={CustomerOverview} />
        <Route path={root+'/customer/auctions/:auction_id/bids/:bid_id'}      component={CustomerViewBid} />
        <Route path={root+'/customer/auctions/:auction_id?'}                  component={CustomerAuctions} />
        <Route path={root+'/customer/jobs/:jobs_filter'}                      component={CustomerJobs} />
        
      </div>
    )
  }
}

function mapStateToProps(state) {
   return {
     ui: state.ui,
   };
 
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
