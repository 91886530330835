import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import DefaultInput from '../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../components/UI/DefaultButton/DefaultButton';

import { tryAuth, authLogout } from './../../store/actions/auth'
import { setMenuOpen } from './../../store/actions/ui'
import validate from '../../utility/validation'

class Header extends React.Component {

  state = {
    controls: {
      customer_email: {
        value: '',
        valid: false,
        validationRules: {
          notEmpty: true,
          isEmail: true,
        },
        touched: false
      },
      customer_password: {
        value: '',
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false
      },
      provider_email: {
        value: '',
        valid: false,
        validationRules: {
          notEmpty: true,
          isEmail: true,
        },
        touched: false
      },
      provider_password: {
        value: '',
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false
      },
    },
    login_open: false,
    account_open: false,
  }

  updateInputState = (key, value) => {
    
    let valid = validate(value, this.state.controls[key].validationRules)
    this.setState(prevState => {
      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          [key]: {
            ...prevState.controls[key],
            value: value,
            valid: valid,
            touched: true
          }
        },
        any_touched: true,
      }
    })
  }

  getRoot = () => {

    if (this.props.ui.is_branded) {
      if (this.props.ui.is_custom_domain) {
        return ''
      }
      else {
        if (this.props.ui.brand_provider_account.provider_identifier) {
          return '/' + this.props.ui.brand_provider_account.provider_identifier
        }
        else {
          return '/' + this.props.ui.brand_provider_account.provider_account_id
        }
      }
    }
    else {
      return '/byggbuddy'
    }
  }

  toggleMenu = () => {
    this.props.setMenuOpen(!this.props.ui.menu_open)
  }

  toggleLogin = () => {
    this.setState(prev_state => {
      return {
        ...prev_state,
        login_open: !prev_state.login_open,
      }
    })
  }

  toggleAccount = () => {
    this.setState(prev_state => {
      return {
        ...prev_state,
        account_open: !prev_state.account_open,
      }
    })
  }

  loginCustomer = () => {
    let auth_data = {
      scope: 'customer',
      email: this.state.controls.customer_email.value,
      password: this.state.controls.customer_password.value,
    }
    this.props.tryAuth(auth_data)
    .then(() => {
      this.toggleLogin()
    })
    .catch(error => {
      alert('Inloggningen misslyckades')
    })
  }

  signupCustomer = () => {
    this.props.history.push(this.getRoot() + '/customer_signup')
  }

  loginProvider = () => {
    let auth_data = {
      scope: 'provider',
      email: this.state.controls.provider_email.value,
      password: this.state.controls.provider_password.value,
    }
    this.props.tryAuth(auth_data)
    .then(() => {
      this.toggleLogin()
      if (this.props.auth.provider_account) {
        if (this.props.auth.provider_account.provider_identifier) {
          this.props.history.push('/' + this.props.auth.provider_account.provider_identifier + '/provider')
        }
        else {
          this.props.history.push('/' + this.props.auth.provider_account.provider_account_id + '/provider')
        }
      }
    })
    .catch(error => {
      alert('Inloggningen misslyckades')
    })
  }

  signupProvider = () => {
    this.props.history.push(this.getRoot() + '/provider_signup')
  }

  resetPasswordProvider = () => {
    this.toggleLogin()
    this.props.history.push(this.getRoot() + '/provider_reset_password')
  }

  resetPasswordCustomer = () => {
    this.toggleLogin()
    this.props.history.push(this.getRoot() + '/customer_reset_password')
  }

  logout = () => {
    this.toggleAccount()
    this.props.authLogout()
    .then(() => {
      this.props.history.push(this.getRoot())
    })
  }

  goHome = () => {
    if (!this.props.ui.is_branded) {
      // Go to global website start
      window.location.href = "https://www.byggbuddy.se/"
    }
    this.props.history.push(this.getRoot())
  }

  goToCart = () => {
    this.props.history.push(this.getRoot() + '/shop/cart')
  }

  goTo = (page) => {
    if (this.state.account_open) {
      this.toggleAccount()
    }
    this.props.history.push(this.getRoot() + page)
  }

  render() {

    let is_logged_in = this.props.auth.scope == 'customer' || this.props.auth.scope == 'provider'
    let can_login = !this.props.ui.is_branded || this.props.ui.brand_provider_account.has_messenger || true
    let has_menu = is_logged_in

    return (
      <div className="byggbuddy-header">
        <div style={{boxSizing:'border-box', width: '100%', backgroundColor: 'white', padding: '0 15px', margin: '0 auto', backgroundColor: 'white', /*maxWidth: '1600px',*/ display:'flex', flexDirection: 'row', justifyContent: 'space-between', /*minWidth: '600px',*/ }}>
          <div style={{boxSizing:'border-box', display:'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            {is_logged_in ? (
               <div className="byggbuddy-menu-icon" onClick={this.toggleMenu} style={{alignItems: 'center', position: 'relative', cursor: 'pointer', marginRight: '15px'}}>
                <span className="material-icons">menu</span>
              </div>
            ) : null}
            <div>
              {this.props.ui.is_branded ? (
                <div onClick={this.goHome}>
                  {this.props.ui.brand_provider_account.provider_logo ? (
                    <img src={this.props.ui.brand_provider_account.provider_logo.image_url} onClick={this.goHome} className="byggbuddy-header-logo" />
                  ) : (
                    <div className="byggbuddy-header-provider-name">{this.props.ui.brand_provider_account.provider_name}</div>
                  )}
                </div>
              ) : (
                <img src="https://app.byggbuddy.se/static/byggbuddyJPEG.jpg" onClick={this.goHome} className="byggbuddy-header-logo" style={{width: '180px'}} />
              )}
            </div>
          </div>
          <div style={{boxSizing:'border-box', display:'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            {/* <div className="byggbuddy-cart-icon" onClick={this.goToCart} style={{alignItems: 'center', position: 'relative', cursor: 'pointer'}}>
              <span className="material-icons">feed</span>
              {this.props.cart.tasks.length > 0 ? (
                <div style={{backgroundColor: 'red', borderRadius: '20px', color: 'white', fontSize: '12px', fontWeight: 'bold', lineHeight: '20px', width: '20px', height: '20px', textAlign: 'center', verticalAlign: 'middle', marginLeft: '6px', position: 'relative', left: '-16px', top: '8px',}}>{this.props.cart.tasks.length}</div>
              ) : null}
            </div> */}
            {is_logged_in ? (
               <div className="byggbuddy-account-icon" onClick={this.toggleAccount} style={{alignItems: 'center', position: 'relative', cursor: 'pointer', marginLeft: '15px', marginTop: '4px'}}>
                <span className="material-icons">account_circle</span>
              </div>
            ) : null}
            {!is_logged_in && can_login ? (
              <div className="byggbuddy-login-icon" onClick={this.toggleLogin} style={{alignItems: 'center', position: 'relative', cursor: 'pointer', marginLeft: '15px', marginTop: '4px'}}>
                <span className="material-icons">login</span>
              </div>
            ) : null}
          </div>
        </div>

        {/* Login drop menu */}
        {this.state.login_open ? (
          <>
            <div style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100vh', backgroundColor: 'rgba(0,0,0,0.6)', zIndex: '99990'}} onClick={this.toggleLogin}>
            </div>
            <div style={{position: 'absolute', right: '20px', top: '100px', zIndex: '99999', maxWidth: '250px' /* '500px' */, backgroundColor: 'white', border: '1px solid rgb(192, 192, 192)', padding: '15px', width: 'calc(100% - 40px)', boxSizing: 'border-box', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
              {/*<div style={{width: 'calc(50% - 8px', minWidth: '200px', display: 'flex', flexDirection: 'column'}}>
                <div style={{textAlign: 'center', fontWeight: 'bold', marginBottom: '10px'}}>Privatperson</div>
                <div style={{marginBottom: '10px', borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: (!this.state.controls.customer_email.value || this.state.controls.customer_email.valid ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <DefaultInput
                   placeholder="E-postadress"
                   value={String(this.state.controls.customer_email.value)}
                   onChange={event => this.updateInputState('customer_email', event.target.value)}
                   style={{backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', border: 'none'}}
                  />
                </div>
                <div style={{marginBottom: '10px', borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: (!this.state.controls.customer_password.value || this.state.controls.customer_password.valid ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <DefaultInput
                   placeholder="Lösenord"
                   password={true}
                   value={String(this.state.controls.customer_password.value)}
                   onChange={event => this.updateInputState('customer_password', event.target.value)}
                   style={{backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', border: 'none'}}
                  />
                </div>
                <div style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                  <DefaultButton
                    style={{width: '100%', backgroundColor: '#167f40', margin: '15px 0'}}
                    onClick={() => this.loginCustomer()}
                    disabled={!this.state.controls.customer_email.valid || !this.state.controls.customer_password.valid}
                  >
                    Logga in
                  </DefaultButton>
                </div>
                <div style={{textAlign: 'center', color: 'blue', cursor: 'pointer'}} onClick={this.resetPasswordCustomer}>Glömt lösenord</div>
                <div style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px'}}>
                  <DefaultButton
                    style={{width: '100%', backgroundColor: 'white', border: '2px solid #167f40', color: '#167f40', margin: '15px 0'}}
                    onClick={() => this.signupCustomer()}
                  >
                    Skapa konto
                  </DefaultButton>
                </div>
              </div>*/}
              <div style={{width: '100%' /*'calc(50% - 8px'*/, minWidth: '200px', display: 'flex', flexDirection: 'column'}}>
                <div style={{textAlign: 'center', fontWeight: 'bold', marginBottom: '10px'}}>Hantverkare</div>
                <div style={{marginBottom: '10px', borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: (!this.state.controls.provider_email.value || this.state.controls.provider_email.valid ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <DefaultInput
                   placeholder="E-postadress"
                   value={String(this.state.controls.provider_email.value)}
                   onChange={event => this.updateInputState('provider_email', event.target.value)}
                   style={{backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', border: 'none'}}
                  />
                </div>
                <div style={{marginBottom: '10px', borderStyle: 'solid', borderRadius: '4px', borderWidth: 1, borderColor: (!this.state.controls.provider_password.value || this.state.controls.provider_password.valid ? '#808080' : 'red'), flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <DefaultInput
                   placeholder="Lösenord"
                   password={true}
                   value={String(this.state.controls.provider_password.value)}
                   onChange={event => this.updateInputState('provider_password', event.target.value)}
                   style={{backgroundColor: '#ffffff', textAlign: 'left', color: '#404040', width: '100%', border: 'none'}}
                  />
                </div>
                <div style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                  <DefaultButton
                    style={{width: '100%', backgroundColor: '#167f40', margin: '15px 0'}}
                    onClick={() => this.loginProvider()}
                    disabled={!this.state.controls.provider_email.valid || !this.state.controls.provider_password.valid}
                  >
                    Logga in
                  </DefaultButton>
                </div>
                <div style={{textAlign: 'center', color: 'blue', cursor: 'pointer'}} onClick={this.resetPasswordProvider}>Glömt lösenord</div>
              </div>
            </div>
          </>
        ) : null}

        {/* Account drop menu */}
        {this.state.account_open ? (
          <>
            <div style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100vh', backgroundColor: 'rgba(0,0,0,0.6)', zIndex: '99990'}} onClick={this.toggleAccount}>
            </div>
            <div style={{position: 'absolute', right: '20px', top: '100px', zIndex: '99999', maxWidth: '250px', backgroundColor: 'white', border: '1px solid rgb(192, 192, 192)', padding: '15px', width: 'calc(100% - 40px)', boxSizing: 'border-box', display: 'flex', flexDirection: 'column'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold'}}>
                {this.props.auth.user.first_name} {this.props.auth.user.last_name}
              </div>
              {this.props.auth.scope == 'provider' ? (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  {this.props.auth.provider_account.provider_name}
                </div>
              ) : null}
              <div onClick={this.logout} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
                <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                  <span className="material-icons">logout</span>
                </div>
                <div>
                  Logga ut
                </div>
              </div>
              {this.props.auth.scope == 'provider' ? (
                <div onClick={() => this.goTo('/provider/change_password')} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', cursor: 'pointer'}}>
                  <div className="byggbuddy-icon" style={{alignItems: 'center', marginRight: '15px', marginTop: '4px'}}>
                    <span className="material-icons">password</span>
                  </div>
                  <div>
                    Byt lösenord
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
   return {
     //screen: state.ui.screen,
     auth: state.auth,
     cart: state.shop.cart,
     ui: state.ui,
   };
 
}

function mapDispatchToProps(dispatch) {
  return {
    setMenuOpen: (menu_open) => dispatch(setMenuOpen(menu_open)),
    tryAuth: (auth_data) => dispatch(tryAuth(auth_data)),
    authLogout: () => dispatch(authLogout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)