export const AUCTION_STATUS = Object.freeze({
  ONGOING: "ongoing",
  ACCEPTED: "accepted",
  BID_SENT: "bid_sent",
  ABORTED: "aborted",
  DECLINED: "declined",
  FINISHED: "finished",
});

export const PROVIDER_AUCTION_STATUS = Object.freeze({
  INVITED: "invited",
  ACCEPTED: "accepted",
  ABORTED: "aborted",
  DECLINED: "declined",
});

export const BID_STATUS = Object.freeze({
  OPEN: "open",
  UNSIGNED: "unsigned",
  SOFT_ACCEPTED: "soft_accepted", // provider has registered that it has recieved a yes via phone/email etc
  SOFT_DECLINED: "soft_declined", // provider has registered that it has recieved a no via phone/email etc
});
