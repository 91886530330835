import { 
    SET_TASKS,
    SET_TASK_ITEMS,
    SET_TASK_ITEM_IMAGES,
    SET_TASK_ITEM_QUESTIONS,
    SET_TASK_ITEM_PREREQUISITES,
    SET_DRIVERS,
    SET_OPTIONS,
    SET_SERVICES,
    SET_SETTINGS,
    SET_COMPETENCES,
    SET_PROPERTIES,
    SET_GEOLOCATIONS,
    SET_PROVIDER_ACCOUNTS,

    SET_SHOP_PROVIDER_ACCOUNT_ID,

    SELECT_SEARCH_TASK,
    SELECT_SEARCH_TASK_ITEM,
    SET_SEARCH_DRIVER_VALUE,
    SET_SEARCH_TASK_TEXT,
    ADD_IMAGE_TO_TASK,
    REMOVE_IMAGE_FROM_TASK,
    DONE_IMAGES_TO_TASK,
    SET_SEARCH_TASK_ANSWER,
    CONFIRM_PREREQUISITES,
    INITIATE_CHECKOUT,
    ADD_SEARCH_TO_CART,
    RESET_SEARCH,

    RESET_CART_JOB_OPTIONS,
    SELECT_CART_JOB_OPTION,
    SET_CART_MAX_PRICE_PER_HOUR,
    SET_CART_ADDRESS,
    SET_CART_SPEED,
    SET_CART_PREFERRED_STARTDATE,
    SET_CART_PREFERRED_ENDDATE,
    SET_CART_JOB_NAME,
    SET_CART_JOB_TEXT,
    SET_CART_PROVIDER_ACCOUNT_IDS,
    REMOVE_TASK_FROM_CART,
    RESET_CART,
    LOAD_CART,
} from './actionTypes';
import { store } from '../configureStore'
import moment from 'moment'
import { createJob, getJobs, uploadJobImage } from './jobs'
import { startAuction, getAuctions } from './auctions'
import { uiStartLoading, uiStopLoading, uiStartSaving, uiStopSaving } from './ui'
import { Socket } from '../../services/socket.service'
import { Analytics } from '../../services/analytics.service'
//import showNoConnection from '../../screens/MainApp/showNoConnection'
//import analytics from '@react-native-firebase/analytics'
//import AsyncStorage from '@react-native-community/async-storage'

export const getShopData = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            dispatch(uiStartLoading())
            Socket.getShopData()
            .then(response => {
                dispatch({
                    type: SET_TASKS,
                    tasks: response.getTaskList.tasks,
                })
                dispatch({
                    type: SET_TASK_ITEMS,
                    task_items: response.getTaskItemList.task_items,
                })
                dispatch({
                    type: SET_TASK_ITEM_IMAGES,
                    task_item_images: response.getTaskItemImageList.task_item_images,
                })
                dispatch({
                    type: SET_TASK_ITEM_QUESTIONS,
                    task_item_questions: response.getTaskItemQuestionList.task_item_questions,
                })
                dispatch({
                    type: SET_TASK_ITEM_PREREQUISITES,
                    task_item_prerequisites: response.getTaskItemPrerequisiteList.task_item_prerequisites,
                })
                dispatch({
                    type: SET_DRIVERS,
                    drivers: response.getDriverList.drivers,
                })
                dispatch({
                    type: SET_OPTIONS,
                    options: response.getOptionList.options,
                })
                dispatch({
                    type: SET_SERVICES,
                    services: response.getServiceList.services,
                })
                dispatch({
                    type: SET_SETTINGS,
                    settings: response.getSettingList.settings,
                })
                dispatch({
                    type: SET_COMPETENCES,
                    competences: response.getCompetenceList.competences,
                })
                dispatch({
                    type: SET_PROPERTIES,
                    properties: response.getPropertyList.properties,
                })
                dispatch({
                    type: SET_GEOLOCATIONS,
                    geolocations: response.getGeolocationList.geolocations,
                })
                dispatch({
                    type: SET_PROVIDER_ACCOUNTS,
                    provider_accounts: response.getProviderAccountList.provider_accounts,
                })

                // Save cached version
                let storage_name = 'byggbuddy:shop'
                let storage_date = 'byggbuddy:shop_date'

                localStorage.setItem(storage_name + '_tasks', JSON.stringify(response.getTaskList.tasks))
                localStorage.setItem(storage_date, moment().format('YYYY-MM-DD'))

                return Promise.resolve()
            })
            .then(() => {
                dispatch(uiStopLoading())
                resolve()
            })
            .catch(error => {
                console.warn(error)
                dispatch(uiStopLoading())
                //showNoConnection()
                reject(error)
            })
        })
    }
}

export const setShopProviderAccountId = (shop_provider_account_id) => {

    return {
        type: SET_SHOP_PROVIDER_ACCOUNT_ID,
        shop_provider_account_id: shop_provider_account_id,
    }
}

export const resetSearch = () => {

    Analytics.logEvent('reset_search')

    return {
        type: RESET_SEARCH,
    }
}

export const selectSearchTask = (task_id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            /*let state = store.getState()

            for (var i = 0; i < state.shop.tasks.length; i++) {
                let task = state.shop.tasks[i]
            
                if (task.task_id == task_id) {

                    Analytics.logViewItem({
                        item_id: 'task_' + task_id,
                        item_name: task.task_name,
                        item_category: 'task',
                    })
                }
            }*/

            dispatch ({
                type: SELECT_SEARCH_TASK,
                task_id: task_id,
            })
            resolve()
        })
    }
}

export const selectSearchTaskItem = (task_item_id) => {

    /*let state = store.getState()

    for (var i = 0; i < state.shop.task_items.length; i++) {
        let task_item = state.shop.task_items[i]
    
        if (task_item.task_item_id == task_item_id) {

            Analytics.logViewItem({
                item_id: 'task_item_' + task_item_id,
                item_name: task_item.task_item_name,
                item_category: 'task_item',
            })
        }
    }*/

    return {
        type: SELECT_SEARCH_TASK_ITEM,
        task_item_id: task_item_id,
    }
}

export const setSearchDriverValue = (driver_id, driver_value) => {

    Analytics.logEvent('set_driver_value')

    return {
        type: SET_SEARCH_DRIVER_VALUE,
        driver_id: driver_id,
        driver_value: driver_value,
    }
}

export const setSearchTaskText = (task_text) => {

    Analytics.logEvent('set_task_text')

    return {
        type: SET_SEARCH_TASK_TEXT,
        task_text: task_text,
    }
}

// cart_task_index is set if task already in cart should be updated
export const addImageToTask = (task_item_image_id, image_uri, cart_task_index = -1) => {

    Analytics.logEvent('add_image_to_task')

    return {
        type: ADD_IMAGE_TO_TASK,
        task_item_image_id: task_item_image_id,
        image_uri: image_uri,
        cart_task_index,
    }
}

export const removeImageFromTask = (index, cart_task_index = -1) => {

    Analytics.logEvent('remove_image_to_task')

    return {
        type: REMOVE_IMAGE_FROM_TASK,
        index: index,
        cart_task_index,
    }
}

export const doneImagesToTask = (task_item_image_id, cart_task_index = -1) => {
    return {
        type: DONE_IMAGES_TO_TASK,
        task_item_image_id: task_item_image_id,
        cart_task_index,
    }
}

export const setSearchTaskAnswer = (task_item_question_id, answer_text) => {

    Analytics.logEvent('set_task_answer')

    return {
        type: SET_SEARCH_TASK_ANSWER,
        task_item_question_id: task_item_question_id,
        answer_text: answer_text,
    }
}

export const initiateCheckout = (initiate, cart_task_index = -1) => {

    Analytics.logEvent('initiate_checkout')

    return {
        type: INITIATE_CHECKOUT,
        initiate: initiate,
        cart_task_index,
    }
}

export const confirmPrerequisites = (confirm, cart_task_index = -1) => {

    Analytics.logEvent('confirm_prerequisites')

    return {
        type: CONFIRM_PREREQUISITES,
        confirm: confirm,
        cart_task_index,
    }
}

export const loadCart = () => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {

            let state = store.getState()

            let shop_provider_account_id = state.shop.shop_provider_account_id
            
            let storage_name = 'byggbuddy:cart_' + shop_provider_account_id + '_' + state.auth.scope + (state.auth.scope == 'provider' ? '_' + state.auth.user.provider_user_id : (state.auth.scope == 'customer' ? '_' + state.auth.user.customer_id : ''))
            let storage_date = 'byggbuddy:cart_' + shop_provider_account_id + '_date_' + state.auth.scope + (state.auth.scope == 'provider' ? '_' + state.auth.user.provider_user_id : (state.auth.scope == 'customer' ? '_' + state.auth.user.customer_id : ''))

            //console.warn('CART', state.shop.cart)
            let cart_encoded = await localStorage.getItem(storage_name)
            let cart_date = await localStorage.getItem(storage_date)

            let now = moment()
            let earlier = moment(cart_date)

            if (cart_encoded && moment.duration(now.diff(earlier)).asDays() < 7) {
                let cart = JSON.parse(cart_encoded)
                
                if (cart.tasks) {
                    dispatch ({
                        type: LOAD_CART,
                        cart,
                    })
                }
                else {
                    dispatch ({
                        type: RESET_CART,
                    })
                }
            }
            else {
                dispatch ({
                    type: RESET_CART,
                })
            }
            resolve()
        })
    }
}

export const loadStoredShop = () => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {

            let storage_name = 'byggbuddy:shop'
            let storage_date = 'byggbuddy:shop_date'
            
            let shop_date = await localStorage.getItem(storage_date)
            
            let now = moment()
            let earlier = moment(shop_date)

            if (moment.duration(now.diff(earlier)).asDays() < 7) {
                let tasks_encoded = await localStorage.getItem(storage_name + '_tasks')
                let tasks = JSON.parse(tasks_encoded)
                
                if (tasks && tasks.length > 0) {
                    dispatch({
                        type: SET_TASKS,
                        tasks,
                    })
                }
            }
            resolve()
        })
    }
}

export const addSearchToCart = () => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {

            let state = store.getState()
            let task_id = state.shop.search.task_id
            let total_price = state.shop.search.total_price

            for (var i = 0; i < state.shop.tasks.length; i++) {
                let task = state.shop.tasks[i]
            
                if (task.task_id == task_id) {

                    /*Analytics.logAddToCart({
                        item_id: 'task_' + task_id,
                        item_name: task.task_name,
                        item_category: 'task',
                        currency: 'SEK',
                        price: total_price,
                        quantity: 1,
                        value: total_price,
                    })*/
                    Analytics.logEvent('add_task_to_cart')
                    Analytics.logEvent('add_task_to_cart_' + task_id)
                }
            }

            dispatch ({
                type: ADD_SEARCH_TO_CART,
            })

            // Refresh to include added search
            state = store.getState()

            let shop_provider_account_id = state.shop.shop_provider_account_id

            let storage_name = 'byggbuddy:cart_' + shop_provider_account_id + '_' + state.auth.scope + (state.auth.scope == 'provider' ? '_' + state.auth.user.provider_user_id : (state.auth.scope == 'customer' ? '_' + state.auth.user.customer_id : ''))
            let storage_date = 'byggbuddy:cart_' + shop_provider_account_id + '_date_' + state.auth.scope + (state.auth.scope == 'provider' ? '_' + state.auth.user.provider_user_id : (state.auth.scope == 'customer' ? '_' + state.auth.user.customer_id : ''))
            
            //console.warn('CART', state.shop.cart)
            await localStorage.setItem(storage_name, JSON.stringify(state.shop.cart))
            await localStorage.setItem(storage_date, moment().format('YYYY-MM-DD'))
            resolve()
        })
    }
}

export const resetCartJobOptions = () => {
    return {
        type: RESET_CART_JOB_OPTIONS,
    }
}

export const selectCartJobOption = (option_id) => {
    return {
        type: SELECT_CART_JOB_OPTION,
        option_id: option_id,
    }
}

export const setCartAddress = (address) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
    
            Analytics.logEvent('set_address')

            dispatch ({
                type: SET_CART_ADDRESS,
                address: address,
            })
            resolve()
        })
    }
}

export const setCartSpeed = (speed) => {
    return {
        type: SET_CART_SPEED,
        speed: speed,
    }
}

export const setCartPreferredStartDate = (preferred_startdate) => {
    return {
        type: SET_CART_PREFERRED_STARTDATE,
        preferred_startdate: preferred_startdate,
    }
}

export const setCartPreferredEndDate = (preferred_enddate) => {
    return {
        type: SET_CART_PREFERRED_ENDDATE,
        preferred_enddate: preferred_enddate,
    }
}

export const setCartJobName = (job_name) => {
    return {
        type: SET_CART_JOB_NAME,
        job_name: job_name,
    }
}

export const setCartJobText = (job_text) => {

    Analytics.logEvent('set_job_text')

    return {
        type: SET_CART_JOB_TEXT,
        job_text: job_text,
    }
}

export const setCartProviderAccountIds = (provider_account_ids) => {

    return {
        type: SET_CART_PROVIDER_ACCOUNT_IDS,
        provider_account_ids: provider_account_ids,
    }
}

export const setCartMaxPricePerHour = (price_per_hour) => {
    return {
        type: SET_CART_MAX_PRICE_PER_HOUR,
        price_per_hour: price_per_hour,
    }
}

export const removeTaskFromCart = (task_index) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
    
            let state = store.getState()
            let tasks = state.shop.cart.tasks
            let task_id = tasks[task_index].task_id
            let total_price = tasks[task_index].total_price
            
            for (var i = 0; i < state.shop.tasks.length; i++) {
                let task = state.shop.tasks[i]
            
                if (task.task_id == task_id) {

                    /*Analytics.logRemoveFromCart({
                        item_id: 'task_' + task_id,
                        item_name: task.task_name,
                        item_category: 'task',
                        currency: 'SEK',
                        price: total_price,
                        quantity: parseInt(1),
                        value: total_price,
                    })*/
                    Analytics.logEvent('remove_task_from_cart')
                    Analytics.logEvent('remove_task_from_cart_' + task_id)
                }
            }

            dispatch ({
                type: REMOVE_TASK_FROM_CART,
                task_index: task_index,
            })

            state = store.getState()

            let shop_provider_account_id = state.shop.shop_provider_account_id

            let storage_name = 'byggbuddy:cart_' + shop_provider_account_id + '_' + state.auth.scope + (state.auth.scope == 'provider' ? '_' + state.auth.user.provider_user_id : (state.auth.scope == 'customer' ? '_' + state.auth.user.customer_id : ''))
            let storage_date = 'byggbuddy:cart_' + shop_provider_account_id + '_date_' + state.auth.scope + (state.auth.scope == 'provider' ? '_' + state.auth.user.provider_user_id : (state.auth.scope == 'customer' ? '_' + state.auth.user.customer_id : ''))
            
            console.log(storage_name, storage_date, state.shop.cart)

            //console.warn('CART', state.shop.cart)
            await localStorage.setItem(storage_name, JSON.stringify(state.shop.cart))
            await localStorage.setItem(storage_date, moment().format('YYYY-MM-DD'))
            resolve()
        })
    }
}

export const resetCart = () => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {

            Analytics.logEvent('reset_cart')

            dispatch ({
                type: RESET_CART,
            })

            let state = store.getState()

            let shop_provider_account_id = state.shop.shop_provider_account_id

            let storage_name = 'byggbuddy:cart_' + shop_provider_account_id + '_' + state.auth.scope + (state.auth.scope == 'provider' ? '_' + state.auth.user.provider_user_id : (state.auth.scope == 'customer' ? '_' + state.auth.user.customer_id : ''))
            let storage_date = 'byggbuddy:cart_' + shop_provider_account_id + '_date_' + state.auth.scope + (state.auth.scope == 'provider' ? '_' + state.auth.user.provider_user_id : (state.auth.scope == 'customer' ? '_' + state.auth.user.customer_id : ''))
            
            //console.log(storage_name, storage_date, state.shop.cart)

            //console.warn('CART', state.shop.cart)
            await localStorage.setItem(storage_name, JSON.stringify(state.shop.cart))
            await localStorage.setItem(storage_date, moment().format('YYYY-MM-DD'))
            resolve()
        })
    }
}

const createUploadImageFunction = (job, task_image, i, dispatch) => {

    let job_image_call = () => {
        let job_image = {
            job_id:                 job.job_id,
            job_task_id:            job.job_tasks[i].job_task_id,
            task_item_image_id:     task_image.task_item_image_id,
            job_image_uri:          task_image.image_uri,
        }

        return new Promise((res, rej) => {
            dispatch(uploadJobImage(job_image))
            .then(() => {
                res()
            })
            .catch(error => {
                console.warn(error)
                rej()
            })
        })
    }
    return job_image_call
}

const allSequential = (calls) => {
    return new Promise((resolve, reject) => {

        if (calls.length == 0) {
            resolve()
        }
        else {

            let call = calls.shift()

            call()
            .then(() => {
                return allSequential(calls)
            })
            .then(() => {
                resolve()
            })
            .catch(error => {
                console.warn(error)
                reject(error)
            })
        }
    })
}

export const checkoutCart = (customer = null) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(uiStartSaving())

            let state = store.getState()
            let cart = state.shop.cart
            let speeds = state.shop.speeds
            let customer_id = (state.auth.scope == 'customer' ? state.auth.user.customer_id : 0)

            let job = {
                job_id: 0,
                job_name: cart.job_name,
                job_text: cart.job_text,
                job_status: 'auction',
                customer_id: customer_id,
                geolocation_id: 0,
                geolocation: cart.address,
                job_options: cart.options,
                job_tasks: cart.tasks,
                preferred_startdate: cart.preferred_startdate,
                preferred_enddate: cart.preferred_enddate,
            }

            if (customer_id == 0 && customer) {
                job.customer = customer
            }

            for (var i = 0; i < job.job_tasks.length; i++) {
                job.job_tasks[i].job_task_items = job.job_tasks[i].task_items
                job.job_tasks[i].job_task_text = job.job_tasks[i].task_text
            }

            //let raise_price_per_hour = 25

            //let auction_steps = Math.round((cart.max_price_per_hour - cart.min_price_per_hour) / raise_price_per_hour)
            let auction_start = moment()
            let auction_end = moment().add(speeds[cart.speed], 'days')

            if (auction_end.day() == 0) {
                // Sunday
                auction_end.add(1, 'days')
            }
            else if (auction_end.day() == 6) {
                // Saturday
                auction_end.add(2, 'days')
            }

            let auction_evaluation_end = moment(auction_end).add(speeds[cart.speed], 'days')

            if (auction_evaluation_end.day() == 0) {
                // Sunday
                auction_evaluation_end.add(1, 'days')
            }
            else if (auction_evaluation_end.day() == 6) {
                // Saturday
                auction_evaluation_end.add(2, 'days')
            }

            //console.warn(auction_end, auction_evaluation_end)

            //let auction_duration = moment.duration(auction_end.diff(auction_start)).asSeconds()
            //let auction_step_duration = auction_duration / auction_steps
            //let auction_raise = moment(auction_start).add(auction_step_duration, 'seconds')

            //let auction_start_price = Math.round((cart.min_price_per_hour   * cart.total_hours + cart.total_material)/1000)*1000
            //let auction_raise_price = Math.round((raise_price_per_hour      * cart.total_hours)/1000)*1000
            //let auction_end_price   = Math.round((cart.max_price_per_hour   * cart.total_hours + cart.total_material)/1000)*1000

            let auction_reference_price = Math.ceil((cart.price_per_hour   * cart.total_hours + cart.total_material)/1000)*1000
            let auction_reference_price_deducted = Math.ceil((cart.price_per_hour * cart.total_hours * 0.7 + cart.total_material)/1000)*1000

            let auction_total_material = Math.ceil((cart.total_material)/1000)*1000

            let auction = {
                auction_id: 0,
                auction_starttime:                  auction_start.format("YYYY-MM-DD HH:mm:ss"),
                auction_raisetime:                  '2099-12-31 23:59:59',
                auction_endtime:                    auction_end.format("YYYY-MM-DD") + ' 18:00:00',
                auction_evaluation_endtime:         auction_evaluation_end.format("YYYY-MM-DD") + ' 18:00:00',
                auction_status:                     'ongoing',
                auction_step_duration:              0,
                auction_reference_price:            auction_reference_price,
                auction_reference_price_deducted:   auction_reference_price_deducted,
                auction_start_price:                auction_reference_price,
                auction_current_price:              auction_reference_price,
                auction_raise_price:                0,
                auction_end_price:                  auction_reference_price,
                auction_total_hours:                cart.total_hours,
                auction_total_material:             auction_total_material,
                provider_account_ids:               cart.provider_account_ids, 
            }

            dispatch(createJob(job))
            .then(job => {
                auction.job_id = job.job_id

                let job_image_calls = []

                for (var i = 0; i < cart.tasks.length; i++) {
                    let task = cart.tasks[i]

                    for (var j = 0; j < task.task_images.length; j++) {
                        let task_image = task.task_images[j]
                        let job_image_call = createUploadImageFunction(job, task_image, i, dispatch)
                        job_image_calls.push(job_image_call)
                    }
                }
                return allSequential(job_image_calls)
                //return Promise.all(job_image_promises)
            })
            .then(() => {
                return dispatch(startAuction(auction))    
            })
            .then(auction => {
                if (auction.auction_id > 0) {
                    dispatch(resetCart())
                    //dispatch(getAuctions())
                    //dispatch(getJobs())
                }
                dispatch(uiStopSaving())
                resolve()
            })
            .catch(error => {
                console.warn(error)
                dispatch(uiStopSaving())
                reject(error)
            })
        })
    }
}

export const submitTaskMissingText = (task_missing_text) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            
            let state = store.getState()
            //let cart = state.shop.cart
            let customer_id = (state.auth.scope == 'customer' ? state.auth.user.customer_id : 0)

            let task_missing_text_data = {
                task_missing_text_id: 0,
                customer_id: customer_id,
                task_missing_text,
            }

            Socket.submitTaskMissingText(task_missing_text_data)
        })
    }
}

export const getGeolocations = (search_text, types) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            
            Socket.getGeolocations(search_text, types)
            .then(geolocations => {
                resolve(geolocations)
            })
            .catch(error => {
                console.warn(error)
                reject(error)
            })
        })
    }
}





