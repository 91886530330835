import { 
    UI_SET_SCREEN,
    UI_SET_MENU_OPEN,
    UI_SET_BRANDING,
    UI_SET_EMBEDDED,
    UI_START_LOADING, 
    UI_STOP_LOADING,
    UI_START_SAVING, 
    UI_STOP_SAVING,
    UI_START_NETWORK_OFFLINE,
    UI_STOP_NETWORK_OFFLINE, 
} from '../actions/actionTypes'
import { setShopProviderAccountId } from '../../store/actions/shop'

export const goToScreen = (screen) => {
    return {
        type: UI_SET_SCREEN,
        screen: screen,
    };
};

export const setMenuOpen = (menu_open) => {
    return {
        type: UI_SET_MENU_OPEN,
        menu_open: menu_open,
    };
};

export const setBranding = (provider) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {

            let brand_provider_account = null
            let shop_provider_account_id = 0
            let is_custom_domain = false

            if (provider && provider != 'byggbuddy') {
                let state = getState()
                let provider_accounts = state.shop.provider_accounts

                for (var i = 0; i < provider_accounts.length; i++) {
                    let provider_account = provider_accounts[i]

                    if (provider_account.provider_account_id == provider || provider_account.provider_identifier == provider || provider_account.provider_domain == provider) {
                        brand_provider_account = provider_account
                        shop_provider_account_id = provider_account.provider_account_id
                        is_custom_domain = (provider_account.provider_domain == provider)
                    }
                }
            }

            dispatch ({
                type: UI_SET_BRANDING,
                brand_provider_account,
                is_custom_domain,
            })
            dispatch(setShopProviderAccountId(shop_provider_account_id))
            resolve()
        })
    }
};

export const setEmbedded = (is_embedded) => {
    return {
        type: UI_SET_MENU_OPEN,
        is_embedded: is_embedded,
    };
};

export const uiStartLoading = () => {
    return {
        type: UI_START_LOADING
    };
};

export const uiStopLoading = () => {
    return {
        type: UI_STOP_LOADING
    };
};

export const uiStartSaving = () => {
    return {
        type: UI_START_SAVING
    };
};

export const uiStopSaving = () => {
    return {
        type: UI_STOP_SAVING
    };
};

export const uiStartNetworkOffline = () => {
    return {
        type: UI_START_NETWORK_OFFLINE
    };
};

export const uiStopNetworkOffline = () => {
    return {
        type: UI_STOP_NETWORK_OFFLINE
    };
};