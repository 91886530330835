import React, { Component } from "react";
import { connect } from "react-redux";
import utility from "../../../utility/utility";
import ByggbuddyScreen from "../../ByggbuddyScreen";
import DefaultInput from "../../../components/UI/DefaultInput/DefaultInput";
import DefaultButton from "../../../components/UI/DefaultButton/DefaultButton";
import { pushDebugMessage } from "../../../store/actions/debug";
import { selectSearchTask, resetSearch } from "../../../store/actions/shop";

import _ from "lodash";
import { Analytics } from "../../../services/analytics.service";

import Cart from "./../../../components/Shop/Cart";

class ShopInfoScreen extends ByggbuddyScreen {
  // Component settings
  show_cart = true;

  findTask = () => {
    this.props.history.push(this.getRoot() + "/shop/find_task/0");
  };

  selectProvider = () => {
    this.props.history.push(this.getRoot() + "/shop/select_provider");
  };

  renderBrandedContent(provider_account) {
    return (
      <>
        <div style={{ marginTop: 20, textAlign: "center" }}>
          <h1
            style={{
              fontSize: 40,
              fontWeight: "normal",
              color: "#000000",
              textAlign: "center",
              fontFamily: "MuseoSans-300",
            }}
          >
            {provider_account.provider_texts.ID_SHOP_INFO_HEADING ? (
              provider_account.provider_texts.ID_SHOP_INFO_HEADING
            ) : (
              <span>
                Välkommen att skicka in din förfrågan till oss på{" "}
                <strong>{provider_account.provider_name}</strong>
              </span>
            )}
          </h1>
        </div>

        <div style={{ margin: "20px auto", width: "100%", maxWidth: "600px" }}>
          {provider_account.provider_texts.ID_SHOP_INFO_MAIN ? (
            <p style={{ whiteSpace: "pre-line" }}>
              {provider_account.provider_texts.ID_SHOP_INFO_MAIN}
            </p>
          ) : (
            <div>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "normal",
                  color: "#404040",
                  textAlign: "center",
                  fontFamily: "MuseoSans-300",
                }}
              >
                Här beskriver du vad du vill ha hjälp med genom att svara på
                några enkla frågor.
                <strong>
                  {" "}
                  Det är helt gratis att skicka in en förfrågan och du förbinder
                  dig inte till någonting.
                </strong>
              </p>

              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "normal",
                  color: "#404040",
                  textAlign: "center",
                  fontFamily: "MuseoSans-300",
                }}
              >
                Normalt återkommer vi med en offert eller med uppföljningsfrågor
                inom några dagar.
              </p>
            </div>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "0 20px",
          }}
        >
          <DefaultButton
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "50px",
              backgroundColor: "#92bc2a",
              margin: "40px 0",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
            onClick={() => this.findTask()}
          >
            <strong>Kom igång</strong>
          </DefaultButton>
        </div>

        <div style={{ margin: "50px auto", width: "100%", maxWidth: "600px" }}>
          <h2>Om {provider_account.provider_name}</h2>
          {provider_account.provider_texts.ID_SHOP_INFO_PROVIDER ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "normal",
                color: "#404040",
                textAlign: "left",
                fontFamily: "MuseoSans-300",
                whiteSpace: "pre-line",
              }}
            >
              {provider_account.provider_texts.ID_SHOP_INFO_PROVIDER}
            </p>
          ) : (
            <div>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  color: "#404040",
                  textAlign: "left",
                  fontFamily: "MuseoSans-300",
                  whiteSpace: "pre-line",
                }}
              >
                {provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180
                  ? provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180
                  : provider_account.provider_description}
              </p>
            </div>
          )}
        </div>

        {/* <div style={{margin: '0px auto 50px', width: '100%', maxWidth: '600px', }}>
          <h3 style={{marginBottom: 0}}>Integritetspolicy</h3>
          <p style={{fontSize: '16px', fontWeight: 'normal', color: '#404040', textAlign: 'left', fontFamily: 'MuseoSans-300', whiteSpace: 'pre-line'}}>
            <a href="https://www.byggbuddy.se/integritetspolicy/">Läs vår Integritetspolicy här</a>
          </p>
        </div>             */}
      </>
    );
  }

  renderByggbuddyContent() {
    return (
      <>
        <div style={{ marginTop: 20, textAlign: "center" }}>
          <h1
            style={{
              fontSize: 40,
              fontWeight: "normal",
              color: "#000000",
              textAlign: "center",
              fontFamily: "MuseoSans-300",
            }}
          >
            Välkommen till Byggbuddy!
          </h1>
        </div>

        <div style={{ margin: "20px auto", width: "100%", maxWidth: "600px" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "normal",
              color: "#404040",
              textAlign: "center",
              fontFamily: "MuseoSans-300",
            }}
          >
            Vi hjälper dig att hitta rätt hantverkare. Det är helt gratis att
            skicka in en förfrågan och du förbinder dig inte till någonting.
          </p>
        </div>

        <div style={{ width: "100%", maxWidth: "600px" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#404040",
              textAlign: "center",
              fontFamily: "MuseoSans-300",
            }}
          >
            Vad vill du göra?
          </h2>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "0 20px",
          }}
        >
          <DefaultButton
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "50px",
              backgroundColor: "#167f40",
              margin: "5px 0",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
            onClick={() => this.selectProvider()}
          >
            Välj hantverkare först
          </DefaultButton>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              color: "#808080",
              textAlign: "center",
              fontFamily: "MuseoSans-300",
              margin: "0",
            }}
          >
            Om du vet vem du vill anlita
          </p>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "15px 0",
          }}
        >
          <div
            style={{
              width: "25%",
              height: "1px",
              borderTop: "1px solid black",
            }}
          ></div>
          <div style={{ fontSize: "14px", margin: "0 10px" }}>ELLER</div>
          <div
            style={{
              width: "25%",
              height: "1px",
              borderTop: "1px solid black",
            }}
          ></div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "0 20px",
          }}
        >
          <DefaultButton
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "50px",
              color: "#167f40",
              border: "1px solid #167f40",
              backgroundColor: "white",
              margin: "5px 0",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
            onClick={() => this.findTask()}
          >
            Beskriv uppdraget först
          </DefaultButton>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              color: "#808080",
              textAlign: "center",
              fontFamily: "MuseoSans-300",
              margin: "0",
            }}
          >
            Om du inte vet vem du vill anlita
          </p>
        </div>

        {/* <div style={{margin: '50px auto 50px', width: '100%', maxWidth: '600px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h3 style={{marginBottom: 0}}>Integritetspolicy</h3>
          <p style={{fontSize: '16px', fontWeight: 'normal', color: '#404040', textAlign: 'left', fontFamily: 'MuseoSans-300', whiteSpace: 'pre-line'}}>
            <a href="https://www.byggbuddy.se/integritetspolicy/">Läs vår Integritetspolicy här</a>
          </p>
        </div>       */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  shop: state.shop,
  scope: state.auth.scope,
  ui: state.ui,
  developer_mode: state.auth.developer_mode,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopInfoScreen);
