import React from 'react';
import { connect } from "react-redux";

import { tryAuth } from '../../../store/actions/auth'
import { goToScreen } from '../../../store/actions/ui'

import { Switch, Route, Redirect, Link } from 'react-router-dom';

import ShopInfo from './ShopInfo'
import FindTask from './FindTask'
import AddTaskToCart from './AddTaskToCart'
import TaskAddedInfo from './TaskAddedInfo'
import StartAuction from './StartAuction'
import AuctionStartedInfo from './AuctionStartedInfo'

import SelectProvider from './SelectProvider'

import PersistentRoute from './../../../components/Router/PersistentRoute'
import Cart from './../../../components/Shop/Cart'


class Shop extends React.Component {

  componentDidMount() {
  }

  getTaskId() {
    let path = this.props.location.pathname

    let lastSlash = path.lastIndexOf('/')
    let id = path.substring(lastSlash + 1)
    return id
  }

  render() {

    let root = this.props.ui.is_custom_domain ? '' : '/:provider'

    let task_id = this.getTaskId()

    return (
      <div className="byggbuddy-shop">

        <Route path={root+'/shop/'} exact                     component={ShopInfo} />
        <Route path={root+'/shop/find_task/:task_id'}         render={(props) => (<FindTask {...props} key={task_id} />)} />
        
        <Route path={root+'/shop/add_task_to_cart/:task_id'}  render={(props) => (<AddTaskToCart {...props} key={task_id} />)} />
        <Route path={root+'/shop/task_added'}                 component={TaskAddedInfo} />
        
        <Route path={root+'/shop/start_auction'}              component={StartAuction}/>
        <Route path={root+'/shop/auction_started'}            component={AuctionStartedInfo} />

        <Route path={root+'/shop/select_provider'}            component={SelectProvider}/>

        <Route path={root+'/shop/cart'}                       render={(props) => (<Cart {...props} fullscreen={true} />)} />    
      
      </div>
    )
  }
}

function mapStateToProps(state) {
   return {
     ui: state.ui,
   };
 
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
