import React, { Component } from "react";
import { connect } from "react-redux";
import utility from "../../utility/utility";
import DefaultInput from "../../components/UI/DefaultInput/DefaultInput";
import DefaultButton from "../../components/UI/DefaultButton/DefaultButton";
import { pushDebugMessage } from "../../store/actions/debug";
import {
  resetCart,
  resetCartJobOptions,
  removeTaskFromCart,
} from "../../store/actions/shop";
import { Analytics } from "../../services/analytics.service";
//import Icon from 'react-native-vector-icons/dist/MaterialIcons';

class Cart extends React.Component {
  getRoot = () => {
    if (this.props.ui.is_branded) {
      if (this.props.ui.is_custom_domain) {
        return "";
      } else {
        if (this.props.ui.brand_provider_account.provider_identifier) {
          return "/" + this.props.ui.brand_provider_account.provider_identifier;
        } else {
          return "/" + this.props.ui.brand_provider_account.provider_account_id;
        }
      }
    } else {
      return "/byggbuddy";
    }
  };

  findTask = () => {
    this.props.history.push(this.getRoot() + "/shop/find_task/0");
  };

  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {}

  onViewTask(task_index) {
    //
  }

  onPromptRemoveTask(task_index) {
    //if (confirm('Vill du ta bort tjänsten från varukorgen?')) {
    this.props.onRemoveTaskFromCart(task_index);
    //}
  }

  onCheckout() {
    this.props.onResetCartJobOptions();

    // Firebase analytics
    Analytics.logBeginCheckout();

    this.props.history.push(this.getRoot() + "/shop/start_auction/");

    /*if (this.props.scope == 'customer') {
      if (!this.props.user.user_terms_current) {
        Alert.alert(
          'Användarvillkor ej godkända',
          'Du kan inte checka ut förrän du har godkänt användarvillkoren. Du gör detta under fliken Mitt Byggbuddy',
          [
            {text: 'OK'},
          ]
        )
      }
      else {
        Navigation.push(this.props.componentId, {
          component: {
            name: 'byggbuddy.StartAuctionScreen',
            id: 'StartAuctionScreenRoot',
          }
        })
      }
    }
    else {

      Navigation.push(this.props.componentId, {
        component: {
          name: 'byggbuddy.CustomerSignupScreen',
          passProps: {
            checkout_flow: true
          },
        }
      })
    }*/
  }

  onPromptResetCart() {
    this.props.onResetCart();

    /*Alert.alert(
      'Töm varukorg',
      'Vill du tömma varukorgen?',
      [
        {text: 'Töm varukorg', onClick: () => {
          this.props.onResetCart()
        }},
        {text: 'Avbryt'},
      ]
    )*/
  }

  minCompetencesRecursive(competence_groups, selected) {
    if (competence_groups.length == 0) {
      return selected.length;
    } else {
      let competence_group = competence_groups.splice(0, 1)[0];
      let min_competences = 999;

      for (var i = 0; i < competence_group.length; i++) {
        let competence_id = competence_group[i];

        if (selected.includes(competence_id)) {
          let recursive_competences = this.minCompetencesRecursive(
            competence_groups,
            selected,
          );
          min_competences = Math.min(min_competences, recursive_competences);
        } else {
          let new_selected = [...selected, competence_id];
          let recursive_competences = this.minCompetencesRecursive(
            competence_groups,
            new_selected,
          );
          min_competences = Math.min(min_competences, recursive_competences);
        }
      }
      return min_competences;
    }
  }

  render() {
    // Construct auction from cart
    let auction = {
      job_tasks: [],
      auction_reference_price: this.props.cart.total_price,
      auction_reference_price_deducted: this.props.cart.total_price_deducted,
    };

    for (var i = 0; i < this.props.cart.tasks.length; i++) {
      let cart_task = this.props.cart.tasks[i];
      let task = null;

      // Task
      for (var j = 0; j < this.props.shop.tasks.length; j++) {
        let t = this.props.shop.tasks[j];
        if (t.task_id == cart_task.task_id) {
          task = t;
        }
      }

      // Drivers
      let job_task_drivers = [];
      for (var j = 0; j < cart_task.driver_values.length; j++) {
        let cart_driver_value = cart_task.driver_values[j];
        let driver = null;

        for (var k = 0; k < this.props.shop.drivers.length; k++) {
          let d = this.props.shop.drivers[k];
          if (d.driver_id == cart_driver_value.driver_id) {
            driver = d;
          }
        }

        let job_task_driver = {
          ...driver,
          driver_value: cart_driver_value.driver_value,
        };
        job_task_drivers.push(job_task_driver);
      }

      // Task items
      let job_task_items = [];
      for (var j = 0; j < cart_task.task_items.length; j++) {
        let cart_task_item = cart_task.task_items[j];
        let task_item = null;

        for (var k = 0; k < this.props.shop.task_items.length; k++) {
          let ti = this.props.shop.task_items[k];
          if (ti.task_item_id == cart_task_item.task_item_id) {
            task_item = ti;
          }
        }

        let job_task_item = {
          ...task_item,
        };
        job_task_items.push(job_task_item);
      }

      // Task answers
      let job_task_answers = [];
      for (var j = 0; j < cart_task.task_answers.length; j++) {
        let cart_task_answers = cart_task.task_answers[j];
        let task_item_question = null;

        for (var k = 0; k < this.props.shop.task_item_questions.length; k++) {
          let tq = this.props.shop.task_item_questions[k];
          if (
            tq.task_item_question_id == cart_task_answers.task_item_question_id
          ) {
            task_item_question = tq;
          }
        }

        let job_task_answer = {
          ...task_item_question,
          job_task_answer_text: cart_task_answers.job_task_answer_text,
        };
        job_task_answers.push(job_task_answer);
      }

      // Compiling the task item object
      let job_task = {
        ...task,
        job_task_text: cart_task.task_text,
        job_task_total_price: cart_task.total_price,
        job_task_total_price_deducted: cart_task.total_price_deducted,
        job_task_drivers,
        job_task_items,
        job_task_answers,
        always_in_cart: cart_task.always_in_cart == true,
      };
      //console.warn(job_task.task_name)
      auction.job_tasks.push(job_task);
    }

    let any_without_price = false;
    for (var i = 0; i < auction.job_tasks.length; i++) {
      if (auction.job_tasks[i].job_task_total_price_deducted == 0) {
        any_without_price = true;
      }
    }

    // Check if tasks in cart reqiure too many competences
    let competence_groups = [];
    for (var i = 0; i < this.props.cart.tasks.length; i++) {
      let job_task = this.props.cart.tasks[i];
      let competence_group = [];

      for (var j = 0; j < this.props.tasks.length; j++) {
        let task = this.props.tasks[j];

        if (task.task_id == job_task.task_id) {
          let task_competences = task.task_competences;

          for (var k = 0; k < task_competences.length; k++) {
            let task_competence = task_competences[k];

            if (
              !competence_group.includes(task_competence.competence_id) &&
              task_competence.competence_id != 16
            ) {
              // competence_id 16 is "can do anything"
              competence_group.push(task_competence.competence_id);
            }
          }
        }
      }

      competence_groups.push(competence_group);
    }
    let min_competences = this.minCompetencesRecursive(competence_groups, []);
    let show_pricing =
      !this.props.ui.is_branded ||
      this.props.ui.brand_provider_account.provider_show_price_estimate;

    return (
      <div
        className="byggbuddy-cart byggbuddy-content-height"
        style={{
          WebkitBoxShadow: "-10px 0px 15px -5px #808080",
          boxShadow: "-10px 0px 15px -5px #808080",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div
          className="byggbuddy-cart-upper"
          style={{ display: "flex", flexDirection: "column", flexShrink: 1 }}
        >
          {this.props.fullscreen ? (
            <div style={{ padding: "15px" }}>
              <a
                style={{
                  textDecoration: "none",
                  color: "#404040",
                  display: "flex",
                  alignItems: "center",
                }}
                href="javascript://"
                onClick={this.goBack}
              >
                <span className="material-icons">arrow_back_ios</span> Tillbaka
              </a>
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "13px 20px",
              borderBottom: "1px solid #a0a0a0",
            }}
          >
            <span className="material-icons">feed</span>
            <div
              style={{
                fontSize: 20,
                fontWeight: "normal",
                textAlign: "center",
                marginLeft: "10px",
              }}
            >
              Dina Offertförfrågningar
            </div>
          </div>

          <div
            className="byggbuddy-cart-tasks byggbuddy-content-scroll"
            style={{ maxWidth: "600px", margin: "0 auto" }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                padding: 20,
              }}
            >
              {auction.job_tasks.length == 0 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxSizing: "border-box",
                    padding: "0 20px",
                  }}
                >
                  <div
                    style={{
                      color: "#808080",
                      textAlign: "center",
                      margin: "20px 0",
                      width: "40%",
                      minWidth: "240px",
                      height: "40px",
                    }}
                  >
                    Inga förfrågningar.{" "}
                  </div>
                  {/* <div */}
                  {/*   style={{ */}
                  {/*     width: "100%", */}
                  {/*     display: "flex", */}
                  {/*     flexDirection: "column", */}
                  {/*     alignItems: "center", */}
                  {/*     boxSizing: "border-box", */}
                  {/*     padding: "0 20px", */}
                  {/*   }} */}
                  {/* > */}
                  {/*   <DefaultButton */}
                  {/*     style={{ */}
                  {/*       width: "40%", */}
                  {/*       minWidth: "240px", */}
                  {/*       height: "40px", */}
                  {/*       backgroundColor: "#92bc2a", */}
                  {/*       margin: "20px 0", */}
                  {/*       fontSize: "14px", */}
                  {/*     }} */}
                  {/*     onClick={() => this.findTask()} */}
                  {/*   > */}
                  {/*     Beskriv ditt uppdrag */}
                  {/*   </DefaultButton> */}
                  {/* </div> */}
                </div>
              ) : null}
              {auction.job_tasks.map((job_task, index) => (
                <div
                  key={index}
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    marginBottom: "30px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      backgroundColor: "#026ca0",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={job_task.image_url}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>

                  <div style={{ width: "80%", paddingLeft: 15 }}>
                    <div
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        display: "flex",
                      }}
                    >
                      <div style={{ width: "70%" }}>
                        <div style={{ marginBottom: 7 }}>
                          <div style={{ fontSize: 16, fontWeight: "600" }}>
                            {job_task.task_name}
                          </div>
                        </div>
                        {job_task.job_task_drivers.map((driver) => (
                          <div
                            style={{
                              flexDirection: "column",
                              marginBottom: 7,
                            }}
                            key={driver.driver_id}
                          >
                            <div style={{}}>
                              <div
                                style={{
                                  fontWeight: "600",
                                  fontSize: 12,
                                  color: "#808080",
                                }}
                              >
                                {driver.driver_name}
                              </div>
                            </div>
                            <div style={{}}>
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: 12,
                                  color: "#808080",
                                }}
                              >
                                {driver.driver_type === "area"
                                  ? driver.driver_value * 1 + " m2"
                                  : null}
                                {driver.driver_type === "length"
                                  ? driver.driver_value * 1 + " m"
                                  : null}
                                {driver.driver_type === "number"
                                  ? driver.driver_value * 1 + " st"
                                  : null}
                                {driver.driver_type === "binary"
                                  ? driver.driver_value
                                    ? "Ja"
                                    : "Nej"
                                  : null}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div
                        style={{
                          width: "30%",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          display: "flex",
                        }}
                      >
                        {!job_task.always_in_cart ? (
                          <div
                            onClick={() => this.onPromptRemoveTask(index)}
                            style={{
                              width: 32,
                              height: 32,
                              borderRadius: 16,
                              borderWidth: 1,
                              borderColor: "#808080",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={require("../../assets/icons/icon-delete.png")}
                              style={{ height: 20, width: 20 }}
                            />
                          </div>
                        ) : null}
                        {show_pricing ? (
                          <div style={{ width: "100%", marginTop: 10 }}>
                            {job_task.job_task_total_price_deducted > 0 ? (
                              <div
                                style={{
                                  textAlign: "right",
                                  fontSize: 12,
                                  fontWeight: "bold",
                                }}
                              >
                                {utility.niceNumberFormat(
                                  Math.ceil(
                                    job_task.job_task_total_price_deducted /
                                      1000,
                                  ) * 1000,
                                )}{" "}
                                kr
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "right",
                                  fontSize: 9,
                                  fontWeight: "bold",
                                }}
                              >
                                Kan inte prisuppskattas
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div style={{}}>
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: 12,
                          color: "#808080",
                          marginTop: 7,
                        }}
                      >
                        Uppdragets omfattning
                      </div>
                    </div>
                    {job_task.job_task_items.map((job_task_item) => (
                      <div
                        style={{ flexDirection: "row", marginTop: 5 }}
                        key={job_task_item.task_item_id}
                      >
                        <div style={{ marginRight: 3 }}>
                          {/*<Icon name="chevron-right" backgroundColor="white" color="#808080" size={12} />*/}
                        </div>
                        <div style={{}}>
                          <div
                            style={{
                              fontWeight: "normal",
                              fontSize: 12,
                              color: "#808080",
                            }}
                          >
                            {job_task_item.task_item_name}
                          </div>
                        </div>
                      </div>
                    ))}
                    {job_task.job_task_answers.map((job_task_answer) => (
                      <div
                        style={{ flexDirection: "column", marginTop: 10 }}
                        key={job_task_answer.task_item_question_id}
                      >
                        <div style={{}}>
                          <div
                            style={{
                              fontWeight: "600",
                              fontSize: 12,
                              color: "#808080",
                            }}
                          >
                            {job_task_answer.question_name}
                          </div>
                        </div>
                        <div style={{}}>
                          <div
                            style={{
                              textAlign: "left",
                              fontSize: 12,
                              color: "#808080",
                            }}
                          >
                            {job_task_answer.job_task_answer_text}
                          </div>
                        </div>
                      </div>
                    ))}
                    {job_task.job_task_text ? (
                      <div
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          marginTop: 10,
                        }}
                      >
                        <div style={{ fontSize: 12, color: "#808080" }}>
                          {job_task.job_task_text}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}

              {/* Search more tasks */}
              {auction.job_tasks.length > 0 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxSizing: "border-box",
                    padding: "0 20px",
                  }}
                >
                  <DefaultButton
                    style={{
                      width: "40%",
                      minWidth: "240px",
                      height: "40px",
                      backgroundColor: "#aaa",
                      margin: "20px 0",
                      fontSize: "14px",
                    }}
                    onClick={() => this.findTask()}
                  >
                    Lägg till fler
                  </DefaultButton>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexShrink: 0,
            overflow: "hidden",
          }}
        >
          <div>
            {show_pricing > 0 &&
            auction.job_tasks.length > 0 &&
            auction.auction_reference_price_deducted > 0 ? (
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                }}
              >
                <div
                  style={{ maxWidth: "600px", margin: "0 auto", padding: 20 }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      marginBottom: "15px",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "65%" }}>
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 14,
                          fontWeight: "normal",
                        }}
                      >
                        Prisuppskattning
                      </div>
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 10,
                          fontWeight: "normal",
                          color: "#808080",
                        }}
                      >
                        (inklusive moms före ROT-avdrag)
                      </div>
                    </div>
                    <div style={{ width: "35%" }}>
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        {utility.niceNumberFormat(
                          Math.ceil(auction.auction_reference_price / 1000) *
                            1000,
                        )}{" "}
                        kr
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      marginBottom: "15px",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "65%" }}>
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 14,
                          fontWeight: "normal",
                        }}
                      >
                        ROT-avdrag
                      </div>
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 10,
                          fontWeight: "normal",
                          color: "#808080",
                        }}
                      >
                        (förutsätter att du är berättigad)
                      </div>
                    </div>
                    <div style={{ width: "35%" }}>
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: 18,
                          fontWeight: "normal",
                          color: "red",
                        }}
                      >
                        {utility.niceNumberFormat(
                          Math.ceil(
                            auction.auction_reference_price_deducted / 1000,
                          ) *
                            1000 -
                            Math.ceil(auction.auction_reference_price / 1000) *
                              1000,
                        )}{" "}
                        kr
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      paddingTop: "15px",
                      borderTopWidth: "1px",
                      borderTopColor: "#808080",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 30,
                          fontWeight: "bold",
                        }}
                      >
                        {utility.niceNumberFormat(
                          Math.ceil(
                            auction.auction_reference_price_deducted / 1000,
                          ) * 1000,
                        )}{" "}
                        kr
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: 10,
                          fontWeight: "normal",
                          color: "#808080",
                        }}
                      >
                        Prisuppskattning inkl moms efter ROT-avdrag
                      </div>
                      {any_without_price ? (
                        <div
                          style={{
                            fontSize: 11,
                            textAlign: "center",
                            color: "red",
                            marginTop: "10px",
                          }}
                        >
                          Det finns produkter i varukorgen som inte kunde
                          prisuppskattas
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {show_pricing > 0 &&
            auction.job_tasks.length > 0 &&
            auction.auction_reference_price_deducted == 0 ? (
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: 20,
                }}
              >
                <div
                  style={{
                    fontSize: 11,
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Varukorgen kan inte prisuppskattas
                </div>
              </div>
            ) : null}
          </div>

          <div style={{ borderTop: "1px solid #a0a0a0" }}>
            {auction.job_tasks.length > 0 ? (
              <div>
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    alignItems: "center",
                    padding: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <DefaultButton
                    style={{
                      width: "80%",
                      backgroundColor: "#ffffff",
                      color: "#808080",
                      borderWidth: "1px",
                      borderColor: "#808080",
                      fontSize: "16px",
                    }}
                    onClick={() => this.onPromptResetCart()}
                  >
                    Ta bort förfrågningar
                  </DefaultButton>
                </div>
              </div>
            ) : null}

            {/* Floating action */}
            {true || auction.job_tasks.length > 0 ? (
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  boxSizing: "border-box",
                  borderColor: "#808080",
                  borderTopWidth: 2,
                  bottom: 0,
                  backgroundColor: "white",
                  padding: 10,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: -10,
                  },
                  shadowOpacity: 0.5,
                  shadowRadius: 15,
                }}
              >
                {/*show_pricing && auction.job_tasks.length > 0 ? (
                  <div style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{flexDirection: 'column', alignItems: 'center'}}>
                      {auction.auction_reference_price_deducted == 0 ? (
                        <div style={{fontSize: 11, textAlign: 'center', color: 'black', fontWeight: 'bold'}}>Varukorgen kan inte prisuppskattas</div>
                      ) : (
                        <div>
                          <div style={{fontSize: 36, fontWeight: 'bold', textAlign: 'center', color: '#167f40', display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
                            {utility.niceNumberFormat(Math.ceil(auction.auction_reference_price_deducted/1000)*1000)} <div style={{fontSize: 16, marginLeft: '5px', marginBottom: '5px'}}>kr</div>
                          </div>
                          <div style={{fontSize: 11, textAlign: 'center', color: '#808080'}}>Prisuppskattning inkl moms efter ROT-avdrag</div>
                          {any_without_price ? (
                             <div style={{fontSize: 11, textAlign: 'center', color: 'red'}}>Det finns produkter i varukorgen som inte kunde prisuppskattas</div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                ) : null*/}

                <div
                  style={{
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <DefaultButton
                      style={{
                        width: "40%",
                        minWidth: "240px",
                        backgroundColor: "#167f40",
                        fontSize: 14,
                      }}
                      onClick={() => this.onCheckout()}
                      disabled={this.props.cart.tasks == 0}
                    >
                      Gå vidare
                    </DefaultButton>
                    <div
                      style={{
                        width: "100%",
                        padding: "0 20px",
                        margin: "10px 0",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          fontStyle: "italic",
                          fontSize: 11,
                          color: "#808080",
                          textAlign: "center",
                        }}
                      >
                        <strong>
                          Du förbinder dig inte vid något när du checkar ut. Det
                          är först när du väljer att acceptera ett anbud som du
                          ingår avtal.
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.shop.cart,
  tasks: state.shop.tasks,
  shop: state.shop,
  scope: state.auth.scope,
  user: state.auth.user,
  ui: state.ui,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onResetCart: () => dispatch(resetCart()),
    onResetCartJobOptions: () => dispatch(resetCartJobOptions()),
    onRemoveTaskFromCart: (task_index) =>
      dispatch(removeTaskFromCart(task_index)),
    pushDebugMessage: (message) => dispatch(pushDebugMessage(message)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
