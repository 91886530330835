import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import _ from 'lodash'

import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { Analytics } from '../../../services/analytics.service'
import utility from '../../../utility/utility';
import { pushDebugMessage } from '../../../store/actions/debug'
import { 
  selectSearchTask, 
  resetSearch,
} from '../../../store/actions/shop'

class TaskAddedInfoScreen extends ByggbuddyScreen {

  // Component settings
  show_cart = true
  
  findTask = () => {
    this.props.history.push(this.getRoot() + '/shop/find_task/0')
  }

  checkoutCart = () => {
    this.props.history.push(this.getRoot() + '/shop/start_auction')
  }

  renderContent() {

    return (
      <>
        <div style={{marginTop: 20, padding: '0 20px'}}>
          <h1 style={{fontSize: 40, fontWeight: 'normal', color: '#000000', textAlign: 'center', fontFamily: 'MuseoSans-300'}}>
            Vald tjänst har lagts till i varukorgen!
          </h1>
        </div>

        <div style={{padding: '0 20px'}}>
          <h2 style={{fontSize: '20px', fontWeight: 'bold', color: '#404040', textAlign: 'center', fontFamily: 'MuseoSans-300'}}>
            Vad vill du göra nu?
          </h2>
        </div>

        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', boxSizing: 'border-box', padding: '0 20px'}}>
          <DefaultButton
            style={{width: '100%', maxWidth: '400px', height: '50px', backgroundColor: '#167f40', margin: '5px 0', fontSize: '20px', textTransform: 'uppercase'}}
            onClick={() => this.checkoutCart()}
          >
            Gå vidare
          </DefaultButton>
          {/*<p style={{fontSize: '14px', fontWeight: 'normal', color: '#808080', textAlign: 'center', fontFamily: 'MuseoSans-300', margin: '0'}}>
            Om du vet vem du vill anlita
          </p>*/}
        </div>

        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '15px 0'}}>
          <div style={{width: '25%', height: '1px', borderTop: '1px solid black'}}></div>
          <div style={{fontSize: '14px', margin: '0 10px'}}>ELLER</div>
          <div style={{width: '25%', height: '1px', borderTop: '1px solid black'}}></div>
        </div>              

        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', boxSizing: 'border-box', padding: '0 20px'}}>
          <DefaultButton
            style={{width: '100%', maxWidth: '400px', height: '50px', color: '#167f40', border: '1px solid #167f40', backgroundColor: 'white', margin: '5px 0', fontSize: '20px', textTransform: 'uppercase'}}
            onClick={() => this.findTask()}
          >
            Lägg till fler tjänster
          </DefaultButton>
          <p style={{fontSize: '14px', fontWeight: 'normal', color: '#808080', textAlign: 'center', fontFamily: 'MuseoSans-300', margin: '0'}}>
            Om du vill att samma hantverkare ska hjälpa dig med flera saker
          </p>
        </div>
      </>

    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  shop: state.shop,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskAddedInfoScreen)
