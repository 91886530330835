import React from 'react';
import { connect } from "react-redux";

import { Switch, Route, Redirect, Link } from 'react-router-dom';

import ProviderOverview from './ProviderOverview'
import ProviderAuctions from './Auctions/ProviderAuctions'
import ProviderMakeBid from './Auctions/ProviderMakeBid'
import ProviderViewBid from './Auctions/ProviderViewBid'
import ProviderJobs from './Jobs/ProviderJobs'

import ProviderChangePassword from './Auth/ProviderChangePassword'


class Provider extends React.Component {

  componentDidMount() {
  }

  render() {

    let provider = 'byggbuddy'

    if (this.props.custom_domain) {
      provider = this.props.custom_domain
    }
    else if (this.props.match && this.props.match.params && this.props.match.params.provider) {
      provider = this.props.match.params.provider
    }

    let root = (this.props.custom_domain || this.props.ui.is_custom_domain) ? '' : '/:provider'
    
    return (
      <div className="byggbuddy-provider">
        
        <Route path={root+'/provider/'} exact                                 render={() => <Redirect to={'/' + provider + '/provider/auctions/ongoing'} />} />
        <Route path={root+'/provider/auctions'} exact                         render={() => <Redirect to={'/' + provider + '/provider/auctions/ongoing'} />} />
        <Route path={root+'/provider/auctions/:auction_id/bids/:bid_id'}      component={ProviderViewBid} />
        <Route path={root+'/provider/auctions/:auction_id/bids'}              component={ProviderMakeBid} />
        <Route path={root+'/provider/auctions/:auction_id'}                   component={ProviderAuctions} />
        <Route path={root+'/provider/jobs/:job_id'}                           component={ProviderJobs} />

        <Route path={root+'/provider/change_password'}                        component={ProviderChangePassword}/>
        
      </div>
    )
  }
}

function mapStateToProps(state) {
   return {
     ui: state.ui,
   };
 
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Provider);
