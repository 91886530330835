import {
  ADD_AUCTION,
  SET_AUCTIONS,
  SET_BIDS,
  SET_JOBS,
  SET_JOB_EVENTS,
} from "./actionTypes";
import moment from "moment";
import { store } from "../configureStore";
import {
  uiStartLoading,
  uiStopLoading,
  uiStartSaving,
  uiStopSaving,
} from "./ui";
//import analytics from '@react-native-firebase/analytics'
import { Socket } from "../../services/socket.service";
import { Analytics } from "../../services/analytics.service";

// Customers only
export const startAuction = (auction) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;

      Analytics.logEvent("start_auction");

      Socket.startAuction(auction)
        .then((response) => {
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          dispatch({
            type: SET_JOBS,
            jobs: response.getJobList.jobs,
          });
          dispatch({
            type: SET_JOB_EVENTS,
            job_events: response.getJobEventList.job_events,
          });
          resolve(response.createAuction.auction);
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};

// Customers only
export const abortAuction = (auction_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;

      Analytics.logEvent("abort_auction");

      Socket.abortAuction(auction_id)
        .then((response) => {
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          resolve(response.updateAuction.auction);
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};

// Providers only
export const declineAuction = (auction_id, provider_account_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;

      Analytics.logEvent("decline_auction");

      Socket.declineAuction(auction_id, provider_account_id)
        .then((response) => {
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          resolve();
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};

export const getAuctions = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;
      dispatch(uiStartLoading());
      Socket.getAuctions()
        .then((response) => {
          //console.warn(auctions)
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          dispatch(uiStopLoading());
          resolve();
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};

// Providers only
export const submitBid = (bid) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;

      Analytics.logEvent("submit_bid");

      Socket.submitBid(bid)
        .then((response) => {
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          resolve(response.createBid.bid);
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};

// Providers only
export const signBid = (bid_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;

      Analytics.logEvent("sign_bid");

      Socket.signBid(bid_id)
        .then((response) => {
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          resolve(response.updateBid.bid);
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};

// Providers only
export const revokeBid = (bid_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;

      Analytics.logEvent("revoke_bid");

      Socket.revokeBid(bid_id)
        .then((response) => {
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          resolve(response.updateBid.bid);
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};

export const acceptBid = (bid_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;

      Analytics.logEvent("accept_bid");

      Socket.acceptBid(bid_id)
        .then((response) => {
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          dispatch({
            type: SET_JOBS,
            jobs: response.getJobList.jobs,
          });
          resolve(response.updateBid.bid);
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};

export const revokeBidSoftOutcome = (bid_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;

      Analytics.logEvent("revoke_bid_outcome");

      Socket.revokeBidSoftOutcome(bid_id)
        .then((response) => {
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          dispatch({
            type: SET_JOBS,
            jobs: response.getJobList.jobs,
          });
          resolve(response.updateBid.bid);
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};

export const softDecline = (bid_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let scope = getState().auth.scope;

      Analytics.logEvent("decline_bid");

      Socket.declineBid(bid_id)
        .then((response) => {
          dispatch({
            type: SET_AUCTIONS,
            auctions: response.getAuctionList.auctions,
            scope,
          });
          dispatch({
            type: SET_BIDS,
            bids: response.getBidList.bids,
          });
          resolve(response.updateBid.bid);
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    });
  };
};
