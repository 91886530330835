import { 
    UI_SET_SCREEN,
    UI_SET_MENU_OPEN,
    UI_SET_BRANDING,
    UI_SET_EMBEDDED,
    UI_START_LOADING, 
    UI_STOP_LOADING,
    UI_START_SAVING, 
    UI_STOP_SAVING,
    UI_START_NETWORK_OFFLINE,
    UI_STOP_NETWORK_OFFLINE,
} from '../actions/actionTypes';


const initialState = {
  screen: 'login',
  menu_open: false,
  is_branded: false,
  is_custom_domain: false,
  brand_provider_account: null,
  is_embedded: 0,
  is_loading: false,
  is_saving: false,
  network_is_offline: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_SET_BRANDING:
      return {
        ...state,
        brand_provider_account: action.brand_provider_account,
        is_branded: !(!action.brand_provider_account),
        is_custom_domain: action.is_custom_domain,
      };
    case UI_SET_SCREEN:
      return {
        ...state,
        screen: action.screen
      };
    case UI_SET_MENU_OPEN:
      return {
        ...state,
        menu_open: action.menu_open
      };
    case UI_SET_EMBEDDED:
      return {
        ...state,
        is_embedded: action.is_embedded
      };
    case UI_START_LOADING:
      return {
        ...state,
        is_loading: true
      };
    case UI_STOP_LOADING:
      return {
        ...state,
        is_loading: false
      };
    case UI_START_NETWORK_OFFLINE:
      return {
        ...state,
        network_is_offline: true
      };
    case UI_STOP_NETWORK_OFFLINE:
      return {
        ...state,
        network_is_offline: false
      };
    default:
      return state;
  }
};

export default reducer;