import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import logo from './logo.svg';
import './App.css';

import Header from './components/Header/Header'
import Menu from './components/Menu/Menu'

import ForkScreen from './screens/Root/Fork'
import Login from './screens/Auth/Login'

import Shop from './screens/Customer/Shop/Shop'

import Home from './screens/Customer/Website/Home'

import Customer from './screens/Customer/Customer'
import CustomerLogin from './screens/Customer/Auth/CustomerLogin'
import CustomerResetPassword from './screens/Customer/Auth/CustomerResetPassword'

import Provider from './screens/Provider/Provider'
import ProviderLogin from './screens/Provider/Auth/ProviderLogin'
import ProviderResetPassword from './screens/Provider/Auth/ProviderResetPassword'

import { autoAuth, authLogout } from './store/actions/auth'
import { getShopData, loadCart } from './store/actions/shop'
import { setBranding } from './store/actions/ui'
import { Socket, SocketService } from './services/socket.service'
import { Analytics, AnalyticsService } from './services/analytics.service'

import DefaultProviderImage from './assets/images/background-5.jpg';

class ProviderApp extends React.Component {

  state = {
    auth_finished: false,
  }

  async componentDidMount() {

    let provider = 'byggbuddy'

    if (this.props.custom_domain) {
      provider = this.props.custom_domain
    }
    else if (this.props.match && this.props.match.params && this.props.match.params.provider) {
      provider = this.props.match.params.provider
    }

    // Check if we are already logged in (or token sent in url)
    Promise.resolve()
    .then(() => {
      return this.props.autoAuth()
    })
    .then(() => {
      return this.props.setBranding(provider)
    })
    .then(() => {
      return this.props.loadCart()
    })
    .then(() => {
      this.setState((prev_state) => {
        return {
          ...prev_state,
          auth_finished: true,
        }
      })
    })
  }

  render() {

    let provider = 'byggbuddy'

    if (this.props.custom_domain) {
      provider = this.props.custom_domain
    }
    else if (this.props.match && this.props.match.params && this.props.match.params.provider) {
      provider = this.props.match.params.provider
    }

    let is_logged_in = this.props.auth.scope == 'customer' || this.props.auth.scope == 'provider'
    let show_menu = is_logged_in
    
    if (!this.state.auth_finished) {
      return (
         <div style={{padding: 20}}>Vänta...</div>
      )
    }

    // Check if we are on the wrong subdomain - redirect if so
    if (!this.props.auth.is_authenticating && this.props.auth.scope == "provider" && this.props.auth.user && this.props.auth.provider_account && this.props.auth.provider_account.provider_account_id) {

      let logged_in_provider = this.props.auth.provider_account.provider_identifier || this.props.auth.provider_account.provider_account_id
    
      if (logged_in_provider != provider) {
        return (
          <div>
            <Redirect to={"/" + logged_in_provider} />
          </div>
        )
      }
    }

    return (
      <div className="byggbuddy-app" style={{backgroundColor: 'white', height: '100vh'}}>
        <div className="byggbuddy-header-container" style={{borderBottom: '1px solid #c0c0c0', /*position: 'fixed', top: 0, width: '100%', height: '85px', zIndex: 999,*/ backgroundColor: 'white'}}>
          <Header {...this.props} />
        </div>
        <div className="byggbuddy-body-container" style={{backgroundColor: 'white', /*marginTop: '85px',*/ display: 'flex', flexDirection: 'horizontal', backgroundImage: 'url(https://awareness.nobicon.se/images/knowledge-power.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>

          <div className="byggbuddy-main-margin byggbuddy-main-margin-left" style={{flexGrow: 1, backgroundColor: 'white', opacity: 0.7}}></div>
          
          <div className="byggbuddy-main-container byggbuddy-content-height" style={{display: 'flex', flexDirection: 'horizontal', /*maxWidth: '1600px',*/ /*minWidth: '800px',*/ flexGrow: 10000, backgroundColor: 'white', opacity: 0.97, overflowY: 'hidden', width: '100%'}}>
            
            {show_menu ? (
              <div className={'byggbuddy-menu-container byggbuddy-content-height' + (this.props.ui.menu_open ? ' byggbuddy-menu-open' : ' byggbuddy-menu-closed')} style={{borderRight: '1px solid #a0a0a0', flexShrink: 0, flexGrow: 0, backgroundColor: '#f8f8f8'}}>
                <Menu {...this.props} />
              </div>
            ) : null}

            <div className="byggbuddy-screen-container" style={{flexGrow: 1}}>

              {this.props.custom_domain && this.props.auth.scope == 'anonymous' ? (
                <Switch>
                  <Route path='/' exact component={Home}/>
                  <Route path='/shop' component={Shop}/>
                </Switch>
              ) : null}

              {!this.props.custom_domain && this.props.auth.scope == 'anonymous' ? (
                <Switch>
                  {this.props.ui.is_branded &&  this.props.ui.brand_provider_account.provider_full_webpage ? (
                    <>
                      <Route exact path='/:provider/' component={Home}/>
                      <Route path='/:provider/provider' render={() => <Redirect to={"/" + provider + "/shop"} />} />

                      <Route path='/:provider/shop' component={Shop}/>
                  
                      <Route path='/:provider/customer_login' component={CustomerLogin}/>
                      <Route path='/:provider/customer_reset_password' component={CustomerResetPassword}/>
                      
                      <Route path='/:provider/provider_login' component={ProviderLogin}/>
                      <Route path='/:provider/provider_reset_password' component={ProviderResetPassword}/>
                    </>
                  ) : (
                    <>
                      <Route exact path='/:provider/' render={() => <Redirect to={"/" + provider + "/shop"} />} />
                      <Route path='/:provider/provider' render={() => <Redirect to={"/" + provider + "/shop"} />} />

                      <Route path='/:provider/shop' component={Shop}/>
                  
                      <Route path='/:provider/customer_login' component={CustomerLogin}/>
                      <Route path='/:provider/customer_reset_password' component={CustomerResetPassword}/>
                      
                      <Route path='/:provider/provider_login' component={ProviderLogin}/>
                      <Route path='/:provider/provider_reset_password' component={ProviderResetPassword}/>
                    </>
                  )}
                </Switch>
              ) : null}

              {this.props.custom_domain && this.props.auth.scope == 'provider' ? (
                <Switch>
                  <Route exact path='/' render={() => <Redirect to={"/provider"} />} />
                  <Route path='/provider' component={Provider}/>
                  <Route path='/shop' component={Shop}/>
                </Switch>
              ) : null}

              {!this.props.custom_domain && this.props.auth.scope == 'provider' ? (
                <Switch>
                  <Route exact path='/:provider' render={() => <Redirect to={"/" + provider + "/provider"} />} />
                  <Route path='/:provider/provider' component={Provider}/>
                  <Route path='/:provider/shop' component={Shop}/>
                </Switch>
              ) : null}

              {this.props.custom_domain && this.props.auth.scope == 'customer' ? (
                <Switch>
                  <Route exact path='/' render={() => <Redirect to={"/customer"} />} />
                  <Route path='/customer' component={Customer}/>
                  <Route path='/shop' component={Shop}/>
                </Switch>
              ) : null}

              {!this.props.custom_domain && this.props.auth.scope == 'customer' ? (
                <Switch>
                  <Route exact path='/:provider' render={() => <Redirect to={"/" + provider + "/customer"} />} />
                  <Route path='/:provider/customer' component={Customer}/>
                  <Route path='/:provider/shop' component={Shop}/>
                </Switch>
              ) : null}


            </div>
          </div>

          <div className="byggbuddy-main-margin byggbuddy-main-margin-right" style={{flexGrow: 1, backgroundColor: 'white', opacity: 0.7}}></div>

        </div>
        <div className="byggbuddy-preload" style={{display: 'none'}}>
          {this.props.shop.tasks.map(task => (
            <div key={task.task_id}>
              <img src={task.image_url} />
            </div>
          ))}
          <img src={DefaultProviderImage} />
          {this.props.shop.provider_accounts.map(provider_account => (
            <div key={provider_account.provider_account_id}>
              {provider_account.provider_images.length ? (
                <div key={provider_account.provider_images[0].provider_image_id}>
                  <img src={provider_account.provider_images[0].image_url} />
                </div>
              ) : null}
              {provider_account.provider_logo ? (
                <div key={provider_account.provider_logo.provider_logo_id}>
                  <img src={provider_account.provider_logo.image_url} />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
   return {
     screen: state.ui.screen,
     auth: state.auth,
     ui: state.ui,
     shop: state.shop,
   };
 
}

function mapDispatchToProps(dispatch) {
  return {
     autoAuth: () => dispatch(autoAuth()),
     setBranding: (provider) => dispatch(setBranding(provider)),
     loadCart: () => dispatch(loadCart()),
   }
}

var ProviderAppRedux = connect(mapStateToProps, mapDispatchToProps)(ProviderApp);
export default ProviderAppRedux;
