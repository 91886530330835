import { SET_JOBS, SET_JOB_EVENTS, UPDATE_JOB_EVENTS, SET_AUCTIONS, PUSH_DEBUG_MESSAGE} from './actionTypes';
import moment from 'moment'
import { uiStartLoading, uiStopLoading, uiStartSaving, uiStopSaving } from './ui'
import { Socket } from '../../services/socket.service'
import { Analytics } from '../../services/analytics.service'
//import RNFetchBlob from 'rn-fetch-blob'
//import RNFS from 'react-native-fs'
//import { Platform } from 'react-native'
//import analytics from '@react-native-firebase/analytics'

export const pushDebugMessage = (message) => {
    return {
        type: PUSH_DEBUG_MESSAGE,
        message: message,
        timestamp: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
    };
};

export const createJobNote = (job_note) => {
	//console.warn(Socket)
	return dispatch => {
		return new Promise((resolve, reject) => {

			Promise.resolve()
			.then(() => {
				return Socket.createJobNote(job_note)	
			})
			.then(response => {

				dispatch({
					type: SET_JOBS,
					jobs: response.getJobList.jobs,
			    })
			    dispatch({
					type: SET_AUCTIONS,
					auctions: response.getAuctionList.auctions,
			    })
			    resolve(response.createJobNote.job_note)
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})
		})
    }
}

export const createJob = (job) => {
	//console.warn(Socket)
	return dispatch => {
		return new Promise((resolve, reject) => {

			Analytics.logEvent('create_job')

			Promise.resolve()
			.then(() => {
				if (job.geolocation && !job.geolocation_id) {
					return Socket.getGeolocation(job.geolocation)
				}
				else {
					return Promise.resolve(null)
				}
			})
			.then(geolocation => {
				if (geolocation) {
					job.geolocation_id = geolocation.geolocation_id
				}
				return Promise.resolve()
			})
			.then(() => {
				return Socket.createJob(job)	
			})
			.then(job => {
				resolve(job)
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})
		})
    }
}

export const createJobEvent = (job_event) => {
	//console.warn(Socket)
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			let scope = getState().auth.scope

			Promise.resolve()
			.then(() => {
				return Socket.createJobEvent(job_event)	
			})
			.then(response => {

				/*dispatch({
					type: SET_JOBS,
					jobs: response.getJobList.jobs,
			    })
			    dispatch({
					type: SET_JOB_EVENTS,
					job_events: response.getJobEventList.job_events,
			    })*/
			    dispatch({
					type: UPDATE_JOB_EVENTS,
					job_id: job_event.job_id,
					job_events: response.getJobEventList.job_events,
					scope,
			    })
			    /*dispatch({
					type: SET_AUCTIONS,
					auctions: response.getAuctionList.auctions,
			    })*/
			    resolve(response.createJobEvent.job_event)
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})
		})
    }
}

export const markJobEventsRead = (job_event_ids) => {

	return dispatch => {
		return new Promise((resolve, reject) => {
		
			Socket.markJobEventsRead(job_event_ids)
			.then(response => {
				// TODO - DO BETTER LOGIC TO AVOID FULL RELOAD
				dispatch({
					type: SET_JOBS,
					jobs: response.getJobList.jobs,
			    })
			    dispatch({
					type: SET_JOB_EVENTS,
					job_events: response.getJobEventList.job_events,
			    })
				dispatch({
					type: SET_AUCTIONS,
					auctions: response.getAuctionList.auctions,
			    })
			    resolve(response)
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})
		})
    }
}

// Customers only
export const completeJob = (job_id) => {

	return dispatch => {
		return new Promise((resolve, reject) => {

			Analytics.logEvent('complete_job')
		
			Socket.completeJob(job_id)
			.then(response => {
				dispatch({
					type: SET_JOBS,
					jobs: response.getJobList.jobs,
			    })
			    dispatch({
					type: SET_JOB_EVENTS,
					jobs: response.getJobEventList.job_events,
			    })
			    resolve(response.updateJob.job)
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})
		})
    }
}

export const getJobs = () => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch(uiStartLoading())
			Socket.getJobs()
			.then(response => {

				//console.warn(jobs)
				dispatch({
					type: SET_JOBS,
					jobs: response.getJobList.jobs,
			    })
			    dispatch({
					type: SET_JOB_EVENTS,
					job_events: response.getJobEventList.job_events,
			    })
			    dispatch(uiStopLoading())
				resolve()
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})
		})
    }
}

export const uploadJobImage = (job_image) => {
	return dispatch => {
		return new Promise((resolve, reject) => {

			Analytics.logEvent('upload_job_image')

			if (!job_image.job_image_uri || !job_image.job_image_uri.base64) {
				reject()
				return
			}

			console.warn('UPLOAD', job_image.task_item_image_id)

			let job_image_data = job_image.job_image_uri.base64
			
			Promise.resolve()
			.then(() => {

				let job_image_with_data = {
					job_id: job_image.job_id,
					job_task_id: job_image.job_task_id,
					task_item_image_id: job_image.task_item_image_id,
					job_image_data: job_image_data,
				}
				return Socket.uploadJobImage(job_image_with_data)	
			})
			.then(() => {
				console.warn('DONE', job_image.task_item_image_id)
				resolve()
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})

			/*Promise.resolve()
			.then(() => {

				if (Platform.OS == 'ios') {

					const filePath = RNFS.DocumentDirectoryPath + '/upload_image' + Math.floor(Math.random() * 10000) + '.jpg'
					//console.warn(filePath)

					return RNFS.copyAssetsFileIOS(job_image.job_image_uri, filePath, 0, 0)
				}
				else {
					return Promise.resolve(job_image.job_image_uri)
				}
			})
			.then(job_image_filename => {

				return RNFS.readFile(job_image_filename, 'base64')
			})
			.then((job_image_data) => {

				//console.warn('Image size', job_image_data.length)

				job_image_with_data = {
					job_id: job_image.job_id,
					job_task_id: job_image.job_task_id,
					task_item_image_id: job_image.task_item_image_id,
					job_image_data: job_image_data, //.substring(0,80000),
				}
				return Socket.uploadJobImage(job_image_with_data)	
			})
			.then(() => {
				console.warn('DONE', job_image.task_item_image_id)
				resolve()
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})*/
		})
    }
}

export const updateJobTask = (job_task) => {

	return dispatch => {
		return new Promise((resolve, reject) => {

			Socket.updateJobTask(job_task)
			.then(response => {
				dispatch({
					type: SET_JOBS,
					jobs: response.getJobList.jobs,
			    })
			    dispatch({
					type: SET_JOB_EVENTS,
					jobs: response.getJobEventList.job_events,
			    })
			    dispatch({
					type: SET_AUCTIONS,
					auctions: response.getAuctionList.auctions,
			    })
			    resolve(response.updateJobTask.job_task)
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})
		})
    }
}

export const submitReview = (review) => {
	//console.warn(Socket)
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			let scope = getState().auth.scope

			Promise.resolve()
			.then(() => {
				return Socket.submitReview(review)	
			})
			.then(response => {

				dispatch({
					type: SET_JOBS,
					jobs: response.getJobList.jobs,
			    })
			    resolve(response.createReview.review)
			})
			.catch(error => {
				console.warn(error)
				reject(error)
			})
		})
    }
}