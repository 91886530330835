import { 
    ADD_AUCTION,
    SET_AUCTIONS,
    SET_BIDS,
    UPDATE_JOB_EVENTS,
} from '../actions/actionTypes';

const initialState = {
  auctions: [],
  bids: [],
  new_job_events: 0,
};

const reducer = (state = initialState, action) => {
  let auctions = []
  let new_job_events = 0
  switch (action.type) {
    case ADD_AUCTION:
      auctions = [...state.auctions]
      auctions.push(action.auction)
      return {
        ...state,
        auctions: auctions,
      }
    case SET_AUCTIONS:

      new_job_events = 0

      for (var i = 0; i < action.auctions.length; i++) {
        let auction = action.auctions[i]

        if (!auction.job_events_read && (auction.job_status == 'auction' || action.scope == 'provider')) {
          new_job_events++
        }
      }
      return {
        ...state,
        auctions: action.auctions,
        new_job_events,
      }
    case SET_BIDS:
      return {
        ...state,
        bids: action.bids
      }
    case UPDATE_JOB_EVENTS:
      new_job_events = state.new_job_events
      auctions = [...state.auctions]
      for (var i = 0; i < auctions.length; i++) {
        let auction = auctions[i]
        if (auction.job_id == action.job_id) {
          auction.job_events = action.job_events

          let job_events_read = true
          let job_events_new = {}
          for (var j = 0; j < auction.job_events.length; j++) {
            let job_event = auction.job_events[j]
            if (!job_event.job_event_read) {
              if (job_event.event_type != 'hidden') {
                job_events_read = false
              }
              if (job_events_new[job_event.event_type]) {
                job_events_new[job_event.event_type]++
              }
              else {
                job_events_new[job_event.event_type] = 1
              }
            }
          }

          if (auction.job_status == 'auction' || action.scope == 'provider') {
            new_job_events = new_job_events + (job_events_read ? 0 : 1) - (auction.job_events_read ? 0 : 1)
          }
          
          auction.job_events_read = job_events_read
          auction.job_events_new = job_events_new
        }
      }
      return {
        ...state,
        auctions,
      }
    default:
      return state;
  }
};

export default reducer;