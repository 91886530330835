export const UI_SET_SCREEN 					= 'UI_SET_SCREEN'
export const UI_SET_MENU_OPEN 				= 'UI_SET_MENU_OPEN'
export const UI_SET_BRANDING 				= 'UI_SET_BRANDING'
export const UI_SET_EMBEDDED 				= 'UI_SET_EMBEDDED'
export const UI_START_LOADING 				= 'UI_START_LOADING'
export const UI_STOP_LOADING 				= 'UI_STOP_LOADING'
export const UI_START_SAVING 				= 'UI_START_SAVING'
export const UI_STOP_SAVING 				= 'UI_STOP_SAVING'
export const UI_START_NETWORK_OFFLINE 		= 'UI_START_NETWORK_OFFLINE'
export const UI_STOP_NETWORK_OFFLINE 		= 'UI_STOP_NETWORK_OFFLINE'

export const TRY_AUTH 						= 'TRY_AUTH'
export const AUTH_SET_TOKEN 				= 'AUTH_SET_TOKEN'
export const AUTH_REMOVE_TOKEN 				= 'AUTH_REMOVE_TOKEN'
export const START_AUTHENTICATING 			= 'START_AUTHENTICATING'
export const STOP_AUTHENTICATING 			= 'STOP_AUTHENTICATING'
export const START_REFRESHING_TOKEN 		= 'START_REFRESHING_TOKEN'
export const STOP_REFRESHING_TOKEN 			= 'STOP_REFRESHING_TOKEN'


export const SET_USER 						= 'SET_USER'
export const SET_PROVIDER_ACCOUNT 			= 'SET_PROVIDER_ACCOUNT'
export const SET_DEVICE_TOKEN				= 'SET_DEVICE_TOKEN'
export const SET_DEVICE_ID 					= 'SET_DEVICE_ID'

export const PUSH_DEBUG_MESSAGE 			= 'PUSH_DEBUG_MESSAGE'

export const SET_ZIP_AREA 					= 'SET_ZIP_AREA'

export const SET_API_ENDPOINT 				= 'SET_API_ENDPOINT'
export const SET_SHOP_SCOPE 				= 'SET_SHOP_SCOPE'


export const SET_TASKS 						= 'SET_TASKS'
export const SET_TASK_ITEMS 				= 'SET_TASK_ITEMS'
export const SET_TASK_ITEM_IMAGES 			= 'SET_TASK_ITEM_IMAGES'
export const SET_TASK_ITEM_QUESTIONS 		= 'SET_TASK_ITEM_QUESTIONS'
export const SET_TASK_ITEM_PREREQUISITES 	= 'SET_TASK_ITEM_PREREQUISITES'
export const SET_DRIVERS 					= 'SET_DRIVERS'
export const SET_OPTIONS 					= 'SET_OPTIONS'
export const SET_SERVICES 					= 'SET_SERVICES'
export const SET_SETTINGS 					= 'SET_SETTINGS'
export const SET_COMPETENCES 				= 'SET_COMPETENCES'
export const SET_PROPERTIES 				= 'SET_PROPERTIES'
export const SET_GEOLOCATIONS 				= 'SET_GEOLOCATIONS'
export const SET_PROVIDER_ACCOUNTS 			= 'SET_PROVIDER_ACCOUNTS'

export const SET_SHOP_PROVIDER_ACCOUNT_ID 	= 'SET_SHOP_PROVIDER_ACCOUNT_ID'

export const SELECT_SEARCH_TASK 			= 'SELECT_SEARCH_TASK'
export const SELECT_SEARCH_TASK_ITEM		= 'SELECT_SEARCH_TASK_ITEM'
export const SET_SEARCH_DRIVER_VALUE 		= 'SET_SEARCH_DRIVER_VALUE'
export const SET_SEARCH_TASK_TEXT 			= 'SET_SEARCH_TASK_TEXT'
export const ADD_IMAGE_TO_TASK 				= 'ADD_IMAGE_TO_TASK'
export const REMOVE_IMAGE_FROM_TASK 		= 'REMOVE_IMAGE_FROM_TASK'
export const DONE_IMAGES_TO_TASK 			= 'DONE_IMAGES_TO_TASK'
export const SET_SEARCH_TASK_ANSWER 		= 'SET_SEARCH_TASK_ANSWER'
export const CONFIRM_PREREQUISITES 			= 'CONFIRM_PREREQUISITES'
export const INITIATE_CHECKOUT 				= 'INITIATE_CHECKOUT'
export const ADD_SEARCH_TO_CART 			= 'ADD_SEARCH_TO_CART'
export const RESET_SEARCH 					= 'RESET_SEARCH'


export const RESET_CART_JOB_OPTIONS 		= 'RESET_CART_JOB_OPTIONS'
export const SELECT_CART_JOB_OPTION 		= 'SELECT_CART_JOB_OPTION'
export const SET_CART_ADDRESS 				= 'SET_CART_ADDRESS'
export const SET_CART_SPEED 				= 'SET_CART_SPEED'
export const SET_CART_MAX_PRICE_PER_HOUR 	= 'SET_CART_MAX_PRICE_PER_HOUR'
export const SET_CART_PREFERRED_STARTDATE	= 'SET_CART_PREFERRED_STARTDATE'
export const SET_CART_PREFERRED_ENDDATE 	= 'SET_CART_PREFERRED_ENDDATE'
export const SET_CART_JOB_NAME 				= 'SET_CART_JOB_NAME'
export const SET_CART_JOB_TEXT 				= 'SET_CART_JOB_TEXT'
export const SET_CART_PROVIDER_ACCOUNT_IDS 	= 'SET_CART_PROVIDER_ACCOUNT_IDS'
export const REMOVE_TASK_FROM_CART 			= 'REMOVE_TASK_FROM_CART'
export const RESET_CART 					= 'RESET_CART'
export const LOAD_CART 						= 'LOAD_CART'


export const ADD_AUCTION 					= 'ADD_AUCTION'
export const SET_AUCTIONS 					= 'SET_AUCTIONS'
export const SET_BIDS 						= 'SET_BIDS'

export const SET_JOBS 						= 'SET_JOBS'
export const SET_JOB_EVENTS 				= 'SET_JOB_EVENTS'
export const UPDATE_JOB_EVENTS 				= 'UPDATE_JOB_EVENTS'

export const SET_CAMERA_ROLL 				= 'SET_CAMERA_ROLL'