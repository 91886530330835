import { store } from '../store/configureStore'
//import { Navigation } from 'react-native-navigation';
//import analytics from '@react-native-firebase/analytics'
//import RNUxcam from 'react-native-ux-cam';
//import { AppEventsLogger } from "react-native-fbsdk";

var Analytics = null

class AnalyticsService {

  static createInstance = () => {
    if (Analytics == null) {
      Analytics = new AnalyticsService()

      /*//RNUxcam.optIntoSchematicRecordings(); // Add this line to enable iOS screen recordings
      //RNUxcam.startWithKey('iwsn06vuw8g7ici'); // Add this line after RNUcam.optIntoSchematicRecordings();
      //RNUxcam.setAutomaticScreenNameTagging(false)
      //RNUxcam.tagScreenName('Root')
      console.warn('UXCAM!')

      Navigation.events().registerCommandListener((name, params) => {
        if (name === 'push') {
          if (params.layout.data.name == 'byggbuddy.FindTaskScreen' ||
              params.layout.data.name == 'byggbuddy.StartAuctionScreen') {
            //console.warn('RNN1', params.layout.id)
            Analytics.setCurrentScreen(params.layout.id)
          }
          else {
            let component_id = params.layout.data.name.substring(10)
            //console.warn('RNN2', component_id)
            Analytics.setCurrentScreen(component_id)
          }
        }
        else if (name === 'popToRoot') {
          if (params.componentId.indexOf('FindTaskScreen') >= 0) {
            //console.warn('RNN3', 'FindTaskScreenReset')
            Analytics.setCurrentScreen('FindTaskScreenReset')
          }
        }
        else {
          //console.warn('RNN', name, params)
        }
      })

      Analytics.logAppOpen()*/

    }
    else {
      console.warn('Tried to instantiate Analytics already running')
    }
  }
  
  logEvent = (event) => {
    if (this.logActive()) {
      //analytics().logEvent(event)
      //RNUxcam.logEvent(event)
      //AppEventsLogger.logEvent(event)
    }
  }

  logBeginCheckout = () => {
    if (this.logActive()) {
      //analytics().logBeginCheckout()
      //RNUxcam.logEvent('begin_checkout')
      //AppEventsLogger.logEvent('begin_checkout')
    }
  }

  logAppOpen = () => {
    if (this.logActive()) {
      //analytics().logAppOpen()
      //RNUxcam.logEvent('open_app')
      //AppEventsLogger.logEvent('open_app')
    }
  }

  logLogin = (properties) => {
    if (this.logActive()) {
      //analytics().logLogin(properties)
      //RNUxcam.logEvent('login', properties)
      //AppEventsLogger.logEvent('login')
    }
  }

  logSignUp = (properties) => {
    if (this.logActive()) {
      //analytics().logSignUp(properties)
      //RNUxcam.logEvent('signup', properties)
      //AppEventsLogger.logEvent('signup')
    }
  }

  logViewItem = (item) => {
    if (this.logActive()) {
      //analytics().logViewItem({items: [item]})
      //RNUxcam.logEvent('view_item', item)
      //AppEventsLogger.logEvent('view_item')
    }
  }

  logAddToCart = (item) => {
    if (this.logActive()) {
      //analytics().logAddToCart({items: [item]})
      //RNUxcam.logEvent('add_to_cart', item)
      //AppEventsLogger.logEvent('add_to_cart')
    }
  }

  logRemoveFromCart = (item) => {
    if (this.logActive()) {
      //analytics().logRemoveFromCart({items: [item]})
      //RNUxcam.logEvent('remove_from_cart', item)
      //AppEventsLogger.logEvent('remove_from_cart')
    }
  }

  setUserId = (user_id) => {
    if (this.logActive()) {
      //analytics().setUserId(user_id)
      //RNUxcam.setUserIdentity(user_id)
    }
  }

  setUserProperty = (property, value) => {
    if (this.logActive()) {
      //analytics().setUserProperty(property, value)
      //RNUxcam.setUserProperty(property, value)
    }
  }

  setCurrentScreen = (activity, screen_name) => {
    if (!screen_name) {
      screen_name = activity
    }
    if (this.logActive()) {
      //analytics().logScreenView({screen_class: activity, screen_name})
      //RNUxcam.tagScreenName(screen_name)
    }
  }

  logActive = () => {
    //let api_endpoint = store.getState().auth.developer_mode.api_endpoint
    //return (api_endpoint == 'prod')
    return false;
  }
}

export {Analytics, AnalyticsService}
