import React from 'react';
import { colors } from '../../../assets/styles/base';

const defaultButton = props => {
    const content = (
      <div 
        className="byggbuddy-button"
        onClick={props.disabled ? null : props.onClick}
        style={props.disabled ? {
            ...styles.button,
            ...props.style,
            ...styles.disabled,
          } : {
            ...styles.button,
            ...props.style,
          }
        }
      >
        <div>
          <span
            className="byggbuddy-button-content" 
            style={{
              ...styles.buttonText,
              ...{color: props.style.color ? props.style.color : styles.buttonText.color},
              ...{fontSize: props.style.fontSize ? props.style.fontSize : styles.buttonText.fontSize}
          }}>
            {props.children}
          </span>
        </div>
      </div>
    );
    return content;
};

const styles = {
  button: {
     alignItems: 'center',
     justifyContent: 'center',
     backgroundColor: colors.secondary,
     height: '40px',
     borderRadius: 7,
     display: 'flex',
     cursor: 'pointer',
  },
  buttonText: {
    color: "#fff",
    /*fontFamily: 'MuseoSans-300',*/ 
    /*fontSize: '16px', */
  },
  disabled: {
      backgroundColor: colors.disabled
  },
};

export default defaultButton;