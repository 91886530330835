import React from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Typography,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { setMenuOpen } from "./../../store/actions/ui";

const Menu = ({ auth, ui, setMenuOpen }) => {
  const location = useLocation();
  const history = useHistory();

  const getRoot = () => {
    if (ui.is_branded) {
      if (ui.is_custom_domain) {
        return "";
      } else {
        if (ui.brand_provider_account.provider_identifier) {
          return "/" + ui.brand_provider_account.provider_identifier;
        } else {
          return "/" + ui.brand_provider_account.provider_account_id;
        }
      }
    } else {
      return "/byggbuddy";
    }
  };

  const goTo = (path) => {
    setMenuOpen(false);
    history.push(getRoot() + path);
  };

  const isActive = (path) => {
    return location.pathname === getRoot() + path;
  };

  const inquiriesMenuItems = [
    {
      label: "Pågående förfrågningar",
      icon: <AssignmentIcon />,
      path: "/provider/auctions/ongoing",
    },
    {
      label: "Avbrutna förfrågningar",
      icon: <CancelIcon />,
      path: "/provider/auctions/aborted",
    },
  ];

  const offersMenuItems = [
    {
      label: "Skickade offerter",
      icon: <CheckCircleIcon />,
      path: "/provider/auctions/bid_sent",
    },
    {
      label: "Vunna offerter",
      icon: <CheckCircleIcon />,
      path: "/provider/auctions/finished",
    },
    {
      label: "Förlorade offerter",
      icon: <CancelIcon />,
      path: "/provider/auctions/declined",
    },
  ];

  return (
    <Box sx={{ width: 250, bgcolor: "background.paper" }}>
      {auth.scope === "provider" && (
        <>
          <Typography variant="h6" sx={{ paddingLeft: 2, paddingTop: 2 }}>
            Förfrågningar
          </Typography>
          <List>
            {inquiriesMenuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => goTo(item.path)}
                selected={isActive(item.path)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>

          <Divider />

          <Typography variant="h6" sx={{ paddingLeft: 2, paddingTop: 2 }}>
            Offerter
          </Typography>
          <List>
            {offersMenuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => goTo(item.path)}
                selected={isActive(item.path)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  ui: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
  setMenuOpen: (menu_open) => dispatch(setMenuOpen(menu_open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
