import React from 'react';
import { connect } from "react-redux";

import ByggbuddyScreen from '../../ByggbuddyScreen';
import { resetProviderUserPassword } from '../../../store/actions/auth'

class ProviderResetPassword extends ByggbuddyScreen {

  state = {
    controls: {
      email: {
        value: '',
        valid: true,
        touched: false,
      },
    },
    email_sent: false
  }

  setControlValue = (event) => {

    let value = event.target.value
    let name = event.target.name
    let type = event.target.type

    if (type == 'checkbox') {
      value = (event.target.checked ? 1 : 0)
    }

    this.setState(prev_state => {
      return {
        ...prev_state,
        controls: {
          ...prev_state.controls,
          [name]: {
            value: value,
            valid: true,
            touched: true
          },
        }
      }
    })
  }

  componentDidMount() {
  }

  resetPassword = () => {
    
    let provider_user = {
      email:    this.state.controls.email.value, 
    }

    this.props.resetProviderUserPassword(provider_user)
    .then(() => {
      this.setState(prev_state => {
        return {
          ...prev_state,
          email_sent: true,
        }
      })
    })
    .catch(() => {
      alert('Misslyckades!')
    })
    
  }

  renderContent() {
    return (
      <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%'}}>

        {this.state.email_sent ? (
          <div>
            <div style={{marginBottom: 20}}>
              Ett nytt lösenord har skickats till din e-post
            </div>
          </div>

        ) : null}

        {this.props.auth.scope == 'anonymous' && !this.state.email_sent ? (
          <div>
            <div style={{marginBottom: 20}}>
              Ange din e-postadress
            </div>
        
            <div style={{marginBottom: 20}}>
              <label style={{fontWeight: 'bold'}}>E-post</label><br />
              <input
                style={{fontSize: 14, padding: 4, width: '100%'}}
                name="email"
                type="email"
                value={this.state.controls.email.value}
                onChange={this.setControlValue} />
            </div>

            <div style={{marginBottom: 20}}>
              <button
                style={{
                  backgroundColor: '#167f40',
                  border: 'none',
                  borderRadius: '0.7em',
                  color: 'white',
                  padding: '1em',
                  fontSize: '1em',
                }}
                disabled={this.state.controls.email.value.length == 0}
                type="submit"
                onClick={(event) => {event.preventDefault(); this.resetPassword()}} >
                Skicka nytt lösenord
              </button>
            </div>
          </div>
        ) : null }

      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
    resetProviderUserPassword: (provider_user) => dispatch(resetProviderUserPassword(provider_user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderResetPassword);
