import React, { Component } from 'react'
import { connect } from 'react-redux'
import utility from '../../../utility/utility';
import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { pushDebugMessage } from '../../../store/actions/debug'

import _ from 'lodash'
import { Analytics } from '../../../services/analytics.service'


class CustomerJobsScreen extends ByggbuddyScreen {

  // Component settings
  show_back = false
  

  findTask = () => {
    this.props.history.push(this.getRoot() + '/shop/find_task/0')
  }

  selectProvider = () => {
    this.props.history.push(this.getRoot() + '/shop/select_provider')
  }

  renderBrandedContent(provider_account) {

    return (
      
      <>
        Hej {provider_account.provider_name}            
      </>

    )
  }

  renderByggbuddyContent() {
    
    return (
      <>
        Not allowed!
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  auth: state.auth,
  ui: state.ui,
  developer_mode: state.auth.developer_mode,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerJobsScreen)
