import React from 'react';
import { connect } from "react-redux";

import { goToScreen } from '../../store/actions/ui'

class ForkScreen extends React.Component {

  /*state = {
    controls: {
      username: {
        value: '',
        valid: true,
        touched: false,
      },
      password: {
        value: '',
        valid: true,
        touched: false,
      },
    }
  }

  setControlValue = (event) => {

    let value = event.target.value
    let name = event.target.name
    let type = event.target.type

    if (type == 'checkbox') {
      value = (event.target.checked ? 1 : 0)
    }

    this.setState(prev_state => {
      return {
        ...prev_state,
        controls: {
          ...prev_state.controls,
          [name]: {
            value: value,
            valid: true,
            touched: true
          },
        }
      }
    })
  }*/

  componentDidMount() {
  }

  /*login = () => {
    
    let auth_data = {
      scope:            'customer',
      username:         this.state.controls.username.value,
      password:         this.state.controls.password.value,
    }

    this.props.tryAuth(auth_data)
  }*/

  render() {
    return (
      <div className="ForkScreen" style={{}}>
        
        <div style={{
          marginBottom: 20, 
          width: '100%',
          height: 'auto',
          backgroundImage: 'url("https://www.byggbuddy.se/House_dark_low_2.jpg")',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          padding: '5em',
          boxSizing: 'border-box',
        }}>
          <div 
            style={{
              maxWidth: '1200px'
            }}>
            <p style={{color: 'white', textAlign: 'left', fontSize: '4.0em'}}>Välkommen till Byggbuddy!</p>
            <p style={{color: 'white', textAlign: 'left', fontSize: '2.5em'}}>Tjänsten finns för närvarande endast som mobilapp. Ladda ner appen på Apple Appstore eller Google Playstore</p>
          </div>
        </div>

        {this.props.auth.scope != 'customer' && this.props.auth.scope != 'provider' ? (
          <div style={{marginBottom: 20, width: '100%'}}>
            <button
              style={{
                backgroundColor: '#167f40',
                border: 'none',
                borderRadius: '0.7em',
                color: 'white',
                padding: '1em',
                fontSize: '1em',
                margin: '1em',
              }}
              type="submit"
              onClick={(event) => {event.preventDefault(); this.props.goToScreen('customer_login')}} >
              Logga in som konsument
            </button>

            <button
              style={{
                backgroundColor: '#167f40',
                border: 'none',
                borderRadius: '0.7em',
                color: 'white',
                padding: '1em',
                fontSize: '1em',
                margin: '1em',
              }}
              type="submit"
              onClick={(event) => {event.preventDefault(); this.props.goToScreen('provider_login')}} >
              Logga in som hantverkare
            </button>

          </div>
        ) : null}

        {this.props.auth.scope == 'customer' ? (
          <div style={{marginBottom: 20, width: '100%'}}>
            <button
              style={{
                backgroundColor: '#167f40',
                border: 'none',
                borderRadius: '0.7em',
                color: 'white',
                padding: '1em',
                fontSize: '1em',
                margin: '1em',
              }}
              type="submit"
              onClick={(event) => {event.preventDefault(); this.props.goToScreen('customer_reset_password')}} >
              Byt lösenord
            </button>
          </div>
        ) : null}

        {this.props.auth.scope == 'provider' ? (
          <div style={{marginBottom: 20, width: '100%'}}>
            <button
              style={{
                backgroundColor: '#167f40',
                border: 'none',
                borderRadius: '0.7em',
                color: 'white',
                padding: '1em',
                fontSize: '1em',
                margin: '1em',
              }}
              type="submit"
              onClick={(event) => {event.preventDefault(); this.props.goToScreen('provider_reset_password')}} >
              Byt lösenord
            </button>
          </div>
        ) : null}

      </div>
    )
  }
}

function mapStateToProps(state) {
   return {
     auth: state.auth,
   };
 
}

function mapDispatchToProps(dispatch) {
  return {
     goToScreen: (screen) => dispatch(goToScreen(screen)),
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForkScreen);

//export default ForkScreen;
