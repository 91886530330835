import React from 'react';

const defaultInput = props => props.rowSpan ? (
    <textarea type={'text'}
        {...props}
        style={{...styles.input, ...props.style, ...(!props.valid && props.touched ? styles.invalid : {})}}
        rows={props.rowSpan}
    >
    </textarea>    
) : (
    <input type={(props.password ? 'password' : 'text')}
        {...props}
        style={{...styles.input, height: (props.rowSpan ? props.rowSpan * 20 + 20 : 40), ...props.style, ...(!props.valid && props.touched ? styles.invalid : {})}}
    />
)
        

const styles = {
    input: {
        backgroundColor: "rgba(0,0,0,0.05)",
        fontFamily: "'MuseoSans-300', Arial sans-serif",
        borderRadius: '4px',
        /*opacity: 0.80,*/
        fontSize: '16px',
        lineHeight: '16px',
        padding: '10px',
        marginVertical: '10px',
        borderWidth: '1px',
        boxSizing: 'border-box',
    },
    invalid: {
        borderColor: "red",
        borderWidth: '1px'
    }
};

export default defaultInput;