import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Redirect, BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
const store = configureStore()

let domain = window.location.hostname
if (domain.indexOf('www.') >= 0) {
	domain = domain.substring(domain.indexOf('www.') + 4)
}

let is_custom_domain = (domain != 'localhost' && domain != 'app.byggbuddy.se' && domain != 'astage2.byggbuddy.se' && domain != 'astage1.byggbuddy.se' && domain != 'adev.byggbuddy.se')

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			{is_custom_domain ? (
				<Route path='/' render={(props) => (<App {...props} custom_domain={domain} />)}  />
			) : (
				<>
					<Route exact path='/' render={() => <Redirect to={"/byggbuddy"} />} />
					<Route path='/:provider' component={App} />
				</>
			)}
		</BrowserRouter>
	</Provider>, 
	document.getElementById('byggbuddy-root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
