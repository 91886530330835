import React, { Component } from 'react'
import { connect } from 'react-redux'
import utility from '../../../utility/utility';
import _ from 'lodash'
import { Redirect } from 'react-router-dom';

import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { Analytics } from '../../../services/analytics.service'
import { pushDebugMessage } from '../../../store/actions/debug'
import { 
  selectSearchTask, 
  resetSearch,
} from '../../../store/actions/shop'

import Cart from './../../../components/Shop/Cart'


class FindTaskScreen extends ByggbuddyScreen {

  // Component settings
  show_cart = true
  
  state = {
    search_mode: false,
    control_search_task: '',
    matched_tasks: [],
    task_question: '',
    task_tip: '',
    task_options: [],
    current_task_id: 0,
  }

  selectSearchTask = (task_id) => {
    
    this.props.onSelectSearchTask(task_id)

    let leaf_selected = false
    let tasks = this.props.shop.provider_tasks
    for (var i = 0; i < tasks.length; i++) {
      let task = tasks[i]

      if (task.task_id == task_id) {
        if (task.leaf == 1) {
          leaf_selected = true
        }
      }
    }

    if (leaf_selected) {
      this.props.history.push(this.getRoot() + '/shop/add_task_to_cart/' + task_id)
    }
    else {
      this.props.history.push(this.getRoot() + '/shop/find_task/' + task_id)
    }
  }

  resetSearch = () => {
    this.props.onResetSearch()
    this.props.history.push(this.getRoot() + '/shop/find_task/0')
  }

  searchTask = (search_string) => {
    this.setState(prev_state => {
      return {
        ...prev_state,
        control_search_task: search_string,
      }
    })

    let matched_tasks = []

    if (search_string != '') {

      let search_words = search_string.toLowerCase().split(' ')

      for (var i = 0; i < this.props.shop.provider_tasks.length; i++) {
        let task = this.props.shop.provider_tasks[i]
        let score = 0

        if (task.leaf && !this.props.shop.always_in_cart_task_ids.includes(task.task_id)) {
          let search_base = task.task_name.toLowerCase()

          for (var j = 0; j < search_words.length; j++) {
            let search_word = search_words[j]

            if (search_base.search(search_word) != -1) {
              score++
            }
          }

          if  (score > 0 &&
              (task.task_status == 'active' || this.props.auth.developer_mode.shop_scope == 'all')
          ) {
            task.score = score
            matched_tasks.push(task)
          }
        }
      }
      matched_tasks = _.sortBy(matched_tasks, 'score')
      matched_tasks.reverse()
    }

    this.setState(prev_state => {
      return {
        ...prev_state,
        matched_tasks: matched_tasks,
      }
    })
  }

  setSearchMode = (search_mode) => {
    this.setState(prev_state => {
      return {
        ...prev_state,
        search_mode: search_mode,
      }
    })
  }

  checkSingleChildRecursive(task) {

    let tasks = this.props.shop.provider_tasks
    let children = []

    for (var i = 0; i < tasks.length; i++) {
      let t = tasks[i]
      if (t.parent_task_id == task.task_id) {
        children.push(t)
      }
    }
    if (children.length == 1) {
      return this.checkSingleChildRecursive(children[0])
    }
    return task
  }

  showBack = () => {

    let param_task_id = 0

    if (this.props.match && this.props.match.params && this.props.match.params.task_id) {
      param_task_id = this.props.match.params.task_id
    }

    return param_task_id > 0
  }

  renderContent() {
  
    let param_task_id = 0

    if (this.props.match && this.props.match.params && this.props.match.params.task_id) {
      param_task_id = this.props.match.params.task_id
    }

    if (param_task_id == 0) {
      this.show_back = false
    }

    let tasks = this.props.shop.provider_tasks
    let search = this.props.shop.search

    let task_question = 'Vad vill du ha hjälp med?'
    let task_tip = '' //'Vill du vill ha hjälp med flera olika uppdrag? Beskriv det första uppdraget och lägg i varukorgen. Därefter går du över till nästa. När du är klar checkar du ut. Precis som en vanlig webshop.'
    let task_options = []

    let current_task_id = (param_task_id ? param_task_id : search.task_id)

    if (this.state.matched_tasks.length > 0) {
      task_options = this.state.matched_tasks
    }
    else {
      for (var i = 0; i < tasks.length; i++) {
        let task = tasks[i]

        if (task.task_id == current_task_id) {
          task_question = task.question
          task_tip = task.tip
        }

        if (task.parent_task_id == current_task_id && 
          (task.task_status == 'active' || this.props.auth.developer_mode.shop_scope == 'all') &&
          !this.props.shop.always_in_cart_task_ids.includes(task.task_id) // Exclude always tasks
        ) {

          // Add task to available options
          // If this task has one child task only, use that instead to avoid unnecessary choices
          task_options.push(this.checkSingleChildRecursive(task))
        }
      }
    }

    // Color of buttons 
    let color_index = 0
    let colors = ['#0d7a3e', '#00a1b1', '#00a58a', '#026ca0']
    for (var i = 0; i < task_options.length; i++) {
      let task_option = task_options[i]
      task_option.color = colors[color_index]
      color_index++
      if (color_index == colors.length) {
        color_index = 0
      }
    }

    if (task_options.length == 1 && !task_options[0].leaf) {
      return (
         <Redirect to={this.getRoot() + '/shop/find_task/' + task_options[0].task_id} />
      )
    } 

    return (
      
        <>
          <div>
            <div className="byggbuddy-header-primary" style={{padding: '0 20px', textAlign: 'center'}}>
              <span style={{fontWeight: 'normal', color: '#000000', textAlign: 'center', fontFamily: 'MuseoSans-300'}}>{task_question}</span>
            </div>
          </div>
          
          {/* Search box */}
          <div style={{width: '100%', padding: '0 30px', margin: '50px 0', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div 
              className="byggbuddy-search-box"
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 7,
                borderColor: '#a0a0a0',
                borderWidth: 1,
                display: 'flex',
                flexDirection: 'row',

            }}>
              <DefaultInput
                placeholder="Sök tjänst som du önskar få hjälp med"
                value={this.state.control_search_task}
                onChange={(event) => this.searchTask(event.target.value)}
                onFocus={() => this.setSearchMode(true)}
                onBlur={() => this.setSearchMode(false)}
                style={{
                  borderRadius: '7px 0 0 7px', 
                  width: 'calc(100% - 40px)',
                  maxWidth: '650px', 
                  margin: '0', 
                  backgroundColor: '#ffffff',
                  fontSize: 20,
                  fontFamily: 'MuseoSans-300',
                  padding: '20px',
                  height: 40,
                  textAlignVertical: 'center',
                  textAlign: 'center',
                  border: 'none',
                }}
              />
              <div style={{backgroundColor: 'rgb(13, 122, 62)', borderRadius: '0 7px 7px 0', height: '42px', width: '42px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <span style={{color: 'white'}} className="material-icons md-48">search</span>
              </div>
            </div>
            {this.state.control_search_task ? (
              <div style={{marginTop: '10px', color: '#404040', cursor: 'pointer'}} onClick={() => this.searchTask('')}>Rensa sökning</div>
            ) : null}
          </div>
          
          <div style={{
            marginTop: 20, 
            paddingHorizontal: 20, 
            display: 'flex',
            flex: 0, 
            flexDirection: 'row', 
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '100%',
            maxWidth: '800px',
            margin: '20px auto 0',
          }}>
            {task_options.map(task_option => (
              <div
                key={task_option.task_id}
              >
                <div
                  className="byggbuddy-task-option"
                  onClick={() => this.selectSearchTask(task_option.task_id)}
                  style={{backgroundColor: task_option.color}} >
                  {task_option.image_url ? (
                    <div className="byggbuddy-task-option-content">
                      <div className="byggbuddy-task-option-image">
                        <img src={task_option.image_url} style={{aspectRatio: 1.33, borderRadius: 7, width: '100%'}} />
                      </div>
                      <div style={{display: 'flex'}}>
                        <span
                         style={{
                           color: '#ffffff',
                           fontSize: ((this.state.matched_tasks.length > 0 ? task_option.task_name.length : task_option.answer.length) > 30 ? '14px' : '20px'),
                           fontFamily: 'MuseoSans-300',
                           }}>
                          {this.state.matched_tasks.length > 0 ? task_option.task_name : task_option.answer}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="byggbuddy-task-option-content" style={{flex: 0, flexDirection: 'column', alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'space-between'}}>
                      <div className="byggbuddy-task-option-image">
                      </div>
                      <div style={{display: 'flex'}}>
                        <span
                         style={{
                           color: '#ffffff',
                           fontSize: ((this.state.matched_tasks.length > 0 ? task_option.task_name.length : task_option.answer.length) > 30 ? '14px' : '20px'),
                           fontFamily: 'MuseoSans-300',
                           }}>
                          {this.state.matched_tasks.length > 0 ? task_option.task_name : task_option.answer}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>

    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  shop: state.shop,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
    onSelectSearchTask: (task_id) => dispatch(selectSearchTask(task_id)),
    onResetSearch: () => dispatch(resetSearch()),
    pushDebugMessage: (message) => dispatch(pushDebugMessage(message)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindTaskScreen)
