import React, { Component } from 'react'
import { connect } from 'react-redux'
import utility from '../../../utility/utility';
import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { pushDebugMessage } from '../../../store/actions/debug'

import _ from 'lodash'
import { Analytics } from '../../../services/analytics.service'

import ProviderJob from './ProviderJob'


class ProviderJobsScreen extends ByggbuddyScreen {

  // Component settings
  show_back = false
  
  selectJob = (selected_job_id) => {
    if (selected_job_id) {
      this.goTo('/provider/jobs/' + selected_job_id)
    }
    else {
      this.goTo('/provider/jobs')
    }
  }

  renderBrandedContent(provider_account) {

    let selected_job_id = 0
    if (this.props.match && this.props.match.params && this.props.match.params.job_id) {
      selected_job_id = this.props.match.params.job_id
    }

    return (

      <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
      
        <div className={'byggbuddy-job-list' + (selected_job_id ? ' item-selected' : '')} style={{flexShrink: '0', flexGrow: '0', borderRight: '1px solid rgb(192, 192, 192)'}}>

          {this.props.jobs.map(job => (
            <div key={job.job_id} className={'byggbuddy-job-card' + (job.job_id == selected_job_id ? ' selected' : '')} style={{padding: '15px', cursor: 'pointer', borderBottom: '1px solid rgb(192, 192, 192)'}} onClick={() => this.selectJob(job.job_id)}>
              {this.renderJobCard(job)}
            </div>
          ))}            
        </div>

        {selected_job_id ? (
          <div className={'byggbuddy-job-container' + (selected_job_id ? ' item-selected' : '')} style={{flexGrow: '1'}}>
            <div className={'byggbuddy-job-container-back'} style={{padding: '15px', cursor: 'pointer'}}>
              <div style={{textDecoration: 'none', color: '#404040', display: 'flex', alignItems: 'center'}} onClick={() => this.selectJob(0)}><span className="material-icons">arrow_back_ios</span> Tillbaka</div>
            </div>
            <ProviderJob {...this.props} job_id={selected_job_id} />
          </div>
        ) : null}

        {!selected_job_id && this.props.jobs.length ? (
          <div className={'byggbuddy-job-container'} style={{flexGrow: '1'}}>
            <div className={'byggbuddy-job-container-back'} style={{padding: '15px', cursor: 'pointer'}}>
              <div style={{textDecoration: 'none', color: '#404040', display: 'flex', alignItems: 'center'}} onClick={() => this.selectJob(0)}><span className="material-icons">arrow_back_ios</span> Tillbaka</div>
            </div>
            <ProviderJob {...this.props} job_id={this.props.jobs[0].job_id} />
          </div>
        ) : null}

        {!selected_job_id && !this.props.jobs.length ? (
          <div className={'byggbuddy-job-placeholder'} style={{padding: '20px'}}>
            <i>Det finns inga vunna uppdrag</i>
          </div>
        ) : null}

      </div>

    )
  }

  renderJobCard(job) {
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{width: '35%', maxWidth: '150px', flexGrow: 0, flexShrink: 5}}>
          <img src={job.job_images.length ? job.job_images[0].job_image_url : require('../../../assets/images/background-5.jpg')} style={{width: '100%'}} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', width: '65%', marginLeft: '15px'}}>
          <div style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
            {job.job_name}
          </div>
          <div style={{marginBottom: '10px'}}>
            {job.geolocation_text}
          </div>
          <div style={{fontSize: '14px'}}>
           {job.first_name} {job.last_name}
          </div>
          {job.job_hours ? (
            <div style={{fontSize: '14px'}}>
              Uppskattad omfattning {utility.niceNumberFormat(job.job_hours)}
            </div>
          ) : null}
          <div style={{fontSize: '14px'}}>
            Start tidigast {job.preferred_startdate}
          </div>
          <div style={{fontSize: '14px'}}>
            Färdigt senast {job.preferred_enddate}
          </div>
        </div>
      </div>
    )
  }

  renderByggbuddyContent() {
    
    return (
      <>
        Not allowed!
      </>
    )
  }  
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  auth: state.auth,
  ui: state.ui,
  developer_mode: state.auth.developer_mode,
  jobs: state.jobs.jobs,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderJobsScreen)
