import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import utility from '../../../utility/utility';
import _ from 'lodash'

import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { Analytics } from '../../../services/analytics.service'
import { pushDebugMessage } from '../../../store/actions/debug'
import { 
  getGeolocations,
} from '../../../store/actions/shop'

import DefaultProviderImage from '../../../assets/images/background-5.jpg';


class SelectProviderScreen extends ByggbuddyScreen {

  // Component settings
  show_cart = false
  content_max_width = '100%'
  
  state = {
    control_search_provider: '',
    matched_providers: [],
    is_searching: false,
  }

  // Search state
  last_search_input = null
  search_counter = 0

  goToProvider = (provider) => {
    this.props.history.push('/' + provider)
  }

  searchProvider = (search_string) => {

    // Update search control
    this.setState(prev_state => {
      return {
        ...prev_state,
        control_search_provider: search_string,
      }
    })

    this.last_search_input = new Date();

    if (search_string.length <= 2) {
      this.setState(prev_state => {
        return {
          ...prev_state,
          matched_providers: [],
          is_searching: false,
        }
      })
      return
    }

    this.search_counter++
    let current_search_counter = this.search_counter

    // Wait for user to complete typing before searching
    setTimeout(async () => {

      var now = new Date(); 
      var time_since_last_input = now.getTime() - this.last_search_input.getTime();

      if (time_since_last_input < 1000 || search_string.length <= 2) {
        return
      }

      this.setState(prev_state => {
        return {
          ...prev_state,
          is_searching: true,
        }
      })

      let matched_providers = []
      
      let search_words = search_string.toLowerCase().split(' ')
      let matched_geolocations = []
      let search_promises = []

      // Find geolocations matching each search word
      search_words.forEach(search_word => {
        let search_promise = new Promise((resolve, reject) => {
          this.props.getGeolocations(search_word, ["sublocality", "locality"])
          .then(geolocations => {
            console.log(geolocations)
            matched_geolocations = matched_geolocations.concat(geolocations)
            resolve()
          })
          .catch(error => {
            console.log(error)
            resolve()
          })
        })
        search_promises.push(search_promise)
      })

      Promise.all(search_promises)
      .then(() => {

        // Abort if a newer search har been started
        if (this.search_counter != current_search_counter) {
          return
        }

        // Did the user clear the search box while we waited for the geolocations?
        if (this.state.control_search_provider.length <= 2) {
          return
        }

        for (var i = 0; i < this.props.shop.provider_accounts.length; i++) {
          let provider_account = this.props.shop.provider_accounts[i]
          
          // Match provider if search words in their name and text
          let score = 0
          let search_base = provider_account.provider_name + ' ' + provider_account.provider_description + (provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180 ? ' ' + provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180 : '')
          search_base = search_base.toLowerCase()

          for (var j = 0; j < search_words.length; j++) {
            let search_word = search_words[j]

            if (search_word.length > 1 && search_base.search(search_word) != -1) {
              score++
              console.log('MATCH', provider_account.provider_name, search_word)
            }
          }

          provider_account.score = score
          
          // Match provider if within reach of geolocation
          let provider_locations = provider_account.provider_locations
          let provider_max_distance = provider_account.provider_max_distance
          let valid_location = false
            
          matched_geolocations.forEach(geolocation => {  
            for (var j = 0; j < provider_locations.length; j++) {
              let provider_location = provider_locations[j]

              // Get distance from provider location to job location
              let p = 0.017453292519943295;
        
              let lat1 = provider_location.latitude;
              let lon1 = provider_location.longitude;

              let lat2 = geolocation.latitude;
              let lon2 = geolocation.longitude;

              let a = 0.5 - Math.cos((lat2 - lat1) * p)/2 + Math.cos(lat1 * p) * Math.cos(lat2 * p) * (1 - Math.cos((lon2 - lon1) * p))/2;
              let distance = 12742 * Math.asin(Math.sqrt(a)); //Distance in km

              //console.log('DISTANCE', provider_location, distance)

              if (distance <= provider_max_distance) {
                valid_location = true
                console.log('MATCH LOC', provider_account.provider_name, geolocation.geolocation_text)
              }
            }
          })
          if (valid_location) {
            provider_account.score++
          }
          if (provider_account.score > 0) {
            matched_providers.push(provider_account)
          }
        }

        console.log('MATCHED PROVIDERS', matched_providers)

        matched_providers = _.sortBy(matched_providers, 'score')
        matched_providers.reverse()

        // Update only if we are the latest search process
        if (this.search_counter == current_search_counter) {
          this.setState(prev_state => {
            return {
              ...prev_state,
              matched_providers: matched_providers,
              is_searching: false
            }
          })
        }
      })
    }, 2000)
  }

  renderContent() {

    // View not allowed if in branded mode
    if (this.getRoot() != '/byggbuddy') {
      return null
    }

    let provider_accounts = []
    if (this.state.matched_providers.length > 0) {
      provider_accounts = this.state.matched_providers
    }
    else {
      provider_accounts = this.props.shop.provider_accounts
    }

    // Filter out hidden
    let filtered_providers = []

    for (var i = 0; i < provider_accounts.length; i++) {
      let provider = provider_accounts[i]

      if (!provider.hide_from_byggbuddy) {
        filtered_providers.push(provider)
      }
    }

    provider_accounts = filtered_providers

    return (
      
      <>
        <div>
          <div className="byggbuddy-header-primary" style={{padding: '0 20px', textAlign: 'center'}}>
            <span style={{fontWeight: 'normal', color: '#000000', textAlign: 'center', fontFamily: 'MuseoSans-300'}}>Välj den hantverkare du vill få hjälp av</span>
          </div>
        </div>

        {/* Search box */}  
        <div style={{width: '100%', padding: '0 30px', margin: '50px 0', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div 
            className="byggbuddy-search-box"
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              borderRadius: 7,
              borderColor: '#a0a0a0',
              borderWidth: 1,
              display: 'flex',
              flexDirection: 'row',
          }}>
            <DefaultInput
              placeholder="Sök hantverkare med namn eller plats"
              value={this.state.control_search_provider}
              onChange={(event) => this.searchProvider(event.target.value)}
              style={{
                borderRadius: '7px 0 0 7px', 
                width: 'calc(100% - 40px)',
                maxWidth: '900px', 
                backgroundColor: '#ffffff',
                fontSize: 20,
                fontFamily: 'MuseoSans-300',
                padding: '20px',
                height: 40,
                textAlignVertical: 'center',
                textAlign: 'center',
                border: 'none',
              }}
            />
            <div style={{backgroundColor: 'rgb(13, 122, 62)', borderRadius: '0 7px 7px 0', height: '42px', width: '42px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <span style={{color: 'white'}} className="material-icons md-48">search</span>
            </div>
          </div>
          {this.state.is_searching ? (
            <div style={{marginTop: '10px', color: '#404040', cursor: 'pointer'}}>Söker...</div>
          ) : null}

          {this.state.control_search_provider && !this.state.is_searching ? (
            <div style={{marginTop: '10px', color: '#404040', cursor: 'pointer'}} onClick={() => this.searchProvider('')}>Rensa sökning</div>
          ) : null}
        </div>

        <div style={{
          display: 'flex',
          flex: 0, 
          flexDirection: 'row', 
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          /*maxWidth: '1200px',*/
          margin: '20px auto 0',
        }}>
          {provider_accounts.map(provider_account => (
            
            <div
              key={provider_account.provider_account_id}
              className="byggbuddy-provider-card"
              onClick={() => this.goToProvider(provider_account.provider_identifier ? provider_account.provider_identifier : provider_account.provider_account_id)}
              style={{}}>
              
              <div
                className="byggbuddy-provider-card-image" 
                style={{backgroundImage: (provider_account.provider_images.length > 0 ? 'url(' + provider_account.provider_images[0].image_url + ')' : 'url(' + DefaultProviderImage + ')'), backgroundSize: 'cover', backgroundPosition: 'center center'}}>
              </div>
              <div
                className="byggbuddy-provider-card-content" 
                style={{}}>
                <div style={{display: 'flex', flexShrink: 1, flexDirection: 'column', alignItems: 'left', width: '100%', height: '215px', padding: '10px 20px 20px', boxSizing: 'border-box'}}>
                  <div style={{width: '100%', flexShrink: 0}}>
                    {provider_account.provider_logo ? (
                      <div
                       style={{
                         color: 'black',
                         fontSize: 20,
                         fontFamily: 'MuseoSans-300',
                         textAlign: 'left',
                         marginBottom: '5px',
                      }}>
                        <img src={provider_account.provider_logo.image_url} style={{width: '160px'}} />
                      </div>
                    ) : (
                      <div
                       style={{
                         color: 'black',
                         fontSize: 20,
                         fontFamily: 'MuseoSans-300',
                         textAlign: 'left',
                         marginBottom: '5px',
                         overflow: 'hidden',
                        }}>
                        {provider_account.provider_name}
                      </div>
                    )}
                  </div>
                  <div style={{width: '100%', flexShrink: 0}}>
                    <div
                       style={{
                         color: '#808080',
                         fontSize: 12,
                         fontFamily: 'MuseoSans-300',
                         textAlign: 'left',
                         marginBottom: '10px',
                         overflow: 'hidden',
                      }}>
                      {provider_account.provider_locations.map(provider_location => utility.cityAddress(provider_location.geolocation_text)).join(', ')}
                    </div>
                  </div>
                  <div style={{width: '100%', flexShrink: 1, overflow: 'hidden'}}>
                    <div
                       style={{
                         color: '#404040',
                         fontSize: 13,
                         fontFamily: 'MuseoSans-300',
                         textAlign: 'left',
                         overflow: 'hidden',
                         whiteSpace: 'pre-line',
                      }}>
                      {provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180 ? provider_account.provider_texts.ID_PROVIDER_DESCRIPTION_180 :  utility.truncateTextRows(provider_account.provider_description, 6, 32)}
                    </div>
                  </div>
                </div>
                <div style={{width: '100%', height: '35px', flexShrink: 0, backgroundColor: 'rgb(13, 122, 62)', color: 'white', fontSize: '13px', fontWeight: 'bold', textAlign: 'right', padding: '10px', boxSizing: 'border-box'}}>
                  Gå till hantverkaren
                </div>
              </div>
            </div>
          ))}
        </div>
      </>

    )
  }
}

const mapStateToProps = state => ({
  ...ByggbuddyScreen.mapStateToProps(state),
  shop: state.shop,
})

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
    getGeolocations: (search_text, require_street_number) => dispatch(getGeolocations(search_text, require_street_number)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectProviderScreen)
