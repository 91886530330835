import { 
  AUTH_SET_TOKEN, 
  AUTH_REMOVE_TOKEN,
  START_AUTHENTICATING,
  STOP_AUTHENTICATING,
  START_REFRESHING_TOKEN,
  STOP_REFRESHING_TOKEN,
  SET_USER, 
  SET_PROVIDER_ACCOUNT, 
  SET_DEVICE_TOKEN, 
  SET_DEVICE_ID, 
  SET_ZIP_AREA,
  SET_API_ENDPOINT,
  SET_SHOP_SCOPE,
} from '../actions/actionTypes';

const initialState = {
  scope: 'none', // 'anonymous', 'customer', 'provider'
  token: null,
  token_expiry_date: null,
  is_authenticating: false,
  is_refreshing_token: false,
  user: null,
  provider_account: {provider_users: []},
  device_token: '', // Used by Firebase to send push messages
  device_id: '',    // Created by the app to distinguish from other apps when not logged in
  zip_area: null,
  service_available: false,
  developer_mode: {
    api_endpoint: null, // 'prod', 'dev'
    shop_scope: null, // 'published', 'all'
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_TOKEN:
      return {
        ...state,
        scope: action.scope,
        token: action.token,
        token_expiry_date: action.token_expiry_date
      };
    case AUTH_REMOVE_TOKEN:
      return {
        ...state,
        scope: 'none',
        token: null,
        token_expiry_date: null
      };
    case START_AUTHENTICATING:
      return {
        ...state,
        is_authenticating: true,
      };
    case STOP_AUTHENTICATING:
      return {
        ...state,
        is_authenticating: false,
      };
    case START_REFRESHING_TOKEN:
      return {
        ...state,
        is_refreshing_token: true,
      };
    case STOP_REFRESHING_TOKEN:
      return {
        ...state,
        is_refreshing_token: false,
      };
    case SET_USER:
      //console.warn('USER', action.user)
      return {
        ...state,
        user: action.user,
      };
    case SET_PROVIDER_ACCOUNT:
      //console.warn('PROPERTIES', action.provider_account.provider_properties)
      return {
        ...state,
        provider_account: action.provider_account,
      };
    case SET_DEVICE_TOKEN:
      return {
        ...state,
        device_token: action.device_token,
      };
    case SET_DEVICE_ID:
      return {
        ...state,
        device_id: action.device_id,
      };
    case SET_ZIP_AREA:
      return {
        ...state,
        zip_area: action.zip_area,
        service_available: (action.zip_area > 10000 && action.zip_area < 20000),
      };
    case SET_API_ENDPOINT:
      return {
        ...state,
        developer_mode: {
          ...state.developer_mode,
          api_endpoint: action.api_endpoint,
        }
      };
    case SET_SHOP_SCOPE:
      return {
        ...state,
        developer_mode: {
          ...state.developer_mode,
          shop_scope: action.shop_scope,
        }
      };
    default:
      return state;
  }
};

export default reducer;
