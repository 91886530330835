import React, { Component } from 'react'
import { connect } from 'react-redux'
import utility from '../../../utility/utility';
import validate from '../../../utility/validation';
import ByggbuddyScreen from '../../ByggbuddyScreen';
import DefaultInput from '../../../components/UI/DefaultInput/DefaultInput';
import DefaultButton from '../../../components/UI/DefaultButton/DefaultButton';
import { pushDebugMessage } from '../../../store/actions/debug'
import moment from 'moment'

import { 
  createJobEvent,
  markJobEventsRead,
} from '../../../store/actions/jobs'

import _ from 'lodash'


class ProviderMessagesScreen extends ByggbuddyScreen {

  // Component settings
  show_back = false
  content_max_width = 'none'
  content_padding = '0'

  state = {
    job_id: 0,
    event_channel_name: '',
    channel_headline: '',
    event_receiver: '',
    provider_account_id: 0,
    last_event_id: 0,
    is_updating: false,
    is_updating_answer: false,
    num_messages: 0,
    controls: {
      new_message: {
        value: '',
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false
      },
      answer_message: {
        parent_job_event_id: 0,
        value: '',
        valid: false,
        validationRules: {
          notEmpty: true
        },
        touched: false
      },
    },
  }

  updateInputState = (key, value) => {
    let valid = false;
    valid = validate(value, this.state.controls[key].validationRules)
    this.setState(prevState => {
      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          [key]: {
            ...prevState.controls[key],
            value: value,
            valid: valid,
            touched: true
          }
        }
      }
    })
    if (key == 'new_message') {
      let storage_name = 'byggbuddy:messages_' + this.state.job_id + '_' + this.props.scope + '_' + this.props.user.provider_user_id + '_' + this.state.event_channel_name
      localStorage.setItem(storage_name, value)
    }
  }

  componentDidMount = async () => {

    let provider_account_id = this.props.provider_account.provider_account_id
    let event_channel_name = 'message_customer_provider_' + provider_account_id
    let event_receiver = 'customer'

    if (!this.props.job_id) {
      alert('Inget uppdrag valt!')
    }

    let new_message = ''
    let storage_name = 'byggbuddy:messages_' + this.props.job_id + '_' + this.props.scope + '_' + this.props.user.provider_user_id + '_' + this.state.event_channel_name
    let cached_message = await localStorage.getItem(storage_name)
    if (cached_message) {
      new_message = cached_message
    }

    this.setState(prevState => {
      return {
        ...prevState,
        job_id: this.props.job_id,
      }
    })

    // Make sure the last message is always visible even if is is a child message
    let job = null
    for (var i = 0; i < this.props.jobs.length; i++) {
      let j = this.props.jobs[i]
      if (j.job_id == this.props.job_id) {
        job = j
      }
    }

    if (!job) {
      for (var i = 0; i < this.props.auctions.length; i++) {
        let a = this.props.auctions[i]
        if (a.job_id == this.props.job_id) {
          job = a
        }
      }
    }


    if (!job)
      return

    let job_events = []
    for (var i = 0; i < job.job_events.length; i++) {
      let job_event = job.job_events[i]
      if (job_event.event_type == 'message' && job_event.event_channel_name == event_channel_name) {
        job_events.push(job_event)
      }
    }
    job_events = _.sortBy(job_events, 'event_datetime').reverse();

    if (job_events.length > 0) {
      if (job_events[0].parent_job_event_id > 0) {
        
        this.setState(prevState => {
          return {
            ...prevState,
            last_event_id: job_events[0].parent_job_event_id,
          }
        })
      }
    }

    let channel_headline = 'Okänd'
    /*let event_receiver = 'all'
    let provider_account_id = 0

    if (event_channel_name == 'message_customer_auction_providers') {
      event_receiver = 'customer'
      channel_headline = 'Uppdragsgivaren och alla hantverkare'
    }
    else if (event_channel_name == 'message_customer_byggbuddy') {
      event_receiver = 'customer'
      channel_headline = 'Uppdragsgivaren'
    }
    else if (event_channel_name.substring(0, 27) == 'message_byggbuddy_provider_') {
      event_receiver = (this.props.provider_account.is_byggbuddy ? 'provider' : 'byggbuddy')
      provider_account_id = parseInt(event_channel_name.substring(27))
      channel_headline = (this.props.provider_account.is_byggbuddy ? (job_events.length > 0 ? job_events[0].provider_name : 'hantverkaren') : 'Byggbuddy')
    }
    else if (event_channel_name.substring(0, 26) == 'message_customer_provider_') {
      event_receiver = 'customer'
      provider_account_id = parseInt(event_channel_name.substring(26))
      channel_headline = 'Uppdragsgivaren'
    }*/

    this.setState(prevState => {
      return {
        ...prevState,
        event_channel_name,
        channel_headline,
        event_receiver,
        provider_account_id,
        num_messages: job_events.length,
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {

    let event_channel_name = this.props.event_channel_name ? this.props.event_channel_name : 'all'

    if (!this.props.job_id) {
      alert('Inget uppdrag valt!')
    }

    let job = null
    for (var i = 0; i < this.props.jobs.length; i++) {
      let j = this.props.jobs[i]
      if (j.job_id == this.props.job_id) {
        job = j
      }
    }

    if (!job) {
      for (var i = 0; i < this.props.auctions.length; i++) {
        let a = this.props.auctions[i]
        if (a.job_id == this.props.job_id) {
          job = a
        }
      }
    }


    if (!job)
      return

    let job_events = []
    for (var i = 0; i < job.job_events.length; i++) {
      let job_event = job.job_events[i]
      if (job_event.event_type == 'message' && job_event.event_channel_name == event_channel_name) {
        job_events.push(job_event)
      }
    }

    let num_messages = job_events.length
    if (num_messages > this.state.num_messages) {
      this.setState(prevState => {
        return {
          ...prevState,
          num_messages,
        }
      })
    }

    this.markJobEventsRead()
  }

  componentDidAppear() {

    this.read_timer = setTimeout(() => {
      this.markJobEventsRead()
    }, 1000)
  }

  componentDidDisappear() {
    
    if (this.read_timer) {
      clearTimeout(this.read_timer)
      this.read_timer = null
    }
  }

  markJobEventsRead = () => {

    let job = null
    for (var i = 0; i < this.props.jobs.length; i++) {
      let j = this.props.jobs[i]
      if (j.job_id == this.state.job_id) {
        job = j
      }
    }

    // If job not available, load data from auction instead
    if (!job) {
      for (var i = 0; i < this.props.auctions.length; i++) {
        let j = this.props.auctions[i]
        if (j.job_id == this.state.job_id) {
          job = j
        }
      }
    }

    if (!job) {
      return
    }

    let job_event_ids = []
    for (var i = 0; i < job.job_events.length; i++) {
      let job_event = job.job_events[i]
      if (job_event.event_type == 'message' && job_event.event_channel_name == this.state.event_channel_name && !job_event.job_event_read) {
        job_event_ids.push(job_event.job_event_id)
      }
    }

    if (job_event_ids.length > 0) {
      this.props.markJobEventsRead(job_event_ids)
    }
  }

  showUpdateIndicator = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        is_updating: true,
      }
    })
  }

  hideUpdateIndicator = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        is_updating: false,
      }
    })
  }

  /*showUpdateIndicatorAnswer = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        is_updating_answer: true,
      }
    })
  }

  hideUpdateIndicatorAnswer = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        is_updating_answer: false,
      }
    })
  }

  openAnswerInput = (job_event_id) => {

    if (this.state.answer_event_id == job_event_id) {
      job_event_id = 0
    }

    this.setState(prevState => {
      return {
        ...prevState,
        answer_event_id: job_event_id,
      }
    })
  }

  closeAnswerInput = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        answer_event_id: 0,
      }
    })
  }*/

  sendNewMessage = () => {

    let job_event = {
      job_id: this.state.job_id,
      event_actor: 'provider',
      event_type: 'message',
      event_channel_name: this.state.event_channel_name,
      event_receiver: this.state.event_receiver,
      provider_account_id: this.state.provider_account_id,
      event_message: this.state.controls.new_message.value,
    }

    this.showUpdateIndicator()
    this.props.createJobEvent(job_event)
    .then(() => {
      this.hideUpdateIndicator()
      this.updateInputState('new_message', '')
    })
    .catch(error => {
      this.hideUpdateIndicator()
      console.warn(error)
    })
  }

  /*sendAnswerMessage = () => {

    let job_event = {
      parent_job_event_id: this.state.answer_event_id,
      job_id: this.state.job_id,
      event_actor: 'provider',
      event_type: 'message',
      event_channel_name: this.state.event_channel_name,
      event_receiver: this.state.event_receiver,
      provider_account_id: this.state.provider_account_id,
      event_message: this.state.controls.answer_message.value,
    }

    this.showUpdateIndicatorAnswer()
    this.props.createJobEvent(job_event)
    .then(() => {
      this.hideUpdateIndicatorAnswer()
      this.updateInputState('answer_message', '')
    })
  }*/
  

  renderBrandedContent(provider_account) {

    let job = null
    for (var i = 0; i < this.props.jobs.length; i++) {
      let j = this.props.jobs[i]
      if (j.job_id == this.state.job_id) {
        job = j
      }
    }

    // If job not available, load data from auction instead
    if (!job) {
      for (var i = 0; i < this.props.auctions.length; i++) {
        let j = this.props.auctions[i]
        if (j.job_id == this.state.job_id) {
          job = j
        }
      }
    }

    if (this.props.scope != 'provider' || !job) {
      return null
    }

    let locked = (job.job_status != 'auction' && this.state.provider_account_id != job.provider_account_id)

    let job_events = []
    for (var i = 0; i < job.job_events.length; i++) {
      let job_event = job.job_events[i]
      if (job_event.event_type == 'message' && job_event.event_channel_name == this.state.event_channel_name && job_event.parent_job_event_id == 0) {
        job_events.push(job_event)
      }
    }
    job_events = _.sortBy(job_events, 'event_datetime');
    //job_events.reverse()

    // Format date and color
    let datetime = '1999-12-31'
    let datetime_string = ''
    for (var i = 0; i < job_events.length; i++) {
      let job_event = job_events[i]
      job_event.datetime_string = utility.niceDateTimeFormat(job_event.event_datetime, true)
      job_event.show_datetime = (job_event.datetime_string != datetime_string && (moment(job_event.event_datetime).format("YYYY-MM-DD") != moment(datetime).format("YYYY-MM-DD") || moment.duration(moment(job_event.event_datetime).diff(datetime)).asMinutes() > 15))
      job_event.color = (job_event.event_actor == 'customer' ? '#00a58a' : (job_event.event_actor == 'provider' ? '#026ca0' : '#00a1b1'))
      job_event.right = (job_event.event_actor == 'provider')
      datetime_string = job_event.datetime_string
      datetime = job_event.event_datetime

    }

    return (

          <div style={{width: '100%'}}>

            {!locked ? (
              <div style={{
                display: 'flex',
                //flex: 0, 
                flexDirection: 'row', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                width: '100%', 
                backgroundColor: 'white', 
                //position: 'absolute', 
                zIndex: 999,
                bottom: 0,
                borderTopWidth: 1,
                borderColor: '#00a58a',
                padding: 10,
                boxSizing: 'border-box',
              }}>
                <DefaultInput
                  placeholder="Skriv ditt meddelande till kunden här"
                  value={this.state.controls.new_message.value}
                  onChange={(event) => this.updateInputState("new_message", event.target.value)}
                  autoCapitalize="sentences"
                  style={{borderRadius: 7, width: '100%'}}
                  rowSpan={Math.ceil((this.state.controls.new_message.value.length+1)/40)}
                />
                {this.state.is_updating ? (
                  <div>...</div>
                ) : (
                  <div className="byggbuddy-icon" onClick={() => this.sendNewMessage()} style={{cursor: 'pointer', alignItems: 'center', marginRight: '15px', marginLeft: '15px', marginTop: '4px', color: (this.state.controls.new_message.valid ? '#00a58a' : 'grey')}}>
                    <span className="material-icons">send</span>
                  </div>
                ) }
              </div>
            ) : null }

            <div>
            
              <div style={{width: '100%'}}>
                {job_events.map(job_event => (
                  <div style={{padding: 10, display: 'flex', flexDirection: 'column'}} key={job_event.job_event_id}>
                    {job_event.show_datetime ? (
                      <div style={{display: 'flex', padding: 10, marginBottom: 0, marginTop: 20, flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{color: '#606060', fontSize: 11}}>
                          {job_event.datetime_string}
                        </div>
                      </div>
                    ) : (
                      <div style={{display: 'flex', height: 8}}>
                      </div>
                    )}
                    <div style={{display: 'flex', backgroundColor: job_event.color, borderRadius: 10, padding: 10, flex: 0, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', alignSelf: job_event.right ? 'flex-end' : 'flex-start'}}>
                      <div style={{fontWeight: (job_event.job_event_read ? 'normal' : 'bold'), color: '#ffffff', fontSize: 14}}>
                        {job_event.event_message} 
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

    )
  }

  renderByggbuddyContent() {
    
    return (
      <>
        Not allowed!
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...ByggbuddyScreen.mapStateToProps(state),
    user: state.auth.user,
    scope: state.auth.scope,
    jobs: state.jobs.jobs,
    auctions: state.auctions.auctions,
    provider_account: state.auth.provider_account,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...ByggbuddyScreen.mapDispatchToProps(dispatch),
    createJobEvent: (job_event) => dispatch(createJobEvent(job_event)),
    markJobEventsRead: (job_event_ids) => dispatch(markJobEventsRead(job_event_ids)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderMessagesScreen)
